import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { classNames } from "../../helpers/classNames";

export default function DatePickerInput({
  label,
  isReqired,
  startDate,
  divClasses,
  dateFormat,
  labelClasses,
  setStartDate,
  type = "calendar", // input || calendar
  errorType,
  errorText,
}) {
  const handleInputChange = (date) => {
    setStartDate(date);
  };

  return (
    <div className="relative w-full">
      {type === "input" ? (
        <div className={classNames("relative space-y-1 w-full", divClasses)}>
          {label && (
            <div
              className={classNames(
                "text-sm font-medium text-slate-500",
                labelClasses
              )}
            >
              {label}
              {isReqired && (
                <span className="text-[8px] text-red-500 ml-0.5 absolute top-[-2px]">
                  <i className="fa-solid fa-asterisk"></i>
                </span>
              )}
            </div>
          )}
          <div className="relative px-2 border border-slate-200 flex items-center justify-between rounded-md">
            <DatePicker
              selected={startDate}
              dateFormat={dateFormat}
              onChange={handleInputChange}
              calendarClassName={"theme-calendar"}
              className="!w-full h-10 rounded-md !border-transparent bg-white border py-0 px-3 text-sm text-slate-800 !ring-0 !outline-none !shadow-none transition-all duration-200"
            />
            <i className="fa-regular fa-calendar-lines text-slate-500" />
          </div>
          {errorType && errorType === "danger" && (
            <div className="text-xs font-medium text-red-600">
              <i className="fa-regular fa-fw fa-square-exclamation text-xs mr-1"></i>
              {errorText}
            </div>
          )}
          {errorType && errorType === "warning" && (
            <div className="text-xs font-medium text-amber-600">
              <i className="fa-regular fa-fw fa-square-exclamation text-xs mr-1"></i>
              {errorText}
            </div>
          )}
        </div>
      ) : (
        <DatePicker
          inline
          selected={startDate}
          dateFormat={dateFormat}
          calendarClassName={"theme-calendar"}
          onChange={handleInputChange}
          className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
        />
      )}
    </div>
  );
}
