import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getVerifyTokan } from "../services/ProfileService";

export const ProtectedRoute = (props) => {
  const loaction = useLocation();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [auth, setAuth] = useState(false);
  const token_ = localStorage.getItem("auth_token");
  const role = localStorage.getItem("role");
  const accessRole = Array.isArray(props.getAccess) ? props.getAccess : [];
  const path = window.location.pathname;
  useEffect(() => {
    const checkData = async () => {
      try {
        const res = await getVerifyTokan();

        if (res?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/signin");
          setAuth(false);
        } else {
          setAuth(true);
          const userRole = res?.data?.data?.role?.find(
            (item) => item?.code === "COMPANY-ADMINISTRATOR"
          )
            ? "COMPANY-ADMINISTRATOR"
            : res?.data?.data?.role?.find(
                (item) => item?.code === "COMPANY-MANAGER"
              )
            ? "COMPANY-MANAGER"
            : res?.data?.data?.role?.find((item) => item?.code === "ADMIN")
            ? "ADMIN"
            : "";
          localStorage.setItem("role", userRole);
        }
      } catch (error) {
        localStorage.removeItem("auth_token");
        navigate("/signin");
        setAuth(false);
      } finally {
        setIsLoader(false);
      }
    };

    if (!token_) {
      setIsLoader(false);
      localStorage.removeItem("role");
      if (path === "/admin" || path.startsWith("/admin")) {
        navigate("/admin/signin");
      } else {
        navigate("/signin");
      }
    } else {
      checkData();
    }
  }, [token_, navigate, path]);

  useEffect(() => {
    const checkAccessRole = () => {
      const hasAccess = accessRole.includes(role);
      if (!hasAccess) {
        if (token_) {
          if (role === "ADMIN") {
            navigate("/admin/dashboard");
          } else if (role === "COMPANY-ADMINISTRATOR") {
            navigate("/");
          } else if (role === "COMPANY-MANAGER") {
            navigate("/chart");
          } else {
            if (path === "/admin" || path.startsWith("/admin")) {
              navigate("/admin/signin");
            } else {
              navigate("/signin");
            }
          }
        }
      }
    };

    checkAccessRole();
  }, [role, path, accessRole, navigate, token_]);

  // if (isLoader) return <div>Loading...</div>;

  return auth ? props.children : null;
};
