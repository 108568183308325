export default function DeparmentLoader({ count }) {
  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <div
          key={i}
          className="col-span-1 relative flex flex-col justify-between  h-32 p-2 px-3 border rounded-lg shadow bg-white  shimmer-effect"
        >
          <div className="py-1 flex flex-col gap-1  items-start  justify-between ">
            <div className="h-3 bg-gray-200 rounded w-1/2 "></div>
            <div className="h-3 bg-gray-200 rounded w-1/3"></div>
            <div className="h-3 bg-gray-200 rounded w-1/4"></div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center justify-end gap-1">
              <i className="fa-regular fa-user  text-gray-400" />
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            </div>
            <div className="w-8 h-8 flex items-center justify-center">
              <div className="w-full h-full bg-gray-200 rounded-full"></div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
