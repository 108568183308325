import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    getSettings: (state, action) => {
        state.data = action.payload
    },
  },
});

export const {getSettings} = settingsSlice?.actions;
export default settingsSlice?.reducer