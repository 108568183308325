import { apiRequest } from "../../util/apiUtils";

export const adminDashboard = async () => {
    try {
      const res = await apiRequest(
        "get",
        `/dashboard`
      );
  
      return res?.data;
    } catch (err) {
      throw err;
    }
  };