import { apiRequest } from "../../util/apiUtils";

export const getPositionList = async (params) => {
  try {
    const res = await apiRequest("get", `/position`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const positionAdd = async (payload) => {
  try {
    const res = await apiRequest("post", `/position`, {
      body: payload,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const positionEdit = async (params) => {
  try {
    const res = await apiRequest("PUT", `/position/${params?.id}`, {
      body: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const deletePosition = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", `/position/delete-all`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
