import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/form/Button";
import Logo from "../../assets/images/logo.png";
import UserDropdown from "./UserDropdown";
import Image from "../../components/elements/Image";
import InviteDropdown from "./InviteDropdown";
import DeleteModal from "../../components/common/DeleteModal";
import { Tooltip } from "react-tooltip";
import SearchWithDropdown from "../../components/form/SearchWithDropdown";
import { useCallback } from "react";
import { getEmployeeList } from "../../services/adminService/EmployeeService";
import { useDebounce } from "../../helpers/useDebounce";

const Header = ({ profile, isMenuOpen, setMenuOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [adminData, setAdminData] = useState({});
  const [empList, setEmpList] = useState({ loading: false, data: [] });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    loading: false,
  });
  const data = {
    image: profile?.image?.url,
    name: profile?.firstName
      ? profile?.firstName + " " + profile?.lastName
      : null,
    email: profile?.email,
  };
  const debKeyword = useDebounce(keyword, 300);

  const userRole = profile?.role?.find(
    (item) => item?.code === "COMPANY-MANAGER"
  );
  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem("adminData");
    if (dataFromLocalStorage) {
      const parsedData = JSON.parse(dataFromLocalStorage);
      setAdminData(parsedData);
    }
  }, []);

  const onSubmit = async (adminData) => {
    setDeleteModal({
      ...deleteModal,
      loading: true,
    });
    if (adminData?._id && adminData?.uuid) {
      localStorage.setItem("auth_token", adminData?.authToken);
      localStorage.setItem("role", "ADMIN");
      navigate("/admin/dashboard");
      setDeleteModal({
        isOpen: false,
        loading: false,
      });
    }
  };

  const handleModalOpen = () => {
    setDeleteModal({
      ...deleteModal,
      isOpen: true,
    });
  };

  const handleModalClose = () => {
    setDeleteModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const loadempList = useCallback(() => {
    setEmpList({ loading: true, data: [] });
    getEmployeeList({ keyword: debKeyword, limit: 10 })
      .then((res) => {
        setEmpList({
          loading: false,
          data: res?.status === 200 ? res?.docs : [],
        });
      })
      .catch(() => {
        setEmpList({ loading: false, data: [] });
      });
  }, [debKeyword]);

  useEffect(() => loadempList(), [loadempList]);

  const handleChange = (val) => {
    navigate(`/directory/employee/${val?._id}`);
  };

  return (
    <>
      <header className="relative w-full bg-slate-900 shadow flex items-center px-6">
        <div className="mr-auto flex items-center gap-10">
          <div className="flex items-center gap-4">
            <Link to="/" className="h-8">
              <Image
                src={Logo}
                alt={"Logo"}
                effect={"blur"}
                className={"!w-auto !h-8 !rounded-none !object-contain"}
              />
            </Link>
          </div>

          {adminData?.login === "Administrator-login" && (
            <div
              className="relative"
              data-tooltip-id="back-dashboard"
              data-tooltip-place="bottom"
            >
              <Button
                buttonHasLink={false}
                buttonIcon={"fa-regular fa-arrow-left-to-bracket"}
                buttonIconPosition={"left"}
                buttonLabel={"Back to Admin"}
                buttonFunction={handleModalOpen}
              />
              <Tooltip
                id="back-dashboard"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">
                    Please click for back to admin dashboard.
                  </div>
                )}
              />
            </div>
          )}
        </div>

        <div className="ml-2 md:ml-auto">
          <div className="flex items-center gap-4">
            {location?.pathname !== "/" && location?.pathname !== "/chart" && (
              <div className="relative w-auto my-2">
                <SearchWithDropdown
                  placeholder={`Search ${
                    profile?.companyDetails?.companyName &&
                    profile?.companyDetails?.companyName.length > 15
                      ? profile?.companyDetails?.companyName.substring(0, 15) +
                        "..."
                      : profile?.companyDetails?.companyName || ""
                  }`}
                  loading={empList?.loading}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  onChange={handleChange}
                  divClasses={
                    "!rounded-3xl !h-9 !flex !items-center !justify-center !opacity-90"
                  }
                  inputClasses={"!text-sm"}
                  dropdownClass={"!rounded-xl"}
                  dropdownData={empList?.data?.map((item) => ({
                    name: item?.firstName + " " + item?.lastName,
                    subItem: item?.positionDetails?.title || "No position",
                    image: item?.image?.url,
                    _id: item?._id,
                    type: "user",
                  }))}
                />
              </div>
            )}

            <div className="relative">
              <Button
                buttonHasLink={false}
                buttonIcon={"fa-regular fa-bars"}
                buttonIconPosition={"left"}
                buttonClasses={
                  "!p-0 !aspect-square flex items-center justify-center !rounded-full text-sm md:!text-xl !h-7 md:!h-10"
                }
                buttonFunction={() => setMenuOpen(!isMenuOpen)}
              />
            </div>

            {userRole?.code !== "COMPANY-MANAGER" && (
              <div className="relative border-x border-gray-700">
                <InviteDropdown xPlacement={"bottomRight"} />
              </div>
            )}
            <div className="relative">
              <UserDropdown
                data={data}
                role="user"
                xPlacement={"bottomRight"}
                isFront={true}
                dropdownContainer="w-44"
              />
            </div>
          </div>
        </div>
      </header>
      <DeleteModal
        open={deleteModal.isOpen}
        onClose={handleModalClose}
        deleteHandler={() => onSubmit(adminData)}
        title="Switch Dashboard"
        loading={deleteModal.loading}
        deleteButtonLabel="Yes, Go Back"
        deleteButtonIcon={"fa-regular fa-arrow-left-to-bracket"}
        customMessage="Are you sure that you want to switch to the admin dashboard?"
      />
    </>
  );
};

export default Header;
