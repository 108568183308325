import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { profileUpdate } from "../../../services/ProfileService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import Modal from "../../../components/elements/Modal";
import ImageUploader from "../../../components/form/ImageUploader";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const MyProfile = ({ profileModal, setProfileModal = () => {} }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);
  const profile = useSelector((state) => state?.profile?.data);
  const [phone, setPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const validation = {
    firstName: { required: true, message: "Please enter your first name!" },
    lastName: { required: true, message: "Please enter your last name!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (profile) {
      setFieldsValue({
        firstName: profile?.firstName || "",
        lastName: profile?.lastName || "",
        email: profile?.email || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (profile?.phone) {
      let phoneNumber = String(profile?.phone);
      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
        setPhone(phoneNumber);
        setPhoneNumber(phoneNumber);
      }
    } else {
      setPhone("");
      setPhoneNumber("");
    }
  }, [profile]);

  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };

  useEffect(() => {
    if (profile?.image?.url) {
      setImage(profile?.image?.url);
    } else {
      setImage(null);
    }
  }, [profile?.image]);

  const onSubmit = (values) => {
    setLoading(true);
    profileUpdate({
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      phone: phone || "",
      image: image || "null",
    }).then((res) => {
      if (res?.status === 200) {
        dispatch(setProfile(res?.data));
        toast.success(res?.message);
        setProfileModal({ isOpen: false });
      } else {
        toast?.error(res?.data?.message);
      }
      setLoading(false);
    });
  };

  return (
    <>
      <Modal
        title="Edit Administrator Details"
        open={profileModal.isOpen}
        onClose={() => {
          setPhone(phoneNumber);
          setImage(profile?.image?.url);
          setProfileModal({ isOpen: false });
          setFieldsValue({
            firstName: profile?.firstName || "",
            lastName: profile?.lastName || "",
            email: profile?.email || "",
          });
          
        }}
        modaltitleClasses={"!text-slate-200 text-lg"}
        headerClass={"!bg-black !rounded-t-2xl !text-white"}
      >
        <div className="lg:flex md:block xl:gap-6">
          <div className="xl:w-1/4 lg:w-1/4 w-full md:px-0 ">
            <div className="mt-2">
              <div className="relative flex items-end  justify-center">
                <ImageUploader
                  label=""
                  labelClasses="text-lg font-bold"
                  isMultiple={false}
                  image={image}
                  setImage={setImage}
                  isCameraIcon={true}
                  buttonLabel={image ? "Change Logo" : "Upload logo"}
                  buttonClasses="!bg-transparent !border border-orange-500 !text-orange-500 !h-8"
                  inputClasses="w-40 h-40"
                />
              </div>
              <div className="text-center pt-4 pb-2">
                <div className="text-black text-2xl font-semibold mt-1">
                  {profile?.firstName + " " + profile?.lastName}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden lg:block w-px bg-gray-300 " />
          <div className="xl:w-3/4 w-full mt-4">
            <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
              <div className="input col-span-12 lg:col-span-9  rounded-md">
                <div className="text-2xl font-bold mb-3">Profile Info</div>
                <div className="grid lg:grid-cols-2 gap-3">
                  <div className="relative">
                    <Input
                      label={"FirstName"}
                      inputType={"text"}
                      value={values?.firstName}
                      inputPlaceholder={"Enter firstname..."}
                      inputName="firstName"
                      onChange={handleChange}
                      isValidate={true}
                      charLimit={20}
                      {...formValidate(errors, "firstName")}
                    />
                  </div>
                  <div className="relative">
                    <Input
                      label={"LastName"}
                      inputType={"text"}
                      value={values?.lastName}
                      inputPlaceholder={"Enter lastname..."}
                      inputName="lastName"
                      onChange={handleChange}
                      isValidate={true}
                      charLimit={20}
                      {...formValidate(errors, "lastName")}
                    />
                  </div>
                  <div className="relative">
                    <Input
                      label={"Email"}
                      inputType={"email"}
                      value={values?.email}
                      inputPlaceholder={"Enter email..."}
                      inputName="email"
                      onChange={handleChange}
                      isValidate={true}
                      {...formValidate(errors, "email")}
                      isDisabled={true}
                    />
                  </div>
                  <div className="relative mt-0.5">
                    <div className="text-sm font-medium text-slate-500">
                      Phone
                    </div>
                    <PhoneInput
                      placeholder="(xxx) xxx-xxxx"
                      value={phone}
                      onChange={handlePhoneInputChange}
                      // defaultCountry="US"
                      international={false}
                    />
                  </div>
                </div>
                <div className="w-full flex items-center justify-end gap-3 mt-4">
                  <div
                    className="flex justify-end items-center"
                    data-tooltip-id="edit-profile"
                    data-tooltip-place="left"
                  >
                    <Button
                      buttonType={"submit"}
                      buttonIcon={"fa-light fa-floppy-disk"}
                      buttonIconPosition={"left"}
                      buttonLabel="Save"
                      loading={loading}
                      isDisable={loading ? true : false}
                    />
                    <Tooltip
                      id="edit-profile"
                      className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                      render={() => (
                        <div className="text-white">
                          Click to save profile details.
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default MyProfile;
