import React from "react";
import { ColorPicker } from "react-color-palette";
import "react-color-palette/css";
import Modal from "../elements/Modal";

export default function ColorModal({
  isOpen,
  color,
  onClose,
  setColor = () => {},
}) {
  return (
    <Modal
      title="Choose Color"
      size="lg"
      open={isOpen}
      onClose={onClose}
      modaltitleClasses="!text-slate-200 text-lg"
      headerClass="!bg-black !rounded-t-2xl !text-white"
    >
      <ColorPicker color={color} onChange={setColor} />
    </Modal>
  );
}
