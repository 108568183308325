import React, { useState, useRef, useEffect, Fragment } from "react";
import { bottom, top, left, right } from "@popperjs/core";
import { Combobox, Transition } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";
import { usePopper } from "react-popper";
import Image from "../elements/Image";
import useClickOutside from "../../hooks/useClickOutside";

export default function SelectDropdownSearch({
  buttonArrowIconClasses,
  onSearch = () => {},
  onChange = () => {},
  onDropdownOpen = () => {},
  dropdownData = [],
  loading = false,
  dropdownClass,
  labelClasses,
  selectName,
  value,
  label,
  keyword,
  errorText,
  errorType,
  isSearch,
  inputClasses,
  placeholder,
  isValidate,
  isMultiple = false,
  ...props
}) {
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [list, setList] = useState(dropdownData);
  const [selected, setSelected] = useState(isMultiple ? [] : null);
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);
  const popperElRef = useRef(null);
  const dropdownRef = useRef(null);
  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": "",
  };
  useClickOutside(dropdownRef, () => setOpen(false));
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements["bottom"],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 2],
        },
      },
    ],
  });

  useEffect(() => {
    setInputValue(keyword);
  }, [keyword, value]);

  useEffect(() => {
    setList(dropdownData);
    if (isMultiple) {
      setSelected(
        value ? dropdownData.filter((item) => value.includes(item._id)) : []
      );
    } else {
      setSelected(
        value ? dropdownData.find((item) => item._id === value) : null
      );
    }
  }, [dropdownData, value, isMultiple]);

  const onSearchHandler = (e) => {
    const value = e.target.value;
    if (value.key === " " || value.keyCode === 32) {
      value.preventDefault();
    }
    setInputValue(value);
    onSearch(value);
    setOpen(true);
  };

  const handleChange = (item) => {
    if (isMultiple) {
      setOpen(true);
      const newSelected = Array.isArray(selected)
        ? selected?.some((selectedItem) => selectedItem._id === item._id)
          ? selected.filter((selectedItem) => selectedItem._id !== item._id)
          : [...selected, item]
        : [item];
      setSelected(newSelected);
      onChange({
        target: { name: selectName, value: newSelected.map((sel) => sel._id) },
      });
    } else {
      setOpen(false);
      setSelected(item);
      setInputValue(item?.name || "");
      onChange({ target: { name: selectName, value: item._id } });
    }
  };

  useEffect(() => {
    onDropdownOpen(open);
  }, [open]);

  const clearSelection = () => {
    setSelected(isMultiple ? [] : null);
    setInputValue("");
    onSearch("");
    onChange({ target: { name: selectName, value: null } });
  };

  return (
    <>
      <div className="relative space-y-1 w-full">
        {label && (
          <div
            className={classNames(
              "text-sm font-medium text-slate-500 mb-1",
              labelClasses
            )}
          >
            {label}
            {isValidate && (
              <span className="text-[8px] text-red-500 ml-0.5 absolute top-[-2px]">
                <i className="fa-solid fa-asterisk"></i>
              </span>
            )}
          </div>
        )}
        <Combobox
          ref={dropdownRef}
          value={selected}
          onChange={handleChange}
          as="div"
          className="relative"
        >
          <>
            <Combobox.Button
              ref={setTargetElement}
              className="flex items-center w-full "
              onClick={() => setOpen(!open)}
            >
              <div
                className={classNames(
                  "shadow-sm relative w-full h-10 flex items-center justify-between gap-1 pl-1 pr-6 rounded-md border text-sm font-medium bg-white border-slate-200 text-slate-600",
                  "px-3 !border-gray-200"
                )}
              >
                <Combobox.Input
                  displayValue={(item) => item?.title}
                  value={inputValue}
                  autoComplete="off"
                  onChange={onSearchHandler}
                  className={classNames(
                    "!border-0 w-full !ring-0 bg-transparent text-slate-600 text-base font-medium px-2",
                    inputClasses
                  )}
                  placeholder={
                    selected && !isMultiple
                      ? selected.name
                      : selected && isMultiple
                      ? `Selected ${label}s (${selected.length})`
                      : placeholder
                  }
                />

                {!selected && (
                  <div
                    className={classNames(
                      "transition-all duration-200 text-xs",
                      open ? "-rotate-180" : "",
                      buttonArrowIconClasses,
                      "text-slate-700" ? "" : "text-white"
                    )}
                    style={"text-slate-700" ? { color: "text-slate-700" } : {}}
                  >
                    {loading ? (
                      <i
                        className={
                          "fa-duotone fa-spinner-third animate-spin text-sm"
                        }
                      />
                    ) : (
                      <i
                        className={classNames(
                          "fa-fw",
                          "fa-solid fa-chevron-down"
                        )}
                      />
                    )}
                  </div>
                )}
                {selected && !isMultiple && (
                  <div onClick={clearSelection}>
                    <i className="fa-regular fa-times text-sm" />
                  </div>
                )}
              </div>
            </Combobox.Button>
            <Transition
              as={Fragment}
              show={open}
              className="z-50"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Combobox.Options
                className={classNames(
                  "absolute mt-1 w-full max-h-64 origin-top-right rounded-md bg-white shadow-md focus:outline-none py-1 overflow-auto scrollbar border",
                  dropdownClass
                )}
              >
                <div className="py-1 divide-y divide-slate-200">
                  {list?.map((item, index) => (
                    <Combobox.Option
                      key={index}
                      className={({ active }) =>
                        classNames(
                          "relative group flex w-full items-center px-3 py-1.5 pl-8 text-sm gap-2 transition-all duration-200 cursor-pointer",
                          active
                            ? "bg-orange-200 text-black"
                            : (Array.isArray(selected) &&
                                selected.some(
                                  (selectedItem) =>
                                    selectedItem._id === item._id
                                )) ||
                              selected?._id === item._id
                            ? "bg-orange-100 text-slate-800"
                            : "text-black",
                          item.isDisabled
                            ? "!bg-slate-100 !cursor-not-allowed"
                            : "",
                          item.class
                        )
                      }
                      value={item}
                      disabled={item.isDisabled}
                    >
                      <div className="flex gap-2">
                        {item?.url && (
                          <div className="flex items-center justify-center w-12 h-12 rounded-full overflow-hidden border">
                            <Image
                              effect="blur"
                              src={item.url}
                              alt=""
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}
                        <div className="flex flex-col items-start justify-center">
                          <div className="flex items-center gap-2 text-sm font-medium text-slate-800">
                            {item.name}
                            {item.icon && (
                              <i className={classNames(item.icon, "text-xs")} />
                            )}
                          </div>
                          {item.subItem && (
                            <div className="text-xs text-slate-400">
                              {item.subItem}
                            </div>
                          )}
                        </div>
                        {(Array.isArray(selected) &&
                          selected.some(
                            (selectedItem) => selectedItem._id === item._id
                          )) ||
                        selected?._id === item._id ? (
                          <div className="absolute inset-y-0 left-0 flex items-center pl-2 text-slate-800">
                            <i className="fa-solid fa-check text-xs" />
                          </div>
                        ) : null}
                      </div>
                    </Combobox.Option>
                  ))}
                  {loading && (
                    <div className="flex items-center space-x-4 text-slate-500 py-2 px-6">
                      <div className="h-20 w-20 flex items-center justify-center">
                        <span className="select_loader"></span>
                      </div>
                      <p className="font-medium text-slate-600">
                        Searching for results...
                      </p>
                    </div>
                  )}
                  {list?.length === 0 && !loading && (
                    <div className="flex items-center space-x-4 text-slate-500 p-3">
                      <i className="fa-regular fa-face-sad-tear text-5xl"></i>
                      <div className="flex flex-col space-y-1">
                        <p className="font-semibold">No Results Found!</p>
                        <p className="text-sm text-slate-400">
                          Try refining your search with a different name.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </Combobox.Options>
            </Transition>
          </>
        </Combobox>
      </div>

      {isMultiple && selected?.length > 0 && (
        <div className="mt-2 flex flex-wrap gap-2">
          {selected.map((item) => (
            <span
              key={item._id}
              className="flex items-center bg-orange-100 text-orange-600 text-sm py-1 px-2 rounded-full"
            >
              {item.name}
              <button
                type="button"
                className="ml-2 text-xs"
                onClick={() => handleChange(item)}
              >
                <i className="fa-regular fa-times" />
              </button>
            </span>
          ))}
        </div>
      )}
      {errorType && errorText && (
        <div
          className={`text-xs mt-1 ${
            errorType === "danger" ? "text-red-600" : "text-amber-600"
          }`}
        >
          <i
            className={`fa-regular fa-fw ${
              errorType === "danger"
                ? "fa-square-exclamation"
                : "fa-square-exclamation"
            } text-xs mr-1`}
          ></i>
          {errorText}
        </div>
      )}
    </>
  );
}
