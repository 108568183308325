export const formatPhoneNumber = (phone) => {
  let formattedPhone = phone;

  if (typeof formattedPhone === "number") {
    formattedPhone = formattedPhone.toString();
  }

  if (typeof formattedPhone === "string" && formattedPhone.startsWith("1")) {
    formattedPhone = formattedPhone.slice(1);
  }

  if (typeof formattedPhone === "string" && formattedPhone.length === 10) {
    formattedPhone = formattedPhone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  return formattedPhone;
};
