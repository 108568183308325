export default function EventLoader({ count }) {
  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <div
          key={i}
          className="flex items-center justify-between px-4 py-2 bg-white border rounded-lg shadow-sm shimmer-effect"
        >
          <div className="flex items-center space-x-4">
            <div className="w-12 h-12 relative">
              <div className="w-full h-full bg-slate-200 rounded-full shimmer-effect"></div>
            </div>

            <div className="flex-1 space-y-2">
              <div className="w-32 h-4 bg-slate-200 rounded shimmer-effect"></div>{" "}
              <div className="w-24 h-3 bg-slate-200 rounded"></div>{" "}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
