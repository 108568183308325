import {
  email_filter,
  status_filter,
  yes_no_filter,
} from "../../../components/constant/constant";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "../../../components/form/Select";
import Search from "../../../components/form/Search";
import Button from "../../../components/form/Button";
import Table from "../../../components/elements/table/Table";
import { setTitle } from "../../../helpers/MetaTag";
import {
  getCompanyList,
  changeStatus,
  deleteCompany,
  superAdminDirectLogin,
  administratorInviteAgain,
} from "../../../services/adminService/CompanyService";
import { createFilter, handleFilterChange } from "../../../helpers";
import DeleteModal from "../../../components/common/DeleteModal";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

const CompanyList = () => {
  const navigate = useNavigate();
  setTitle("ORG Chart Admin | Companies");
  const [selectedItems, setSelectedItems] = useState([]);
  const [sort, setSort] = useState("-createdAt");
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    currentPage: 0,
    keyword: "",
    status: null,
    emailVerified: null,
    chart_count: null,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });

  // loading the company list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    if (filters?.limit && sort) {
      getCompanyList({
        sortQuery: sort,
        keyword: filters?.keyword,
        limit: filters?.limit === 0 ? list?.totalItem : filters?.limit,
        offset: filters?.offset,
        type: "COMPANY",
        ...createFilter(filters?.status, "status"),
        ...createFilter(filters?.emailVerified, "emailVerified"),
        ...createFilter(filters?.chart_count, "chart_count"),
      }).then((res) => {
        if (res && res.status === 200) {
          setList({
            loading: false,
            data: res.docs,
            pageCount: res.totalPages,
            totalItem: res.totalDocs,
          });
        } else {
          setList((pre) => ({ ...pre, data: [], loading: false }));
          toast.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // Status Change >>>>>>>>>>

  const handleStatusChange = async (glossaryItem) => {
    const newStatus = glossaryItem.status === 1 ? 2 : 1;
    const res = await changeStatus({
      id: [glossaryItem._id],
      status: newStatus,
    });

    if (res?.status === 200) {
      setList((pre) => ({
        ...pre,
        data: pre?.data?.map((item) =>
          item?._id === glossaryItem?._id
            ? { ...item, status: newStatus }
            : item
        ),
      }));
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };

  // Delete
  const deletefunction = () => {
    setDeleteModal((pre) => ({ ...pre, loading: true }));
    deleteCompany({
      id: deleteModal?.ids,
    }).then((res) => {
      setDeleteModal((pre) => ({ ...pre, loading: false }));
      if (res?.status === 200) {
        setList((pre) => {
          const updatedData = pre?.data?.filter(
            (item) => !deleteModal?.ids.includes(item._id)
          );

          const totalItem =
            pre?.totalItem -
            (updatedData?.length === 0 && filters?.currentPage > 0 ? 1 : 0);

          return {
            ...pre,
            data: updatedData,
            totalItem,
          };
        });
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };
  const onSubmit = async (values) => {
    setLoading(true);
    const adminToken = localStorage.getItem("auth_token");
    try {
      const res = await superAdminDirectLogin(
        {
          id: values?.companyAdministrator[0]?._id,
          email: values?.companyAdministrator[0]?.email,
          uuid: values?.companyAdministrator[0]?.uuid,
          type: "administratorLogin",
        },
        adminToken
      );
      if (res?.status === 201) {
        toast.info(res?.message);
        localStorage.setItem("auth_token", res?.accessToken);
        localStorage.setItem("role", "COMPANY-ADMINISTRATOR");
        localStorage.setItem(
          "adminData",
          JSON.stringify({
            _id: res?.data?._id,
            email: res?.data?.email,
            uuid: res?.data?.uuid,
            authToken: adminToken,
            login: "Administrator-login",
          })
        );
        navigate("/");
      } else {
        toast.info(res?.data?.message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  const inviteAgain = (values) => {
    administratorInviteAgain({
      emails: [values?.companyAdministrator[0]?.email],
      type: "COMPANY_ADMINISTRATOR",
    }).then((res) => {
      if (res?.status === 200) {
        toast.success(res?.message);
      } else {
        toast.info(res?.message);
      }
    });
  };

  const tableHeadData = [
    {
      _id: 1,
      name: "Company Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-name");
        } else {
          setSort("name");
        }
      },
    },
    {
      _id: 2,
      name: "Administrator Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-administratorName");
        } else {
          setSort("administratorName");
        }
      },
    },
    {
      _id: 3,
      name: "Administrator Email",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-email");
        } else {
          setSort("email");
        }
      },
    },
    {
      _id: 4,
      name: "Created Date",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-createdAt");
        } else {
          setSort("createdAt");
        }
      },
    },
    {
      _id: 5,
      name: "Chart Created",
      align: "left",
      isSort: false,
      isFilter: true,
      isFilterSearch: false,
      filterData: yes_no_filter,
      onFilter: (data) => {
        handleFilterChange(
          "chart_count",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0, currentPage: 0 }));
      },
    },
    {
      _id: 6,
      name: "Email Verification",
      align: "left",
      isSort: false,
      isFilter: true,
      isFilterSearch: false,
      filterData: email_filter,
      onFilter: (data) => {
        handleFilterChange(
          "emailVerified",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0, currentPage: 0 }));
      },
    },
    {
      _id: 7,
      name: "Status",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: status_filter,
      onFilter: (data) => {
        handleFilterChange(
          "status",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0, currentPage: 0 }));
      },
    },
    {
      _id: 8,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];
  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: item?._id,
        type: "user",
        align: "left",
        cellClass: "!min-w-[100px]",

        data:
          item?.companyName?.length > 20
            ? item?.companyName.substring(0, 20) + "..."
            : item?.companyName || "N/A",
        image: item?.image?.url,
        isTooltip: true,
        toolTipData: (
          <>
            <div className="flex flex-col items-start justify-center text-white gap-1">
              {item?.companyName && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Company Name: </span>
                  {item?.companyName?.length > 20
                    ? item?.companyName.substring(0, 20) + "..."
                    : item?.companyName || "N/A"}
                </h4>
              )}
              {item?.companyAdministrator[0] && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Administrator Name: </span>
                  {item?.companyAdministrator[0]?.firstName +
                    " " +
                    item?.companyAdministrator[0]?.lastName}
                </h4>
              )}
              {item?.companyAdministrator[0] && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Administrator Email: </span>
                  {item?.companyAdministrator[0]?.email}
                </h4>
              )}
            </div>
          </>
        ),
      },
      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={
                  "text-xs font-medium leading-tight rounded py-1 px-2 bg-sky-100 text-sky-600"
                }
              >
                {item?.companyAdministrator[0]?.firstName +
                  " " +
                  item?.companyAdministrator[0]?.lastName || "N/A"}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.companyAdministrator[0]?.email || "N/A",
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.createdAt
          ? moment(item?.createdAt).format("MM-DD-YYYY")
          : "N/A",
      },
      {
        _id: 5,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 ${
                  item?.chart_count > 0
                    ? "bg-green-100 text-green-600"
                    : "bg-red-100 text-red-600"
                }`}
              >
                {item?.chart_count > 0 ? "Yes" : "No"}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 6,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 ${
                  item?.companyAdministrator[0]?.emailVerified === 1
                    ? "bg-green-100 text-green-600"
                    : "bg-red-100 text-red-600"
                }`}
              >
                {item?.companyAdministrator[0]?.emailVerified === 1
                  ? "Verified"
                  : "Not Verified"}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 7,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "Inactive",
        statusType: item?.status === 1 ? "success" : "danger",
        functions: () => handleStatusChange(item),
      },
      {
        _id: 8,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          {
            _id: 1,
            name: "Administrator Login",
            icon: "fa-solid fa-right-to-bracket",
            standout: false,
            onClick: () => onSubmit(item),
          },

          ...(item?.companyAdministrator?.[0]?.emailVerified === 2
            ? [
                {
                  _id: 2,
                  name: "Invite Again",
                  icon: "fa-solid fa-calendar-circle-user",
                  standout: false,
                  onClick: () => inviteAgain(item),
                },
              ]
            : []),
          {
            _id: 3,
            name: "Details",
            icon: "fa-regular fa-eye",
            link: `/admin/company/details/${item?._id}`,
            standout: false,
          },
          {
            _id: 4,
            name: "Employees",
            icon: "fa-regular fa-users",
            link: `/admin/company/details/${item?._id}`,
            standout: false,
          },
          {
            _id: 5,
            name: "Departments",
            icon: "fa-solid fa-building",
            standout: false,
            onClick: () =>
              navigate(`/admin/company/details/${item?._id}`, {
                state: "department",
              }),
          },
          {
            _id: 6,
            name: "Positions",
            icon: "fa-regular fa-user",
            standout: false,
            onClick: () =>
              navigate(`/admin/company/details/${item?._id}`, {
                state: "position",
              }),
          },
          {
            _id: 7,
            name: "Calender",
            icon: "fa-regular fa-calendar-days",
            standout: false,
            onClick: () =>
              navigate(`/admin/company/details/${item?._id}`, {
                state: "events",
              }),
          },
          {
            _id: 8,
            name: "Edit",
            icon: "fa-regular fa-pen-to-square",
            link: `/admin/company/edit/${item?._id}`,
            standout: false,
          },
          {
            _id: 8,
            name: "Delete",
            icon: "fa-regular fa-trash-can",
            standout: true,
            onClick: () =>
              setDeleteModal((pre) => ({
                ...pre,
                isOpen: true,
                ids: [item?._id],
              })),
          },
        ],
      },
    ],
  }));

  return (
    <div className="space-y-4 w-full flex flex-col pb-10">
      <div className="space-y-4 mt-5 flex-shrink-0">
        <div className="font-semibold text-lg md:text-xl xl:text-2xl">
          Companies
        </div>

        <div className="flex items-center gap-3">
          <div className="w-36">
            <Select
              selectedValue={filters?.limit}
              dropdownData={[
                { name: "All Items", value: list?.totalItem },
                { name: "5 Items", value: 5 },
                { name: "10 Items", value: 10 },
                { name: "20 Items", value: 20 },
                { name: "30 Items", value: 30 },
                { name: "50 Items", value: 50 },
                { name: "100 Items", value: 100 },
              ]}
              setCustomSelecedvalue={(val) => {
                handleFilterChange("limit", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
                handleFilterChange("currentPage", 0, setFilters);
              }}
            />
          </div>
          <div className="w-72">
            <Search
              placeholder={"Search here"}
              search={(val) => {
                handleFilterChange("keyword", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
                handleFilterChange("currentPage", 0, setFilters);
              }}
            />
          </div>
          <div
            className="ml-auto"
            data-tooltip-id="add-company"
            data-tooltip-place="left"
          >
            <Button
              buttonHasLink={true}
              buttonIcon={"fa-regular fa-plus"}
              buttonIconPosition={"left"}
              buttonClasses={"!bg-orange-500"}
              buttonLabel={"Add Company"}
              buttonLabelClasses={"font-medium !text-sm"}
              buttonLink={"/admin/company/add"}
            />
            <Tooltip
              id="add-company"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">Click to add company.</div>
              )}
            />
          </div>
        </div>
      </div>
      <Table
        tableHeadData={tableHeadData}
        tableData={tableData}
        isLoder={list?.loading}
        pageCount={list?.pageCount}
        currentPage={filters?.currentPage}
        onPageChange={(val) => {
          const newOffset = filters.limit * val?.selected;
          handleFilterChange("offset", newOffset, setFilters);
          handleFilterChange("currentPage", val?.selected, setFilters);
          setSelectedItems([]);
        }}
        isOrderingDisabled={true}
        originalData={[...list?.data]}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
        containerClasses={"flex-grow flex-shrink min-h-[calc(100vh-220px)]"}
        tableActionDropdownContainer={"!w-48"}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        deleteHandler={deletefunction}
      />
    </div>
  );
};

export default CompanyList;
