import React, {useState } from "react";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { formValidate } from "../../../helpers/formValidate";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  changePassword,
} from "../../../services/ProfileService";
import { Validation } from "../../../helpers/Validation";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const validation = {
    currentPassword: {
      required: true,
      message: "Please enter Current Password!",
    },
    newPassword: { required: true, message: "Please enter New Password!" },
  };

  const { values, handleChange, resetField, handleSubmit, errors } = useForm(
    {},
    validation
  );

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push(
        "Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?)."
      );
    }

    return errors;
  };
  const onSubmit = async (values) => {
    setLoading(true);

    try {
      if (values.currentPassword) {
        const currentPasswordErrors = validatePassword(values.currentPassword);
        if (currentPasswordErrors.length > 0) {
          toast.error(currentPasswordErrors.join(" "));
          setLoading(false);
          return;
        }
      }

      if (values.newPassword) {
        const newPasswordErrors = validatePassword(values.newPassword);
        if (newPasswordErrors.length > 0) {
          toast.error(newPasswordErrors.join(" "));
          setLoading(false);
          return;
        }
      }

      if (
        values.newPassword &&
        !Validation(
          "confirmPassword",
          values.confirmPassword,
          values.newPassword
        )
      ) {
        toast.error("Confirm password and password don't match");
        setLoading(false);
        return;
      }

      const res = await changePassword({
        currentPassword: values.currentPassword || "",
        newPassword: values.newPassword || "",
        confirmPassword: values.confirmPassword || "",
      });
      if (res?.status === 200 || res?.status === 201) {
        toast.success(res?.message);
      } else {
        toast.error(
          res.data?.message || "Something went wrong"
        );
      }
      resetField({ currentPassword: "", newPassword: "", confirmPassword: "" });
    } catch (err) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="grid grid-cols-12 gap-4">
                <div className="image bg-white p-4 col-span-12 lg:col-span-3 border-r border-slate-200 h-auto"></div>

    <div className="input bg-white col-span-12 lg:col-span-9">
      <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
        <div className="grid grid-cols-12 gap-4">
          <div className="input bg-white col-span-12 lg:col-span-12">
            <div className="text-xl font-bold mb-3">Change password</div>
            <div className="grid lg:grid-cols-3 gap-3">
              <div className="relative">
                <Input
                  label={"Current Password"}
                  inputType={"password"}
                  value={values?.currentPassword}
                  inputPlaceholder={"Enter current password..."}
                  inputName="currentPassword"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "currentPassword")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"New Password"}
                  inputType={"password"}
                  value={values?.newPassword}
                  inputPlaceholder={"Enter new password..."}
                  inputName="newPassword"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "newPassword")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Confirm Password"}
                  inputType={"password"}
                  value={values?.confirmPassword}
                  inputPlaceholder={"Enter confirm password..."}
                  inputName="confirmPassword"
                  onChange={handleChange}
                  {...formValidate(errors, "confirmPassword")}
                />
              </div>
            </div>

            <div className="w-full flex items-center justify-end gap-3 mt-4">
              <div
                className="flex justify-end items-center"
                data-tooltip-id="change-password"
                data-tooltip-place="left"
              >
                <Button
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel="Change Password"
                    loading={loading}
                />
                <Tooltip
                  id="change-password"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">Click to change password.</div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    </div>
    
  );
};

export default ChangePassword;
