import { status_filter } from "../../../components/constant/constant";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Select from "../../../components/form/Select";
import Search from "../../../components/form/Search";
import Button from "../../../components/form/Button";
import Table from "../../../components/elements/table/Table";
import { setTitle } from "../../../helpers/MetaTag";
import { createFilter, handleFilterChange } from "../../../helpers";
import DeleteModal from "../../../components/common/DeleteModal";
import { getEmployeeList } from "../../../services/adminService/EmployeeService";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import {
  changeStatus,
  deleteAdmin,
} from "../../../services/adminService/AdminsService";
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber";

const AdminList = () => {
  setTitle("ORG Chart Admin | Admins");
  const profile = useSelector((state) => state?.profile?.data);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sort, setSort] = useState("-createdAt");
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    currentPage: 0,
    keyword: "",
    status: null,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });
  // loading the admin list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    if (filters?.limit && sort) {
      getEmployeeList({
        sortQuery: sort,
        keyword: filters?.keyword,
        limit: filters?.limit === 0 ? list?.totalItem : filters?.limit,
        offset: filters?.offset,
        type: "ADMIN",

        ...createFilter(filters?.status, "status"),
      }).then((res) => {
        if (res && res?.status === 200) {
          setList({
            loading: false,
            data: res?.docs,
            pageCount: res?.totalPages,
            totalItem: res?.totalDocs,
          });
        } else {
          setList((pre) => ({ ...pre, data: [], loading: false }));
          toast?.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // Status Change >>>>>>>>>>

  const handleStatusChange = async (glossaryItem) => {
    const newStatus = glossaryItem?.status === 1 ? 2 : 1;
    const res = await changeStatus({
      id: [glossaryItem?._id],
      status: newStatus,
    });

    if (res?.status === 200) {
      setList((pre) => ({
        ...pre,
        data: pre?.data?.map((item) =>
          item?._id === glossaryItem?._id
            ? { ...item, status: newStatus }
            : item
        ),
      }));
      toast.success(res?.message);
    } else {
      toast.error(res?.message);
    }
  };

  // Delete
  const deletefunction = () => {
    setDeleteModal((pre) => ({ ...pre, loading: true }));
    deleteAdmin({
      id: deleteModal?.ids,
    }).then((res) => {
      setDeleteModal((pre) => ({ ...pre, loading: false }));
      if (res?.status === 200) {
        setList((pre) => {
          const updatedData = pre?.data?.filter(
            (item) => !deleteModal?.ids.includes(item._id)
          );

          const totalItem =
            pre?.totalItem -
            (updatedData?.length === 0 && filters?.currentPage > 0 ? 1 : 0);

          return {
            ...pre,
            data: updatedData,
            totalItem,
          };
        });
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };

  const tableHeadData = [
    {
      _id: 1,
      name: " Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-name");
        } else {
          setSort("name");
        }
      },
    },

    {
      _id: 2,
      name: "Email",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-email");
        } else {
          setSort("email");
        }
      },
    },
    {
      _id: 3,
      name: "Phone",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    // {
    //   _id: 4,
    //   name: "Email Verification",
    //   align: "left",
    //   isSort: true,
    //   isFilter: false,
    //   isFilterSearch: false,
    //   onSort: (val) => {
    //     if (val === "DESC") {
    //       setSort("-email");
    //     } else {
    //       setSort("email");
    //     }
    //   },
    // },
    {
      _id: 4,
      name: "Type",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 5,
      name: "Status",
      align: "left",
      isFilter: true,
      isFilterSearch: false,
      isSort: false,
      filterData: status_filter,
      onFilter: (data) => {
        handleFilterChange(
          "status",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0,currentPage: 0 }));
      },
    },
    {
      _id: 6,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: item?._id,
        type: "user",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.firstName + " " + item?.lastName || "N/A",
        image: item?.image?.url,
        isTooltip: true,
        toolTipData: (
          <>
            <div className="flex flex-col items-start justify-center text-white gap-1">
              {item?.firstName && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Name: </span>
                  {item?.firstName + " " + item?.lastName}
                </h4>
              )}
              {item?.email && (
                <h4 className="font-semibold">
                  <span className="text-slate-200">Email: </span>
                  {item?.email}
                </h4>
              )}
            </div>
          </>
        ),
      },

      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.email || "N/A",
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.phone ? `+ ${formatPhoneNumber(item?.phone)}` : "N/A",
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center  capitalize justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 bg-yellow-100 text-yellow-600`}
              >
                {item?._id !== profile?._id ? "ADMIN" : "SUPER ADMIN"}
              </div>
            </div>
          </div>
        ),
      },
      // {
      //   _id: 4,
      //   type: "text",
      //   align: "left",
      //   cellClass: "!min-w-[100px]",
      //   data: (
      //     <div>
      //       <div className={"flex items-center  capitalize justify-start"}>
      //         <div
      //           className={`text-xs font-medium leading-tight rounded py-1 px-2 ${
      //             item?.emailVerified === 1
      //               ? "bg-green-100 text-green-600"
      //               : "bg-red-100 text-red-600"
      //           }`}
      //         >
      //           {item?.emailVerified === 1
      //             ? "Verified"
      //             : "Not Verified"}
      //         </div>
      //       </div>
      //     </div>
      //   ),
      // },
      {
        _id: 5,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "Inactive",
        statusType: item?.status === 1 ? "success" : "danger",
        functions: () => handleStatusChange(item),
      },
      {
        _id: 6,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          {
            _id: 1,
            name: "Edit",
            icon: "fa-regular fa-pen-to-square",
            link: `/admin/edit/${item?._id}`,
            standout: false,
          },

          ...(profile?._id !== item?._id
            ? [
                {
                  _id: 2,
                  name: "Delete",
                  icon: "fa-regular fa-trash-can",
                  standout: true,
                  onClick: () => {
                    setDeleteModal((prev) => ({
                      ...prev,
                      isOpen: true,
                      ids: [item?._id],
                    }));
                  },
                },
              ]
            : []),
        ],
      },
    ],
  }));

  return (
    <div className="space-y-4 w-full flex flex-col pb-10">
      <div className="space-y-4 mt-5">
        <div className="font-semibold text-lg md:text-xl xl:text-2xl">
          Admins
        </div>

        <div className="flex items-center gap-3">
          <div className="w-36">
            <Select
              selectedValue={filters?.limit}
              dropdownData={[
                { name: "All Items", value: list?.totalItem },
                { name: "5 Items", value: 5 },
                { name: "10 Items", value: 10 },
                { name: "20 Items", value: 20 },
                { name: "30 Items", value: 30 },
                { name: "50 Items", value: 50 },
                { name: "100 Items", value: 100 },
              ]}
              setCustomSelecedvalue={(val) => {
                handleFilterChange("limit", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
                handleFilterChange("currentPage", 0, setFilters);
              }}
            />
          </div>
          <div className="w-72">
            <Search
              placeholder={"Search here"}
              search={(val) => {
                handleFilterChange("keyword", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
                handleFilterChange("currentPage", 0, setFilters);

              }}
            />
          </div>
          <div
            className="ml-auto"
            data-tooltip-id="add-admin"
            data-tooltip-place="left"
          >
            <Button
              buttonHasLink={true}
              buttonIcon={"fa-regular fa-plus"}
              buttonIconPosition={"left"}
              buttonClasses={"!bg-orange-500"}
              buttonLabel={"Add Admin"}
              buttonLabelClasses={"font-medium !text-sm"}
              buttonLink={"/admin/add"}
            />
            <Tooltip
              id="add-admin"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">Click to add admin.</div>
              )}
            />
          </div>
        </div>
      </div>
      <Table
        tableHeadData={tableHeadData}
        tableData={tableData}
        isLoder={list?.loading}
        pageCount={list?.pageCount}
        currentPage={filters?.currentPage}
        onPageChange={(val) => {
          const newOffset = filters.limit * val?.selected;
          handleFilterChange("offset", newOffset, setFilters);
          handleFilterChange("currentPage", val?.selected, setFilters);
          setSelectedItems([]);
        }}
        isOrderingDisabled={true}
        originalData={[...list?.data]}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
        containerClasses={"flex-grow flex-shrink min-h-[calc(100vh-220px)]"}
        tableActionDropdownContainer={"!w-48"}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        deleteHandler={deletefunction}
      />
    </div>
  );
};

export default AdminList;
