export default function PositionLoader({ count }) {
  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <div
          key={i}
          className="col-span-1 group cursor-pointer p-3 border rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 bg-gray-50 border-l-4 border-gray-200"
        >
          <div className="flex items-center justify-between mb-3">
            <div className="w-2/3">
              <div className="h-5 bg-gray-200 rounded mb-2 shimmer-effect"></div>
              <div className="flex items-center text-gray-500 text-sm mt-1">
                <div className="w-4 h-4 bg-gray-200 rounded-full shimmer-effect mr-1"></div>
                <div className="h-4 bg-gray-200 rounded w-1/2 shimmer-effect"></div>
              </div>
            </div>
          </div>
          <div className="text-gray-500 text-sm">
            <div className="h-4 bg-gray-200 rounded w-full shimmer-effect"></div>
            <div className="h-4 bg-gray-200 rounded w-4/5 mt-2 shimmer-effect"></div>
          </div>
        </div>
      ))}
    </>
  );
}
