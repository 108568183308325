import { Link, useParams } from "react-router-dom";
import Image from "../../../elements/Image";
import { employeeDetails } from "../../../../services/frontService/EmployeeService";
import { useCallback, useEffect, useState } from "react";
import EmployeeDetailsLoader from "../../../loaders/EmployeeDetailsLoader";
import NoEmpImg from "../../../../assets/images/noEmpImg.svg";
import { setTitle } from "../../../../helpers/MetaTag";
import Directory from "../../../../pages/front/directory/Directory";
import EmployeeEdit from "../../../modals/front/EmployeeEdit";
import Button from "../../../form/Button";
import { formatPhoneNumber } from "../../../../helpers/formatPhoneNumber";

export default function EmployeeDetails() {
  setTitle("ORG Chart | Employees");
  const { id } = useParams();
  const [employee, setEmployee] = useState({ loading: false, data: {} });
  const [editmodalOpen, setEditModalOpen] = useState({ isOpen: false });

  const loadDetails = useCallback(() => {
    if (!id) return;
    setEmployee({ loading: true, data: {} });

    employeeDetails({ id }).then((res) => {
      if (res?.status === 200) {
        setEmployee({
          loading: false,
          data: res?.data,
        });
      } else {
        setEmployee({ loading: false, data: {} });
      }
    });
  }, [id]);

  useEffect(() => loadDetails(), [loadDetails]);

  return (
    <Directory
      name={
        !employee?.loading
          ? `${employee?.data?.firstName} ${employee?.data?.lastName}`
          : ""
      }
    >
      {employee?.loading ? (
        <EmployeeDetailsLoader />
      ) : (
        <div className="relative w-full bg-white shadow-md rounded-xl p-10 border border-gray-300 space-y-8 ">
          <header className="flex items-center space-x-8 border-b border-gray-200 pb-8">
            <div className="w-36 h-36 rounded-full border-2 shadow-lg">
              <Image
                src={employee?.data?.image?.url || NoEmpImg}
                alt={`${employee?.data?.firstName} ${employee?.data?.lastName}`}
                className="object-cover rounded-full"
              />
            </div>
            <div>
              <h2 className="text-4xl font-semibold text-gray-900">
                {employee?.data?.firstName} {employee?.data?.lastName}
              </h2>
              {employee?.data?.positionDetails?.title && (
                <p className="text-lg font-medium text-indigo-700 mt-1">
                  {employee?.data?.positionDetails?.title}
                </p>
              )}
              <p className="text-md text-gray-500">
                {employee?.data?.departmentDetails
                  ?.map((department) => department.name)
                  .join(" | ")}
              </p>
            </div>
          </header>
          <Button
            buttonIcon={"fa-fw fa-regular fa-pen-to-square  font-light"}
            buttonIconPosition={"left"}
            buttonClasses={
              "w-8 h-8 !border !bg-slate-50 !border-blue-200 !text-black hover:!bg-blue-100  flex items-center justify-center !absolute -top-1 right-6"
            }
            buttonFunction={() => {
              setEditModalOpen({ isOpen: true });
            }}
          />

          <section className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className="flex items-center bg-gray-50 p-5 shadow-sm rounded-lg border border-gray-200">
              <i className="fa-regular fa-envelope h-6 w-6 text-blue-500 mr-4" />
              <div>
                <h4 className="text-sm font-semibold text-gray-600">Email</h4>
                <p className="text-gray-800">{employee?.data?.email}</p>
              </div>
            </div>
            <div className="flex items-center bg-gray-50 p-5 shadow-sm rounded-lg border border-gray-200">
              <i className="fa-regular fa-phone h-6 w-6 text-green-500 mr-4" />
              <div>
                <h4 className="text-sm font-semibold text-gray-600">Phone</h4>
                <p className="text-gray-800">
                  {employee?.data?.phone
                    ? `+${formatPhoneNumber(employee?.data?.phone)}`
                    : "N/A"}
                </p>
              </div>
            </div>
          </section>

          <section className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div className="flex items-center bg-gray-50 p-5 shadow-sm rounded-lg border border-gray-200">
              <i className="fa-solid fa-user-shield h-6 w-6 text-purple-500 mr-4" />
              <div>
                <h4 className="text-sm font-semibold text-gray-600">Role</h4>
                <p className="text-gray-800 capitalize">
                  {employee?.data?.roleDetails?.length
                    ? employee.data.roleDetails.map((role, index) => (
                        <span key={index}>
                          {role.name}
                          {index < employee.data.roleDetails.length - 1 && ", "}
                        </span>
                      ))
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="flex items-center bg-gray-50 p-5 shadow-sm rounded-lg border border-gray-200">
              <i className="fa-solid fa-circle-check h-6 w-6 text-green-500 mr-4" />
              <div>
                <h4 className="text-sm font-semibold text-gray-600">Status</h4>
                <p className="text-gray-800">
                  {employee?.data?.status === 1 ? "Active" : "Inactive"}
                </p>
              </div>
            </div>
          </section>

          <section className="bg-gray-50 p-6 shadow-sm rounded-lg border border-gray-200 space-y-3">
            <h3 className="text-lg font-semibold text-gray-800 border-b border-gray-200 pb-2">
              Position Description
            </h3>
            <p className="text-gray-700">
              {employee?.data?.positionDetails?.description || "N/A"}
            </p>
          </section>

          <section className="bg-gray-50 p-6 shadow rounded-lg border border-gray-200 space-y-3">
            <h3 className="text-lg font-semibold text-gray-800 border-b border-gray-200 pb-2">
              Evaluatees
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {employee?.data?.evaluatees?.length > 0 ? (
                employee.data.evaluatees.map((evaluatee, i) => (
                  <Link
                    key={i}
                    to={`/directory/employee/${evaluatee?._id}`}
                    className="flex items-center space-x-4 p-4 bg-white rounded-lg border border-gray-200 shadow-sm"
                  >
                    <div className="w-14 h-14">
                      <Image
                        src={evaluatee?.image || NoEmpImg}
                        alt={`${evaluatee?.firstName} ${evaluatee?.lastName}`}
                        className="rounded-full object-cover border border-gray-300"
                      />
                    </div>
                    <div>
                      <h4 className="text-md font-semibold text-gray-900">
                        {evaluatee?.firstName} {evaluatee?.lastName}
                      </h4>
                      <p className="text-sm text-gray-500">
                        {evaluatee?.positionDetails?.title}
                      </p>
                    </div>
                  </Link>
                ))
              ) : (
                <p className="text-gray-500">No evaluatees available.</p>
              )}
            </div>
          </section>
        </div>
      )}

      <EmployeeEdit
        details={employee}
        setDetails={setEmployee}
        editmodalOpen={editmodalOpen}
        setEditModalOpen={setEditModalOpen}
      />
    </Directory>
  );
}
