import { apiRequest } from "../../util/apiUtils";

export const getChart = async (params) => {
  try {
    const res = await apiRequest("get", `/chart`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const chartAdd = async (payload) => {
  try {
    const res = await apiRequest("post", `/chart/add`, {
      body: payload,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const chartDetails = async (params) => {
  try {
    const res = await apiRequest("get", `/chart/${params?.id}`);

    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const chartEdit = async ({ id, payload }) => {
  try {
    const res = await apiRequest(
      "put",
      `/chart/${id}`,
      {
        body: payload,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const deleteChart = async ({ id, payload }) => {
  try {
    const res = await apiRequest("patch", `/chart/remove/${id}`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const unAssignUser = async ({ id }) => {
  try {
    const res = await apiRequest("patch", `/chart/remove-user/${id}`, {
      body: {},
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
