import React from "react";
import { classNames } from "../../helpers/classNames";

export default function Radio({
  radioName,
  radioInputClass,
  isChecked,
  radioLabel,
  radioLableClass,
  radioCheckClass,
  radioValue,
  divClass,
  onChange = () => {},
  ...props
}) {
  return (
    <div
      className={classNames(
        "relative overflow-hidden flex items-center",
        divClass
      )}
    >
      <input
        type="radio"
        name={radioName}
        className={classNames(
          "peer absolute top-0 left-0 w-5 h-5 opacity-0 z-10 cursor-pointer",
          radioInputClass
        )}
        checked={isChecked}
        value={radioValue}
        onChange={onChange}
      />
      <div
        className={classNames(
          "w-5 h-5 flex-shrink-0 border border-orange-500 rounded-full flex items-center justify-center text-[8px] bg-white text-black/0 peer-checked:bg-orange-500 peer-checked:border-orange-500 peer-checked:text-white",
          radioCheckClass
        )}
      >
        <i className="fa-solid fa-fw fa-circle"></i>
      </div>
      {radioLabel && (
        <div
          className={classNames(
            "text-sm text-slate-600 w-full ml-3 transition-all duration-200",
            radioLableClass
          )}
        >
          {radioLabel}
        </div>
      )}
    </div>
  );
}
