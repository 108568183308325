import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: { isOpen: false },
};

const employeeSlice = createSlice({
  name: "empModal",
  initialState,
  reducers: {
    employeeModal: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { employeeModal } = employeeSlice?.actions;
export default employeeSlice?.reducer;
