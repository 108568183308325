import React, { useState, useRef, useEffect, Fragment } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import { Combobox, Transition } from "@headlessui/react";
import { classNames } from "../../helpers/classNames";
import { usePopper } from "react-popper";
import { bottom } from "@popperjs/core";
import Checkbox from "./Checkbox";
import Search from "./Search";

export default function RecursiveSelect({
  onChange = () => {},
  dropdownData = [],
  loading = false,
  placeholder,
  value,
  label,
  labelClasses,
  isValidate,
}) {
  const [list, setList] = useState(dropdownData);
  const [selected, setSelected] = useState(null);
  const [expanded, setExpanded] = useState({});
  const [open, setOpen] = useState(false);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const dropdownRef = useRef(null);
  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: bottom,
    modifiers: [{ name: "offset", options: { offset: [0, 2] } }],
  });

  useClickOutside(dropdownRef, () => setOpen(false));

  const handleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  useEffect(() => {
    setList(dropdownData);
  }, [dropdownData]);

  const findItemById = (data, id) => {
    for (const item of data) {
      if (item._id === id) {
        return item;
      }
      if (item.children) {
        const found = findItemById(item.children, id);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  useEffect(() => {
    setSelected(value ? findItemById(dropdownData, value) : null);
  }, [value, dropdownData]);

  const handleChange = (item) => {
    setSelected(item ? item : null);
    onChange(item ? item : null);
  };

  const clearSelection = () => {
    setSelected(null);
    onChange(null);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);

    const filterItems = (items, expandedState = {}) => {
      return items
        .map((item) => {
          const isMatch = item.name.toLowerCase().includes(query.toLowerCase());

          if (item.children && item.children.length > 0) {
            const filteredChildren = filterItems(item.children, expandedState);

            if (isMatch || filteredChildren.length > 0) {
              expandedState[item._id] = true;
              return {
                ...item,
                children:
                  filteredChildren.length > 0 ? filteredChildren : undefined,
              };
            }
          }
          if (isMatch) {
            expandedState[item._id] = true;
            return { ...item };
          }

          return null;
        })
        .filter(Boolean);
    };

    if (query) {
      const updatedList = filterItems(dropdownData, expanded);
      setList(updatedList);
      setExpanded((prev) => ({
        ...prev,
        ...updatedList.reduce((acc, item) => {
          if (expanded[item._id]) acc[item._id] = true;
          return acc;
        }, {}),
      }));
    } else {
      setList(dropdownData);
      setExpanded({});
    }
  };

  const renderDropdownItems = (items) => {
    return items?.map((item) => {
      const isExpanded = expanded[item?._id];

      return (
        <Fragment key={item?._id}>
          <Combobox.Option
            className={({ active }) =>
              classNames(
                "relative flex items-center px-4 py-2 cursor-pointer rounded-md ",
                active ? "bg-orange-200 text-black" : "text-black"
              )
            }
            value={item}
          >
            <div className="relative flex items-center gap-2 w-full px-3">
              {item?.children && item?.children?.length > 0 && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleExpand(item?._id);
                  }}
                  className="text-gray-600 hover:text-gray-700 absolute -left-2"
                >
                  <i
                    className={classNames(
                      "fa-light fa-chevron-right transition-transform",
                      isExpanded && "rotate-90"
                    )}
                  />
                </div>
              )}
              <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                  checked={selected?._id === item?._id}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    handleChange(checked ? item : {});
                  }}
                />
              </div>
              <span className="flex-1">{item?.name}</span>
            </div>
          </Combobox.Option>
          {item?.children && isExpanded && (
            <div className="ml-6 border-l pl-4">
              {renderDropdownItems(item?.children)}
            </div>
          )}
        </Fragment>
      );
    });
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      {label && (
        <div
          className={classNames(
            "text-sm font-medium text-slate-500 mb-1",
            labelClasses
          )}
        >
          {label}
          {isValidate && (
            <span className="text-[8px] text-red-500 ml-0.5 absolute top-[-2px]">
              <i className="fa-solid fa-asterisk"></i>
            </span>
          )}
        </div>
      )}
      <Combobox as="div">
        <Combobox.Button
          ref={setTargetElement}
          className="w-full flex justify-between border rounded-md px-4 py-2 my-1 text-left text-slate-600"
          onClick={() => setOpen((prev) => !prev)}
        >
          <span>{selected?.name || placeholder}</span>
          {!selected && (
            <div
              className={classNames(
                "transition-all duration-200 text-sm",
                open ? "-rotate-180" : "",
                "text-slate-700" ? "" : "text-white"
              )}
              style={"text-slate-700" ? { color: "text-slate-700" } : {}}
            >
              {loading ? (
                <i
                  className={"fa-duotone fa-spinner-third animate-spin text-sm"}
                />
              ) : (
                <i
                  className={classNames("fa-fw", "fa-light fa-chevron-down")}
                />
              )}
            </div>
          )}
          {selected && (
            <div
              onClick={(e) => {
                e.preventDefault();
                clearSelection();
              }}
            >
              <i className="fa-light fa-times text-sm" />
            </div>
          )}
        </Combobox.Button>
        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Combobox.Options
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="absolute my-1 z-10 w-full bg-white border rounded-md shadow-md max-h-60 overflow-auto scrollbar px-3 p-2"
          >
            <Search
              isDebounce={true}
              searchValue={searchQuery}
              search={handleSearch}
              divClasses={"!rounded-xl"}
            />

            <div className="my-1">{renderDropdownItems(list)}</div>
            {loading && (
              <div className="p-4 text-center text-gray-500">Loading...</div>
            )}
            {!loading && list.length === 0 && (
              <div className="p-4 text-center text-gray-500">
                No results found.
              </div>
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
}
