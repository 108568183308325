import React, { useEffect, useState } from "react";
import Tabs from "../../../components/elements/Tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  companyDetails,
  superAdminDirectLogin,
} from "../../../services/adminService/CompanyService";
import CompanyDepartment from "./companyTabsData/CompanyDepartment";
import CompanyPosition from "./companyTabsData/CompanyPosition";
import CompanyEmployee from "./companyTabsData/CompanyEmployee";
import { Tooltip } from "react-tooltip";
import Button from "../../../components/form/Button";
import CompanyDetailsSkeleton from "../../../components/skeleton/CompanyDetailsSkeleton";
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber";
import CompanyEvents from "./companyTabsData/CompanyEvents";
import Indentification from "../../../components/elements/Indentification";
import { initialsValue } from "../../../helpers";

const AdminCompanyDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTabId, setSelectedTabId] = useState();
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (id) {
      setLoading(true);
      companyDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetails(res?.data?.company);
          setLoading(false);
        } else {
          toast.error("Something went wrong!");
          setLoading(false);
        }
      });
    }
  }, [id]);
  const data =
    details?.companyAdministrator?.length > 0 &&
    details?.companyAdministrator[0];

  const tabData = [
    {
      _id: 1,
      name: "Company Employees",
      component: <CompanyEmployee id={id} />,
    },
    {
      _id: 2,
      name: "Company Departments",
      component: <CompanyDepartment id={id} />,
    },
    {
      _id: 3,
      name: "Company Positions",
      component: <CompanyPosition id={id} />,
    },
    {
      _id: 4,
      name: "Company Calender",
      component: <CompanyEvents id={id} />,
    },
  ];
  useEffect(() => {
    if (location?.state === "department") {
      setSelectedTabId(2);
    } else if (location?.state === "position") {
      setSelectedTabId(3);
    } else if (location?.state === "events") {
      setSelectedTabId(4);
    } else {
      setSelectedTabId(1);
    }
  }, [location?.state]);

  const onSubmit = async () => {
    setLoading(true);
    const adminToken = localStorage.getItem("auth_token");
    try {
      const res = await superAdminDirectLogin(
        {
          id: data?._id,
          email: data?.email,
          uuid: data?.uuid,
          type: "administratorLogin",
        },
        adminToken
      );
      if (res?.status === 201) {
        toast.info(res?.message);
        localStorage.setItem("auth_token", res?.accessToken);
        localStorage.setItem("role", "COMPANY-ADMINISTRATOR");
        localStorage.setItem(
          "adminData",
          JSON.stringify({
            _id: res?.data?._id,
            email: res?.data?.email,
            uuid: res?.data?.uuid,
            authToken: adminToken,
            login: "Administrator-login",
          })
        );
        navigate("/");
      } else {
        toast.info(res?.data?.message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {!loading ? (
        <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
          <div className="flex items-center justify-between pb-4">
            <div className="font-semibold text-lg md:text-xl xl:text-2xl">
              Company Details
            </div>
            <div className="flex items-center">
              <div data-tooltip-id="edit-company" data-tooltip-place="bottom">
                <Button
                  buttonClasses="!bg-transparent !p-2 !border-orange-500 !text-orange-500"
                  buttonHasLink={true}
                  buttonType="button"
                  buttonLabel={"Edit"}
                  buttonIconPosition="left"
                  buttonIcon={"fa-regular fa-pen-to-square"}
                  buttonLabelClasses={"font-medium !text-sm"}
                  buttonLink={`/admin/company/edit/${id}`}
                />
                <Tooltip
                  id="edit-company"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-10"
                  render={() => (
                    <div className="text-white">
                      Click to edit company details.
                    </div>
                  )}
                />
              </div>

              <div
                className="ml-4"
                data-tooltip-id="add-company"
                data-tooltip-place="bottom"
              >
                <Button
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-right-to-bracket"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-orange-500"}
                  buttonLabel={"Administrator Login"}
                  buttonLabelClasses={"font-medium !text-sm"}
                  buttonFunction={onSubmit}
                />
                <Tooltip
                  id="add-company"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">
                      Click to Login as Administrator.
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <div className="bg-white border border-slate-200 rounded-xl shadow-md max-w-full w-full pt-4 px-0 transition-all duration-300">
              <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
                <div className="col-span-1 text-center md:mb-0 flex flex-col items-center">
                  <div className="overflow-hidden border-4 border-slate-200 rounded-full bg-white flex items-center justify-center w-36 h-36 mx-auto">
                    <Indentification
                      initial={initialsValue(details?.companyName)}
                      image={details?.image?.url}
                      alt={data.data}
                      fill={true}
                      className="object-cover h-full w-full"
                      size="max"
                      initialClass="!text-7xl"
                    />
                  </div>
                  <h6 className="text-2xl font-bold text-orange-500 mb-2">
                    {details?.companyName}
                  </h6>
                </div>

                <div className="col-span-3 flex flex-col md:flex-row w-full space-y-8 md:space-y-0 md:space-x-8">
                  <div className="flex-1">
                    <h2 className="text-xl font-semibold mb-4">
                      Company Information
                    </h2>
                    <div className="flex flex-col gap-2 w-full">
                      <p className="text-gray-700">
                        <span className="font-semibold">Company Name: </span>
                        {details?.companyName}
                      </p>
                      <p className="text-gray-700">
                        <span className="font-semibold">Company Email: </span>
                        {data?.email}
                      </p>
                      <p className="text-gray-700">
                        <span className="font-semibold">
                          No. of Employees:{" "}
                        </span>
                        {details?.noOfEmployees}
                      </p>
                      <p className="text-gray-700 flex items-center">
                        <span className="font-semibold mr-2">
                          Account Status:{" "}
                        </span>
                        <div
                          className={`text-xs font-medium leading-tight rounded py-1 px-2 ${
                            data?.accountStatus === "paid"
                              ? "bg-green-100 text-green-600"
                              : "bg-red-100 text-red-600"
                          }`}
                        >
                          {data?.accountStatus === "paid" ? "Paid" : "Unpaid"}
                        </div>
                      </p>
                    </div>
                  </div>

                  <div className="flex-1">
                    <h2 className="text-xl font-semibold mb-4">
                      Administrator Information
                    </h2>
                    <div className="flex flex-col gap-2 w-full">
                      <p className="text-gray-700">
                        <span className="font-semibold">Name: </span>
                        {data?.firstName + " " + data?.lastName}
                      </p>
                      <p className="text-gray-700">
                        <span className="font-semibold">Email: </span>
                        {data?.email}
                      </p>
                      <p className="text-gray-700">
                        <span className="font-semibold">Phone: </span>
                        {data?.phone
                          ? `+${formatPhoneNumber(data?.phone)}`
                          : "N/A"}
                      </p>
                      <p className="text-gray-700 flex items-center">
                        <span className="font-semibold mr-2">
                          Email Verified:{" "}
                        </span>
                        <div
                          className={`text-xs font-medium leading-tight rounded py-1 px-2 ${
                            data?.emailVerified === 1
                              ? "bg-green-100 text-green-600"
                              : "bg-red-100 text-red-600"
                          }`}
                        >
                          {data?.emailVerified === 1
                            ? "Verified"
                            : "Not Verified"}
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mt-2">
            <Tabs
              tabs={tabData}
              selectedTabId={selectedTabId}
              onTabChange={(tabId) => setSelectedTabId(tabId)}
            />
          </div>
        </div>
      ) : (
        <CompanyDetailsSkeleton />
      )}
    </>
  );
};

export default AdminCompanyDetails;
