import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
  isLogin: undefined,
};

export const authSlice = createSlice({
  name: "authdata",
  initialState,
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
  },
});

export const { setIsLogin } = authSlice.actions;

export default authSlice.reducer;
