import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";

// Debounce function
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const Search = ({
  isDebounce,
  placeholder,
  divClasses,
  inputClasses,
  iconClasses,
  iconPosition = "left",
  searchValue,
  resetKeyword = false,
  icon = "fa-regular fa-magnifying-glass", 
  search = () => {},
  onKeyDown = () => {},
  setResetKeyword = () => {},
}) => {
  const [isFocused, setFocused] = useState(false);
  const debouncedSearch = debounce(search, 500);

  const handleSearchChange = (e) => {
    if (isDebounce) {
      search(e.target.value);
    } else {
      debouncedSearch(e.target.value);
    }
  };
  useEffect(() => {
    if (resetKeyword) {
      setResetKeyword(false);
    }
  }, [resetKeyword, setResetKeyword]);
  return (
    <>
      <div
        className={classNames(
          "relative flex items-center h-10 w-full bg-white rounded-md overflow-hidden border transition-all duration-200",
          isFocused ? "border-orange-300" : "border-orange-200",
          divClasses
        )}
      >
        {iconPosition === "left" && (
          <div
            className={classNames(
              "w-10 h-10 flex-shrink-0 text-base flex items-center justify-center text-slate-600 border-r border-slate-300",
              iconClasses
            )}
          >
            <i className={classNames("fa-fw", icon)}></i>
          </div>
        )}
        <input
          type="text"
          placeholder={placeholder}
          className={classNames(
            "flex-shrink !border-0 w-full !ring-0 bg-transparent text-slate-800 placeholder:text-slate-500 placeholder:font-medium text-sm  px-3 font-normal ",
            inputClasses
          )}
          value={resetKeyword ? "" : searchValue}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          // onChange={(e) => debouncedSearch(e.target.value)}
          onChange={handleSearchChange}
          onKeyDown={onKeyDown}
        />
        {iconPosition === "right" && (
          <div
            className={classNames(
              "w-10 h-10 flex-shrink-0 text-base flex items-center justify-center text-opplio-grayB rounded-md",
              iconClasses
            )}
          >
            <i className={classNames("fa-fw", icon)}></i>
          </div>
        )}
      </div>
    </>
  );
};

export default Search;
