import { getDepartmentList } from "../../services/frontService/DepartmentService";
import { getPositionList } from "../../services/frontService/PositionService";
import { getEmployeeList } from "../../services/frontService/EmployeeService";
import { chartDetails } from "../../services/frontService/ChartService";
import SelectDropdownSearch from "../form/SelectDropdownSearch";
import React, { useCallback, useEffect, useRef } from "react";
import { formValidate } from "../../helpers/formValidate";
import ImageUploadSingle from "../form/ImageUploadSingle";
import noEmpImg from "../../assets/images/noEmpImg.svg";
import { useDebounce } from "../../helpers/useDebounce";
import { classNames } from "../../helpers/classNames";
import useForm from "../../hooks/useForm";
import Image from "../elements/Image";
import NodeModal from "../NodeModal";
import Button from "../form/Button";
import Input from "../form/Input";
import { useState } from "react";
import { useDisableZoom } from "../../helpers";
import { setProfile } from "../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { formatPhoneNumber } from "../../helpers/formatPhoneNumber";
import { useSelector } from "react-redux";

export default function NodeEditPanel({
  editNode,
  isSidepanelOpen,
  empDetails,
  setEmpDetails = () => {},
}) {
  useDisableZoom(false);
  const [empAddModal, setEmpAddModal] = useState(false);
  const [activeTab, setActiveTab] = useState("editRole");
  const [image, setImage] = useState(null);
  const popperElRef = useRef(null);
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [posList, setPosList] = useState({ loading: false, data: [] });
  const [depList, setDepList] = useState({ loading: false, data: [] });
  const [empList, setEmpList] = useState({ loading: false, data: [] });
  const [keywords, setKeywords] = useState({
    deptKeyword: "",
    posKeyword: "",
    empKeyword: "",
  });
  const profile = useSelector((state) => state?.profile?.data);

  const userRole = profile?.role?.find(
    (item) => item?.code === "COMPANY-MANAGER"
  );
  const newUser = !empDetails?.data?.userDetails;

  let validation = !newUser && {
    firstName: { required: true, message: "Please enter first name!" },
    lastName: { required: true, message: "Please enter last name!" },
    email: { required: true, message: "Please enter email!" },
    ...(empDetails?.data?.parentId
      ? {
          departmentId: {
            required: true,
            message: "Please choose a department !",
          },
          positionId: {
            required: true,
            message: "Please choose a position !",
          },
        }
      : {}),
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  validation = newUser &&
    values?.userId && {
      userId: {
        required: true,
        message: "Please Choose an employee or add a new employee!",
      },
    };

  useEffect(() => {}, []);

  const isAssignedEmp = empList?.data?.find(({ _id }) => _id === values?.userId)
    ?.assignedDetail?.assigned;

  const deptKeyword = useDebounce(keywords.deptKeyword, 400);
  const posKeyword = useDebounce(keywords.posKeyword, 400);
  const empKeyword = useDebounce(keywords.empKeyword, 400);

  useEffect(() => {
    if (isSidepanelOpen?._id) {
      setEmpDetails((pre) => ({ ...pre, loading: true }));
      chartDetails({ id: isSidepanelOpen._id }).then((res) =>
        setEmpDetails({
          loading: false,
          data: res?.status === 200 ? res.data : {},
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSidepanelOpen?._id]);

  useEffect(() => {
    if (empDetails?.data?._id) {
      const { userDetails: user } = empDetails?.data;
      setImage(user?.image?.url || "");
      setFieldsValue({
        positionId: user?.positionDetails?._id || "",
        departmentId:
          (user?.departmentDetails &&
            user?.departmentDetails?.length > 0 &&
            user?.departmentDetails?.map((item) => item?._id)) ||
          [],
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        email: user?.email || "",
        userId: userRole?.code === "COMPANY-MANAGER" ? user?._id : null,
      });
      setKeywords((pre) => ({
        ...pre,
        empKeyword:
          userRole?.code === "COMPANY-MANAGER" && user?._id
            ? user?.firstName + " " + user?.lastName
            : "",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empDetails]);

  useEffect(() => {
    if (empDetails?.data?._id) {
      const { userDetails: user } = empDetails?.data;
      if (user?.phone) {
        let phoneNumber = String(user?.phone);
        if (!phoneNumber.startsWith("+")) {
          phoneNumber = `+${phoneNumber}`;
          setPhone(phoneNumber);
        }
      } else {
        setPhone("");
      }
    }
  }, [empDetails]);

  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };

  const loadList = useCallback((type, setState, keyword, payload = {}) => {
    const apiMap = {
      depList: getDepartmentList,
      posList: getPositionList,
    };
    setState((pre) => ({ ...pre, loading: true }));
    apiMap[type]({ keyword, limit: 30, ...payload }).then((res) =>
      setState({ loading: false, data: res?.status === 200 ? res.docs : [] })
    );
  }, []);

  useEffect(() => {
    loadList("depList", setDepList, deptKeyword);
  }, [deptKeyword, loadList]);

  useEffect(() => {
    loadList("posList", setPosList, posKeyword);
  }, [posKeyword, loadList]);

  const loadempList = useCallback(() => {
    setEmpList({ loading: true, data: [] });
    getEmployeeList({
      limit: 100,
      keyword: empKeyword ? empKeyword : "",
      isAssigned: true,
    }).then((res) => {
      setEmpList({
        loading: false,
        data: res?.status === 200 ? res?.docs : [],
      });
    });
  }, [empKeyword]);

  useEffect(() => loadempList(), [loadempList]);

  const onSubmit = async (values) => {
    setEmpDetails((pre) => ({ ...pre, loading: true }));

    const res = await editNode({
      userId: newUser ? values?.userId : empDetails?.data?.userDetails?._id,
      editUser: newUser
        ? Boolean(values?.positionId || values?.departmentId)
        : true,
      ...(newUser
        ? {
            ...(values?.positionId && { positionId: values.positionId }),
            ...(values?.departmentId?.length > 0 && {
              departmentId: values.departmentId,
            }),
          }
        : {
            firstName: values?.firstName,
            lastName: values?.lastName,
            phone: phone || "",
            email: values?.email,
            image,
            ...(empDetails?.data?.parentId
              ? {
                  ...(values?.positionId && { positionId: values.positionId }),
                  ...(values?.departmentId && {
                    departmentId: values?.departmentId,
                  }),
                }
              : {
                  positionId: values.positionId || "null",
                  departmentId: values?.departmentId || [],
                }),
          }),
    });

    if (res?.status === 200) {
      setEmpDetails({ loading: false, data: { ...res?.data } });
      const { userDetails: user } = res?.data;
      !res?.data?.parentId &&
        dispatch(
          setProfile((pre) => ({
            ...pre,
            firstName: user?.firstName,
            lastName: user?.lastName,
            image: user?.image,
          }))
        );
    } else {
      setEmpDetails((pre) => ({ ...pre, loading: false }));
    }
  };
  useEffect(() => {
    if (values?.userId) {
      const selectedUser = empList?.data?.find(
        (user) => user._id === values.userId
      );

      if (selectedUser) {
        setFieldsValue({
          positionId: selectedUser?.positionDetails?._id || "",
          departmentId:
            selectedUser?.departmentDetails?.map((item) => item?._id) || [],
          userId: selectedUser,
        });
        setKeywords((pre) => ({
          ...pre,
          empKeyword: selectedUser?.firstName + " " + selectedUser?.lastName,
        }));
      }
    }
  }, [values?.userId, empList?.data, setFieldsValue]);

  return (
    <>
      <div
        ref={popperElRef}
        className={classNames(
          "fixed top-20 right-0 w-96 h-[calc(100vh-100px)] bg-white shadow-xl flex flex-col transition-all duration-300 rounded-tl-3xl rounded-bl-3xl overflow-hidden",
          isSidepanelOpen?.isOpen ? "translate-x-0" : "translate-x-full"
        )}
      >
        <div className="w-full h-full relative flex flex-col flex-grow">
          <div className="w-full h-12 flex ">
            <Button
              buttonHasLink={false}
              buttonLabel={newUser ? "Add Role" : "Edit Role"}
              buttonClasses={classNames(
                `!w-full !h-12 !rounded-none !border-none ${
                  newUser ? "!justify-start" : "!justify-center"
                }`,
                activeTab === "editRole"
                  ? "!bg-black !text-white"
                  : "!bg-gray-300 !text-black"
              )}
              buttonLabelClasses={`font-semibold !text-base ${
                newUser ? "pl-3" : ""
              }`}
              buttonFunction={() => setActiveTab("editRole")}
            />
            {!newUser && (
              <>
                <Button
                  buttonHasLink={false}
                  btnLoading={empDetails?.loading}
                  btnLoaderClasses={"!text-black"}
                  buttonLabel={
                    !empDetails?.loading &&
                    `${
                      empDetails?.data?.userDetails?.firstName +
                      " " +
                      empDetails?.data?.userDetails?.lastName
                    }`.substring(0, 18) +
                      (`${empDetails?.data?.userDetails?.firstName} ${empDetails?.data?.userDetails?.lastName}`
                        .length > 18
                        ? "..."
                        : "")
                  }
                  buttonClasses={classNames(
                    "!w-full !h-12 !rounded-none !border-none  !justify-center",
                    activeTab === "details"
                      ? "!bg-black !text-white"
                      : "!bg-gray-300 !text-black"
                  )}
                  buttonLabelClasses={`font-semibold !text-base `}
                  buttonFunction={() => setActiveTab("details")}
                />
              </>
            )}
          </div>

          {empDetails?.loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-slate-100 bg-opacity-75  z-10">
              <span className="dot-loader text-orange-500"></span>
            </div>
          )}

          <form
            className="w-full h-full overflow-y-scroll flex flex-col flex-grow scrollbar"
            onSubmit={(event) => handleSubmit(event, onSubmit)}
          >
            {activeTab === "editRole" && (
              <>
                {newUser || userRole?.code === "COMPANY-MANAGER" ? (
                  <>
                    <div className="px-4 py-4 border-b border-slate-200">
                      <SelectDropdownSearch
                        label={"Employee"}
                        placeholder={"Choose an Employee"}
                        isSearch={true}
                        xPlacement={"bottom"}
                        buttonArrowIcon={"fa-solid fa-chevron-down"}
                        tabTextColor={"text-slate-700"}
                        selectName={"userId"}
                        loading={empList?.loading}
                        onChange={handleChange}
                        dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                        value={values?.userId}
                        dropdownData={empList?.data?.map((item) => ({
                          name: item?.firstName + " " + item?.lastName,
                          _id: item?._id,
                          url: item?.image?.url ? item?.image?.url : noEmpImg,
                          icon: item?.assignedDetail?.assigned
                            ? "fa-regular fa-user-check !text-blue-500"
                            : "",
                          subItem: item?.positionDetails?.title,
                        }))}
                        keyword={keywords?.empKeyword || ""}
                        onSearch={(val) => {
                          setKeywords((pre) => ({ ...pre, empKeyword: val }));
                        }}
                        {...formValidate(errors, "userId")}
                        onDropdownOpen={(val) => {
                          if (val === true) loadempList();
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="px-4 py-4 border-b border-slate-200">
                    <div className="flex items-center justify-between mb-3">
                      <div className="text-sm font-medium text-slate-500">
                        Employee
                      </div>
                    </div>
                    <div className="relative mb-3">
                      <ImageUploadSingle
                        image={image}
                        setImage={setImage}
                        icon={"fa-regular fa-image"}
                        title={"Choose a file or drag & drop it here"}
                        note={"JPG or PNG formats, upto 5MB"}
                      />
                    </div>
                    <div className="relative mb-3">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-light fa-user text-slate-400"}
                        inputGroupPosition={"left"}
                        label={"First Name"}
                        inputPlaceholder={"First Name"}
                        inputClasses={"pl-2"}
                        inputType={"text"}
                        value={values?.firstName}
                        inputName={"firstName"}
                        onChange={handleChange}
                        isValidate
                        {...formValidate(errors, "firstName")}
                      />
                    </div>
                    <div className="relative mb-3">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-light fa-user text-slate-400"}
                        inputGroupPosition={"left"}
                        label={"Last Name"}
                        inputPlaceholder={"Last Name"}
                        inputClasses={"pl-2"}
                        inputType={"text"}
                        value={values?.lastName}
                        inputName={"lastName"}
                        onChange={handleChange}
                        isValidate
                        {...formValidate(errors, "lastName")}
                      />
                    </div>
                    <div className="relative mb-3">
                      <Input
                        isInputGroup={true}
                        isValidate
                        inputGroupIcon={"fa-light fa-envelope text-slate-400"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        inputClasses={"pl-2"}
                        inputPlaceholder={"Enter Employee Email"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    <div className="relative mt-0.5">
                      <div className="text-sm font-medium text-slate-500">
                        Phone
                      </div>
                      <PhoneInput
                        placeholder="(xxx) xxx-xxxx"
                        value={phone}
                        onChange={handlePhoneInputChange}
                        // defaultCountry="US"
                        international={false}
                      />
                    </div>
                  </div>
                )}
                {userRole?.code !== "COMPANY-MANAGER" && (
                  <div className=" py-4 mb-3 px-5 space-y-3">
                    {["Job Title", "Department"].map((label, index) => (
                      <div key={label} className="relative col-span-6">
                        <SelectDropdownSearch
                          label={label}
                          placeholder={`Choose a ${label}`}
                          xPlacement="bottom"
                          selectName={
                            index === 0 ? "positionId" : "departmentId"
                          }
                          isSearch={true}
                          buttonArrowIcon="fa-solid fa-chevron-down"
                          tabTextColor="text-slate-700"
                          dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                          dropdownClass={"!h-60"}
                          isMultiple={index === 0 ? false : true}
                          onSearch={(val) =>
                            setKeywords((pre) => ({
                              ...pre,
                              [index === 0 ? "posKeyword" : "deptKeyword"]: val,
                            }))
                          }
                          onChange={handleChange}
                          value={
                            index === 0
                              ? values?.positionId || ""
                              : values?.departmentId || []
                          }
                          loading={
                            index === 0 ? posList?.loading : depList?.loading
                          }
                          dropdownData={(index === 0
                            ? posList
                            : depList
                          )?.data?.map(({ _id, title, name, theme }) => ({
                            name: title || name,
                            ...(index === 0
                              ? {}
                              : { color: theme?.headerColor }),
                            _id,
                          }))}
                          {...formValidate(
                            errors,
                            index === 0 ? "positionId" : "departmentId"
                          )}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {isAssignedEmp && (
                  <div className="text-xs font-bold text-yellow-600 my-3 mx-5 border-dotted border-2 rounded-lg p-3 border-yellow-600">
                    <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
                    This employee has been already assigned a role ! If you save
                    it will replace it with the current one .
                  </div>
                )}
                <div className="py-5 flex justify-end items-end mt-auto px-4">
                  {newUser === false || (newUser === true && values?.userId) ? (
                    <Button
                      buttonHasLink={false}
                      buttonType="submit"
                      buttonLabelClasses="uppercase"
                      isDisable={empDetails?.loading}
                      buttonLabel={empDetails?.loading ? "Saving..." : "Save"}
                      buttonClasses="w-full !text-white !bg-orange-500 justify-center !rounded-lg"
                    />
                  ) : userRole?.code !== "COMPANY-MANAGER" &&
                    newUser === true &&
                    !values?.userId ? (
                    <Button
                      buttonHasLink={false}
                      buttonLabelClasses="uppercase"
                      buttonIconPosition="left"
                      buttonIcon="fa-solid fa-user"
                      buttonLabel="New Employee"
                      buttonFunction={() => {
                        setEmpAddModal(true);
                      }}
                      buttonClasses="w-full !text-white !bg-orange-500 justify-center !rounded-lg"
                    />
                  ) : null}
                </div>
              </>
            )}
            {activeTab === "details" && (
              <EmployeeDetails empDetails={empDetails} />
            )}
          </form>
          <NodeModal
            posList={posList}
            depList={depList}
            editNode={editNode}
            isOpen={empAddModal}
            empDetails={empDetails}
            dropdownValues={{
              departmentId: values?.departmentId,
              positionId: values?.positionId,
            }}
            setKeywords={setKeywords}
            setEmpDetails={setEmpDetails}
            setEmpAddModal={setEmpAddModal}
          />
        </div>
      </div>
    </>
  );
}

const EmployeeDetails = ({ empDetails }) => (
  <>
    <div className="border-b border-slate-200 mb-3 px-5 py-5">
      <div className="space-y-6">
        <div className="h-64">
          <Image
            alt="Employee Image"
            className="rounded-3xl object-cover "
            src={empDetails?.data?.userDetails?.image?.url || noEmpImg}
            effect={"blur"}
          />
        </div>
        <div className="relative w-full">
          <div className="text-slate-900 font-bold text-lg">
            {(
              empDetails?.data?.userDetails?.firstName &&
              empDetails?.data?.userDetails?.firstName +
                " " +
                empDetails?.data?.userDetails?.lastName
            )?.length > 30
              ? (
                  empDetails?.data?.userDetails?.firstName +
                  " " +
                  empDetails?.data?.userDetails?.lastName
                ).substring(0, 30) + "..."
              : empDetails?.data?.userDetails?.firstName +
                  " " +
                  empDetails?.data?.userDetails?.lastName || ""}
          </div>
          <div className="text-slate-500 font-medium text-sm">
            {empDetails?.data?.userDetails?.positionDetails?.title}
          </div>
        </div>
      </div>
    </div>
    <div className="px-4 mb-3 space-y-1">
      <div className="flex items-center space-x-2">
        <div className="w-6 text-base text-slate-500">
          <i className="fa-regular fa-fw fa-envelope"></i>
        </div>
        <div className="text-sm text-slate-500">
          {empDetails?.data?.userDetails?.email}
        </div>
      </div>
      <div className="flex items-center space-x-2">
        <div className="w-6 text-base text-slate-500">
          <i className="fa-regular fa-fw fa-mobile"></i>
        </div>
        <div className="text-sm text-slate-500">
          {empDetails?.data?.userDetails?.phone
            ? `+${formatPhoneNumber(empDetails?.data?.userDetails?.phone)}`
            : "N/A"}
        </div>
      </div>
    </div>
  </>
);
