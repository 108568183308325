import React, { useState, useEffect } from "react";

const NumberCountAnimation = ({ start, end, duration, className, symbol }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let animationFrameId;
    const startTime = new Date().getTime();

    const updateCount = () => {
      const currentTime = new Date().getTime();
      const progress = (currentTime - startTime) / duration;

      if (progress < 1) {
        setCount(Math.floor(start + progress * (end - start)));
        animationFrameId = requestAnimationFrame(updateCount);
      } else {
        setCount(end);
      }
    };

    updateCount();

    return () => cancelAnimationFrame(animationFrameId);
  }, [start, end, duration]);

  return (
    <div>
      <span className={className}>
        {symbol}
        {count}
      </span>
    </div>
  );
};

export default NumberCountAnimation;
