import { setProfile } from "../../../redux/slice/profileSlice,";
import { formValidate } from "../../../helpers/formValidate";
import React, { useEffect, useState } from "react";
import Modal from "../../elements/Modal";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import ColorModal from "../../shared/ColorModal";
import { hexToHsv, hexToRgb } from "../../../helpers";
import { useColor } from "react-color-palette";
import Radio from "../../form/Radio";
import RecursiveSelect from "../../form/RecursiveSelect";
import {
  departMentAdd,
  departMentEdit,
} from "../../../services/frontService/DepartmentService";

export default function DepartmentMange({
  tree,
  style,
  setList,
  isDepModal,
  setDepModal = () => {},
  setListupdate = () => {},
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [colorModal, setColorModal] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [color, setColor] = useColor("#71b483");
  const [isTree, setIsTree] = useState("department");
  const [parentId, setParentId] = useState(null);

  const validateHex = (hex) => {
    const hexRegex = /^#([0-9A-Fa-f]{3}){1,2}$/;
    return hexRegex.test(hex);
  };

  const data = isDepModal?.data;
  const validation = {
    name: { required: true, message: "Please enter  department name!" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (data) {
      setFieldsValue({
        name: data?.name,
      });
      setColor({
        hex: data?.borderColor || "#71b483",
        rgb: hexToRgb(data?.borderColor || "#71b483"),
        hsv: hexToHsv(data?.borderColor || "#71b483"),
      });
      setIsTree(data?.parentId ? "sub-department" : "department");
      setParentId(data?.parentId ? data?.parentId : null);
      setIsValid(validateHex(data?.borderColor || "#71b483"));
    }
  }, [data]);

  const onSubmit = async (values) => {
    if (!isValid) return;

    setLoading(true);

    try {
      let payload = {
        name: values?.name,
        headerColor: color?.hex,
      };

      if (isTree === "sub-department") {
        if (!parentId) {
          toast.info("Please select a department !");
          return;
        } else {
          payload.parentId = parentId;
        }
      }

      const response = data?._id
        ? await departMentEdit({
            ...payload,
            id: data?._id,
          })
        : await departMentAdd(payload);

      setLoading(false);

      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.message);
        setDepModal((pre) => ({ ...pre, isOpen: false }));

        style === "list"
          ? setList((prev) => ({
              ...prev,
              data:
                response?.status === 200
                  ? prev.data.map((item) =>
                      item?._id === response?.data?._id ? response?.data : item
                    )
                  : [response?.data, ...prev.data],
            }))
          : setListupdate((pre) => pre + 1);

        dispatch(
          setProfile((pre) => ({
            ...pre,
            departmentCount:
              response?.status === 201
                ? pre?.departmentCount + 1
                : pre?.departmentCount,
          }))
        );
      } else {
        toast.error(response?.data?.message || response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const radioOptions = [
    { label: "Department", value: "department" },
    { label: "Sub Department", value: "sub-department" },
  ];

  const mapTreeData = (data) => {
    return data?.map((item) => ({
      name: item?.name,
      _id: item?._id,
      children: item?.children ? mapTreeData(item.children) : [],
    }));
  };

  return (
    <>
      <Modal
        title={data?._id ? "Edit Department" : "Add Department"}
        size="lg"
        open={isDepModal?.isOpen}
        onClose={() => {
          setDepModal((pre) => ({ ...pre, isOpen: false }));
        }}
        modaltitleClasses={"!text-slate-200 text-lg"}
        headerClass={"!bg-black !rounded-t-2xl !text-white"}
      >
        <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
          <div className="relative col-span-12">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-building-user text-slate-400"}
              inputGroupPosition={"left"}
              label={"Name"}
              inputPlaceholder={"Enter department name"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.name}
              inputName={"name"}
              onChange={handleChange}
              isValidate={true}
              charLimit={200}
              {...formValidate(errors, "name")}
            />
          </div>
          {isTree === "sub-department" && (
            <div className="relative col-span-12 mt-1">
              <RecursiveSelect
                isValidate
                label="Deparment"
                placeholder="Choose a deparment"
                value={parentId}
                onChange={(val) => setParentId(val?._id)}
                dropdownData={mapTreeData(tree?.data)}
              />
            </div>
          )}
          <div className="relative col-span-6 space-y-2 pt-2">
            <p className="text-sm font-medium text-slate-600">
              Selected Color{" "}
            </p>
            <div className="w-full flex items-center border gap-1 rounded-md p-1">
              <div
                style={{ background: color?.hex }}
                className="relative w-10 h-10 flex-shrink-0 rounded-md shadow border border-slate-300 cursor-pointer"
                onClick={() => setColorModal(true)}
              ></div>
              <input
                type="text"
                value={color?.hex || ""}
                onChange={(e) => {
                  const newHex = e.target.value;
                  setColor({
                    hex: newHex,
                    rgb: hexToRgb(newHex),
                    hsv: hexToHsv(newHex),
                  });
                  setIsValid(validateHex(newHex));
                }}
                className={`text-slate-700 font-semibold border-none focus:ring-1 focus:ring-orange-300 rounded w-full ${
                  !isValid ? "border-red-500" : ""
                }`}
                placeholder="Enter color code"
              />
            </div>
            {!isValid && (
              <div className="text-xs font-medium text-orange-500 pl-2">
                <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
                Invalid color code. Please enter a valid hex code.
              </div>
            )}
          </div>

          <div
            className={`flex items-center ${
              style == "tree" ? "justify-between" : "justify-end"
            } mt-4`}
          >
            {style == "tree" && (
              <div className="flex gap-3">
                {radioOptions.map((option) => (
                  <Radio
                    key={option?.value}
                    radioLabel={option?.label}
                    radioValue={option?.value}
                    onChange={(e) => setIsTree(e.target.value)}
                    isChecked={isTree === option?.value}
                    radioName="isTree"
                  />
                ))}
              </div>
            )}
            <Button
              loading={loading}
              buttonLabel={
                data?._id
                  ? loading
                    ? "Saving..."
                    : "Save"
                  : loading
                  ? "Adding..."
                  : "Add"
              }
              isDisable={loading ? true : false}
              buttonIcon={
                data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"
              }
              buttonIconPosition={"left"}
              buttonType="submit"
            />
          </div>
        </form>
      </Modal>

      <ColorModal
        color={color}
        isOpen={colorModal}
        setColor={setColor}
        onClose={() => setColorModal(false)}
      />
    </>
  );
}
