import React from "react";

const FrontCompanySkeleton = () => {
  return (
    <div className="flex flex-row w-full pb-6">
      <div className="w-full relative z-10">
        <div className="w-full h-64 relative group">
          {/* Skeleton for banner image */}
          <div
            role="status"
            className="space-y-8  md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex md:items-center"
          >
            <div className="flex pt-12 justify-center w-full h-[250px] bg-gray-200 rounded dark:bg-gray-700 shimmer-effect"></div>
          </div>
        </div>

        <div className="w-10/12 mx-auto -mt-20 space-y-8">
          <div className="relative w-full md:flex block lg:space-x-8 md:space-x-5 space-x-0 bg-white lg:px-10 px-5 py-8 rounded-md shadow-sm border border-gray-100 z-0 overflow-hidden">
            {/* Skeleton for company image */}
            <div className="flex justify-center">
              <div className="w-32 h-32 bg-gray-200 rounded-lg flex-shrink-0 flex border border-slate-200 shimmer-effect"></div>
            </div>
            <div className="flex-shrink flex-grow space-y-2 flex flex-col">
              {/* Skeleton for company name and employee count */}
              <div className="md:flex block justify-between">
                <div className="w-48 h-6 bg-gray-200 rounded-md shimmer-effect"></div>
                <div className="w-32 h-6 bg-gray-200 rounded-md shimmer-effect"></div>
              </div>

              {/* Skeleton for company contact info */}
              <div className="space-y-4 mt-4">
                <div className="w-full flex gap-2 items-center">
                  <div className="w-6 h-6 bg-gray-200 rounded-full shimmer-effect"></div>
                  <div className="flex-shrink border-l border-slate-200 pl-2 flex-grow">
                    <div className="w-48 h-4 bg-gray-200 rounded-md shimmer-effect"></div>
                  </div>
                </div>
                <div className="w-full flex gap-2 items-center">
                  <div className="w-6 h-6 bg-gray-200 rounded-full shimmer-effect"></div>
                  <div className="flex-shrink border-l border-slate-200 pl-2 flex-grow">
                    <div className="w-48 h-4 bg-gray-200 rounded-md shimmer-effect"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-5 gap-6 bg-white rounded-md px-6 ">
            <div className="col-span-3 space-y-10 border-r pr-6 py-8">
              {/* Skeleton for Mission Statement */}
              <div className="relative space-y-2">
                <div className="relative bg-white rounded-lg px-5 py-4 space-y-4 shadow border border-gray-100">
                  <div className="flex justify-between items-center">
                    <div className="w-36 h-4 bg-gray-200 rounded-md shimmer-effect"></div>
                    <div className="w-12 h-6 bg-gray-200 rounded-md shimmer-effect"></div>
                  </div>
                  <div className="w-full h-6 bg-gray-200 rounded-md shimmer-effect mt-4"></div>
                </div>
              </div>

              {/* Skeleton for Administrator Details */}
              <div className="relative space-y-3">
                <div className="text-gray-800 font-bold text-lg">
                  <div className="w-48 h-4 bg-gray-200 rounded-md shimmer-effect"></div>
                </div>
                <div className="relative bg-white rounded-lg px-5 py-4 space-y-4 shadow border border-gray-100">
                  <div className="flex items-start gap-5">
                    <div className="w-24 h-24 bg-gray-200 rounded-lg flex-shrink-0 shimmer-effect"></div>
                    <div className="flex-shrink flex-grow space-y-2">
                      <div className="w-48 h-6 bg-gray-200 rounded-md shimmer-effect"></div>
                      <div className="relative space-y-1">
                        <div className="flex items-center gap-2">
                          <div className="w-6 h-6 bg-gray-200 rounded-full shimmer-effect"></div>
                          <div className="w-48 h-4 bg-gray-200 rounded-md shimmer-effect"></div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div className="w-6 h-6 bg-gray-200 rounded-full shimmer-effect"></div>
                          <div className="w-48 h-4 bg-gray-200 rounded-md shimmer-effect"></div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-shrink-0 flex items-end">
                      <div className="w-10 h-6 bg-gray-200 rounded-md shimmer-effect"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-2 space-y-2 py-8">
              {/* Skeleton for Links */}
              <div className="flex justify-between items-center">
                <div className="w-32 h-4 bg-gray-200 rounded-md shimmer-effect"></div>
                <div className="w-12 h-6 bg-gray-200 rounded-md shimmer-effect"></div>
              </div>
              <div className=" border rounded-lg h-auto">
                <div className="overflow-auto divide-y divide-slate-200">
                  {/* Skeleton for each link item */}
                  <div className="relative">
                    <div className=" h-16 bg-gray-200 rounded-md shimmer-effect m-2"></div>
                  </div>
                  <div className="relative">
                    <div className=" h-16 bg-gray-200 rounded-md shimmer-effect m-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrontCompanySkeleton;
