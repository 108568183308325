import React, { useEffect, useState } from "react";
import Modal from "../../elements/Modal";
import { formValidate } from "../../../helpers/formValidate";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import {
  employeeAdd,
  employeeEdit,
} from "../../../services/frontService/EmployeeService";
import ImageUploader from "../../form/ImageUploader";
import noEmpImg from "../../../assets/images/noEmpImg.svg";
import { setProfile } from "../../../redux/slice/profileSlice,";
import { useDispatch } from "react-redux";
import SelectDropdownSearch from "../../form/SelectDropdownSearch";
import Radio from "../../form/Radio";
import { useSelector } from "react-redux";
import { employeeModal } from "../../../redux/slice/employeeSlice";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function EmployeeManage({
  setList,
  dep_list,
  pos_list,
  setKeywords = () => {},
  loadDeplist = () => {},
  loadPoslist = () => {},
  setEmpAddtype = () => {},
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("COMPANY-EMPLOYEE");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");

  const empModal = useSelector((state) => state.empModal.data);

  const data = empModal?.data && empModal?.data;

  const validation = {
    firstName: { required: true, message: "Please enter  first name!" },
    lastName: { required: true, message: "Please enter  last name!" },
    email: { required: true, message: "Please enter  email !" },
    positionId: {
      required: userType !== "COMPANY-ADMINISTRATOR" ? true : false,
      message: "Please choose a position !",
    },
    departmentId: {
      required: userType !== "COMPANY-ADMINISTRATOR" ? true : false,
      message: "Please choose a department !",
    },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (values?.positionId) {
      setKeywords("");
    }
  }, [values?.positionId]);

  useEffect(() => {
    if (data) {
      setFieldsValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        positionId: data?.positionDetails?._id,
        departmentId: data?.departmentDetails?.map((item) => item?._id) || [],
      });
      setImage(data?.image?.url);
      const rolePriority = [
        "COMPANY-ADMINISTRATOR",
        "COMPANY-MANAGER",
        "COMPANY-EMPLOYEE",
      ];
      const highestPriorityRole = data?.roleDetails?.reduce(
        (prev, curr) => {
          return rolePriority.indexOf(curr.code) <
            rolePriority.indexOf(prev.code)
            ? curr
            : prev;
        },
        { code: "COMPANY-EMPLOYEE" }
      );
      setUserType(highestPriorityRole?.code || "COMPANY-EMPLOYEE");
    }
  }, [data]);

  useEffect(() => {
    if (data?.phone) {
      let phoneNumber = String(data?.phone);
      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
        setPhone(phoneNumber);
      }
    } else {
      setPhone("");
    }
  }, [data]);
  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };

  const onSubmit = async (values) => {
    setLoading(true);

    try {
      const payload = {
        ...values,
        userType,
        phone: phone || "",
        image: image ? image : "null",
      };

      const response = data?._id
        ? await employeeEdit({
            ...payload,
            id: data?._id,
          })
        : await employeeAdd(payload);
      setLoading(false);
      if (response?.status === 201 || response?.status === 200) {
        toast.success(response?.message);
        setEmpAddtype({ isOpen: false });
        dispatch(employeeModal({ data: {}, isOpen: false }));
        loadDeplist();
        loadPoslist();
        setList((prev) => ({
          ...prev,
          data:
            response?.status === 200
              ? prev.data.map((item) =>
                  item?._id === response?.data?._id ? response?.data : item
                )
              : [response?.data, ...prev.data],
        }));
        dispatch(
          setProfile((pre) => ({
            ...pre,
            employeeCount:
              response?.status === 201
                ? pre?.employeeCount + 1
                : pre?.employeeCount,
          }))
        );
      } else {
        toast.error(response?.data?.message || response.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const radioOptions = [
    { label: "Administrator", value: "COMPANY-ADMINISTRATOR" },
    { label: "Evaluator", value: "COMPANY-MANAGER" },
    { label: "Employee", value: "COMPANY-EMPLOYEE" },
  ];

  return (
    <Modal
      title={data?._id ? "Edit Employee" : "Add Employee"}
      size="xl2"
      open={empModal?.isOpen}
      onClose={() => {
        dispatch(employeeModal({ data: {}, isOpen: false }));
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-2xl !text-white"}
    >
      <form
        onSubmit={(event) => handleSubmit(event, onSubmit)}
        className="grid grid-cols-2 gap-2"
      >
        <div className="col-span-1 flex flex-col space-y-3 mt-8">
          <div className="relative ">
            <ImageUploader
              label=""
              image={image}
              altImage={noEmpImg}
              isMultiple={false}
              setImage={setImage}
              isCameraIcon={true}
              buttonLabelClasses="!text-xs"
              buttonIcon="fa-upload text-xs"
              labelClasses="text-lg font-bold"
              buttonClasses="!px-0 !h-8 !cursor-pointer"
              inputClasses="w-28 h-28 !hover:bg-slate-300"
              buttonLabel={data?.image ? "Change image" : "Upload image"}
            />
          </div>

          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-envelope text-slate-400"}
              inputGroupPosition={"left"}
              label={"Email"}
              inputPlaceholder={"Enter email"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"email"}
              value={values.email}
              inputName={"email"}
              isValidate={true}
              onChange={handleChange}
              {...formValidate(errors, "email")}
            />
          </div>
        </div>

        <div className="col-span-1 flex flex-col space-y-3 mt-1">
          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-user text-slate-400"}
              inputGroupPosition={"left"}
              label={"First Name"}
              inputPlaceholder={"Enter first name"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.firstName}
              isValidate={true}
              inputName={"firstName"}
              onChange={handleChange}
              charLimit={100}
              {...formValidate(errors, "firstName")}
            />
          </div>
          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-user text-slate-400"}
              inputGroupPosition={"left"}
              label={"Last Name"}
              charLimit={100}
              inputPlaceholder={"Enter last name"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.lastName}
              isValidate={true}
              inputName={"lastName"}
              onChange={handleChange}
              {...formValidate(errors, "lastName")}
            />
          </div>
          <div className="relative mt-1">
            <div className="text-sm font-medium text-slate-500">Phone</div>
            <PhoneInput
              placeholder="(xxx) xxx-xxxx"
              value={phone}
              onChange={handlePhoneInputChange}
              // defaultCountry="US"
              international={false}
            />
          </div>
        </div>

        <div className="col-span-2 relative mt-1">
          <SelectDropdownSearch
            label={"Position"}
            placeholder={"Choose an position"}
            isSearch={true}
            xPlacement={"bottom"}
            buttonArrowIcon={"fa-solid fa-chevron-down"}
            tabTextColor={"text-slate-700"}
            selectName={"positionId"}
            loading={pos_list?.loading}
            value={values?.positionId}
            onChange={handleChange}
            isValidate={true}
            dropdownButtonClass="!px-3 !border-gray-200 !w-full"
            {...formValidate(errors, "positionId")}
            dropdownData={pos_list?.data?.map(({ _id, title }) => ({
              name: title,
              _id: _id,
            }))}
            onSearch={(val) => {
              setKeywords((pre) => ({ ...pre, posKeyword: val }));
            }}
          />
        </div>
        {userType !== "COMPANY-ADMINISTRATOR" && (
          <div className="col-span-2 relative mt-1">
            <SelectDropdownSearch
              placeholder={"Choose an department"}
              selectName={"departmentId"}
              label={"Department"}
              isMultiple={true}
              isSearch={true}
              isValidate={true}
              loading={dep_list?.loading}
              value={values?.departmentId}
              onChange={handleChange}
              {...formValidate(errors, "departmentId")}
              dropdownData={dep_list?.data?.map(({ _id, name }) => ({
                name: name,
                _id: _id,
              }))}
              onSearch={(val) => {
                setKeywords((pre) => ({ ...pre, deptKeyword: val }));
              }}
            />
          </div>
        )}
        <div className={`col-span-2 flex items-center justify-between mt-4`}>
          <div className="flex gap-2">
            {radioOptions.map((option) => (
              <Radio
                key={option?.value}
                radioLabel={option?.label}
                radioValue={option?.value}
                onChange={(e) => setUserType(e.target.value)}
                isChecked={userType === option?.value}
                radioName="userRole"
              />
            ))}
          </div>
          <Button
            loading={loading}
            buttonLabel={
              data?._id
                ? loading
                  ? "Saving..."
                  : "Save"
                : loading
                ? "Adding..."
                : "Add"
            }
            isDisable={loading ? true : false}
            buttonIcon={
              data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"
            }
            buttonIconPosition={"left"}
            buttonType="submit"
          />
        </div>
      </form>
    </Modal>
  );
}
