import SelectDropdownSearch from "./form/SelectDropdownSearch";
import { formValidate } from "../helpers/formValidate";
import React, { useEffect, useState } from "react";
import Input from "./form/Input";
import useForm from "../hooks/useForm";
import Button from "./form/Button";
import Modal from "./elements/Modal";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function NodeModal({
  posList,
  depList,
  isOpen,
  editNode,
  setEmpAddModal = () => {},
  setKeywords = () => {},
  setEmpDetails = () => {},
  dropdownValues,
}) {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const validation = {
    firstName: { required: true, message: "Please enter first name!" },
    lastName: { required: true, message: "Please enter last name!" },
    email: { required: true, message: "Please enter email!" },
    departmentId: { required: true, message: "Please select a department !" },
    positionId: { required: true, message: "Please select a position !" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

    useEffect(() => {
      if (dropdownValues) {
        setFieldsValue({
          departmentId: dropdownValues?.departmentId,
          positionId: dropdownValues?.positionId,
        });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownValues]);

  if (!isOpen) return null;

  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };
  const onSubmit = async (values) => {
    setLoading(true);
    
    const res = await editNode({
      ...values,
      editUser: true,
      phone: phone || "",
      positionId: values.positionId,
      departmentId: values.departmentId,
    });
    setLoading(false);
    setFieldsValue({
      firstName: "",
      lastName: "",
      email: "",
      departmentId: [],
      positionId: null,
    });
    setPhone("");
    if (res?.status === 200) {
      setEmpDetails((pre) => ({ ...pre, data: { ...res?.data } }));
      setEmpAddModal(false);
    }
  };

  const inputProps = (name, label, placeholder, icon) => ({
    isInputGroup: true,
    inputGroupIcon: icon,
    inputGroupPosition: "left",
    label,
    inputPlaceholder: placeholder,
    inputClasses: "pl-2",
    inputType: name === "email" ? "email" : "text",
    value: values[name],
    inputName: name,
    onChange: handleChange,
    isValidate: name === "phone" ? false : true,
    ...formValidate(errors, name),
  });

  return (
    <Modal
      title="Add new employee"
      size="xl2"
      open={isOpen}
      onClose={() => {
        setPhone("");
        setEmpAddModal(false);
        setFieldsValue({
          firstName: "",
          lastName: "",
          email: "",
          departmentId: [],
          positionId: null,
        });
      }}
      modaltitleClasses="!text-slate-200 text-lg"
      headerClass="!bg-black !rounded-t-2xl !text-white"
    >
      <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
        <div className="py-2 px-6">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
              <Input
                {...inputProps(
                  "firstName",
                  "First Name",
                  "First Name",
                  "fa-light fa-user text-slate-400"
                )}
              />
            </div>
            <div className="col-span-6">
              <Input
                {...inputProps(
                  "lastName",
                  "Last Name",
                  "Last Name",
                  "fa-light fa-user text-slate-400"
                )}
              />
            </div>
            <div className="col-span-6">
              <Input
                {...inputProps(
                  "email",
                  "Email",
                  "Enter Employee Email",
                  "fa-light fa-envelope text-slate-400"
                )}
              />
            </div>
            <div className="col-span-6 mt-0.5">
              <div className="text-sm font-medium text-slate-500">Phone</div>
              <PhoneInput
                placeholder="(xxx) xxx-xxxx"
                value={phone}
                onChange={handlePhoneInputChange}
                // defaultCountry="US"
                international={false}
              />
            </div>
            <div className="col-span-12 border-t mt-3"></div>
            {["Job Title", "Department"].map((label, index) => (
              <div key={label} className={`relative col-span-12`}>
                <SelectDropdownSearch
                  label={label}
                  placeholder={`Select a ${label}`}
                  xPlacement="bottom"
                  selectName={index === 0 ? "positionId" : "departmentId"}
                  isSearch
                  isValidate
                  buttonArrowIcon="fa-solid fa-chevron-down"
                  tabTextColor="text-slate-700"
                  dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                  onSearch={(val) =>
                    setKeywords((pre) => ({
                      ...pre,
                      [index === 0 ? "posKeyword" : "deptKeyword"]: val,
                    }))
                  }
                  onChange={handleChange}
                  isMultiple={index === 0 ? false : true}
                  value={
                    index === 0
                      ? values?.positionId || null
                      : values?.departmentId || []
                  }
                  dropdownData={(index === 0 ? posList : depList)?.data?.map(
                    ({ _id, title, name }) => ({
                      name: title || name,
                      _id,
                    })
                  )}
                  {...formValidate(
                    errors,
                    index === 0 ? "positionId" : "departmentId"
                  )}
                />
              </div>
            ))}
          </div>
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex items-center justify-end space-x-3">
              <Button
                buttonType="submit"
                buttonLabel={loading ? "Saving..." : "Save"}
                isDisable={loading}
                buttonLabelClasses="uppercase"
                buttonClasses="!text-white !bg-orange-500 focus-visible:outline justify-center rounded-md border border-orange-500"
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
