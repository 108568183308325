import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/form/Button";
import { Tooltip } from "react-tooltip";
import Input from "../../../components/form/Input";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import { formValidate } from "../../../helpers/formValidate";
import {
  emailTemplateDetails,
  updateEmailTemplate,
} from "../../../services/adminService/EmailTemplateService";
import Editor from "../../../components/form/Editor";
import { companyEdit } from "../../../services/adminService/CompanyService";
import Select from "../../../components/form/Select";

const ManageEmailTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({});
  const [mailFor, setMailFor] = useState("user");

  const validation = {
    subject: { required: true, message: "Please enter subject!" },
    mailFor: { required: true, message: "Please enter email for!" },
    fromMail: { required: true, message: "Please enter email from!" },
    fromName: { required: true, message: "Please enter name from!" },
    ccMail: { required: true, message: "Please enter CC mail!" },
    content: { required: true, message: "Please enter description!" },
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({}, validation);

  useEffect(() => {
    if (id) {
      emailTemplateDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetails(res?.data);
        } else {
          toast.error("Something went wrong!");
        }
      });
    }
  }, [id]);
  useEffect(() => {
    if (id && details) {
      setFieldsValue({
        subject: details?.subject || "",
        mailFor: details?.mailFor || "",
        fromMail: details?.fromMail || "",
        fromName: details?.fromName || "",
        ccMail: details?.ccMail || "",
        content: details?.content || "",
      });
      setMailFor(details?.mailFor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, details]);

  const onSubmit = (values) => {
    setLoading(true);
    updateEmailTemplate(details?._id, {
      subject: values?.subject,
      mailFor: mailFor,
      fromMail: values?.fromMail,
      fromName: values?.fromName,
      ccMail: values?.ccMail,
      content: values?.content,
    }).then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        toast.success(res?.message)
        // navigate("/admin/email-template")
      }
      setLoading(false);
    });
  };
  return (
    <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
      <div className="flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">Update Email Template</div>
        <div className="relative flex items-center ml-auto gap-2">
          <div
            data-tooltip-id="reset-emailTemplate"
            data-tooltip-place="bottom"
          >
            <Button
              buttonType={"button"}
              buttonIcon={"fa-regular fa-rotate-left"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => resetField()}
            />
            <Tooltip
              id="reset-emailTemplate"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">Please click to reset.</div>
              )}
            />
          </div>
          <div data-tooltip-id="email-template" data-tooltip-place="bottom">
            <Button
              buttonType={"button"}
              buttonClasses={"!text-center"}
              buttonLabel={"Back"}
              buttonIcon={"fa-solid fa-arrow-left"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => navigate("/admin/email-template")}
            />
            <Tooltip
              id="email-template"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">
                  Click to go back to email-templates list.
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
        <div className="grid grid-cols-12 gap-4">
          <div className="input bg-white col-span-12 lg:col-span-12">
            <div className="grid lg:grid-cols-2 gap-3">
              <div className="relative">
                <Input
                  label={"Subject"}
                  inputType={"text"}
                  value={values?.subject}
                  inputPlaceholder={"Enter subject"}
                  inputName="subject"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "subject")}
                />
              </div>
              <div className="relative">
                <Select
                  label={"Email For"}
                  dropdownData={[
                    { name: "user", value: "user" },
                    { name: "admin", value: "admin" },
                    { name: "administrator", value: "administrator" },
                    { name: "employee", value: "employee" },
                  ]}
                  selectedValue={mailFor}
                  setCustomSelecedvalue={(val) => setMailFor(val)}
                />
                
              </div>
              <div className="relative">
                <Input
                  label={"From Email"}
                  inputType={"text"}
                  value={values?.fromMail}
                  inputPlaceholder={"Enter from email"}
                  inputName="fromMail"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "fromMail")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"From Name"}
                  inputType={"text"}
                  value={values?.fromName}
                  inputPlaceholder={"Enter from name"}
                  inputName="fromName"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "fromName")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"CC Mail"}
                  inputType={"text"}
                  value={values?.ccMail}
                  inputPlaceholder={"Enter CC mail"}
                  inputName="ccMail"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "ccMail")}
                />
              </div>
            </div>
            <div>
              <div className="relative">
                <Editor label="Description" value={values?.content} />
              </div>
            </div>
            <div className="w-full flex items-center justify-end gap-3 mt-4">
              <div data-tooltip-id="update-email" data-tooltip-place="left">
                <Button
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Update"}
                  loading={loading}
                />
                <Tooltip
                  id="update-email"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">
                      Click to update email-template.
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ManageEmailTemplate;
