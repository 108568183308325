import EmployeeManage from "../../../modals/front/EmployeeManage";
import React, { useCallback, useEffect, useState } from "react";
import DeleteModal from "../../../common/DeleteModal";
import Button from "../../../form/Button";
import Search from "../../../form/Search";
import { useNavigate } from "react-router-dom";
import {
  deleteEmployee,
  getEmployeeList,
} from "../../../../services/frontService/EmployeeService";
import { toast } from "react-toastify";
import NoDataFound from "../../../common/NodataFound";
import EmployeeLoader from "../../../loaders/EmployeeLoader";
import MultiselectDropdown from "../../../form/MultiselectDropdown";
import { getDepartmentList } from "../../../../services/frontService/DepartmentService";
import { getPositionList } from "../../../../services/frontService/PositionService";
import { createFilter } from "../../../../helpers";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../../redux/slice/profileSlice,";
import Pagination from "../../../common/Pagination";
import EmployeeFilterLoader from "../../../loaders/EmployeeFilterLoader";
import Directory from "../../../../pages/front/directory/Directory";
import { employeeModal } from "../../../../redux/slice/employeeSlice";
import CsvModal from "../../../modals/front/EmpAddType";
import { Tooltip } from "react-tooltip";
import { setTitle } from "../../../../helpers/MetaTag";
import EmployeeItem from "./EmployeeItem";
import EmployeeFilterSection from "./EmployeeFilterSection";
import { useDebounce } from "../../../../helpers/useDebounce";
import CsvUploadModal from "../../../modals/front/CsvUploadModal";
import EmpAddType from "../../../modals/front/EmpAddType";

export default function EmployeeSection({ children }) {
  setTitle("ORG Chart | Employees");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [department, setDepartment] = useState([]);
  const [position, setPosition] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [empAddType, setEmpAddtype] = useState({ isOpen: false });
  const [uploadModal, setUploadModal] = useState({ isOpen: false });
  const [keywords, setKeywords] = useState({
    keyword: "",
    posKeyword: "",
    deptKeyword: "",
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });

  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const [filters, setFilters] = useState({
    limit: 8,
    offset: 0,
    currentPage: 0,
  });

  const [posList, setPosList] = useState({ loading: true, data: [] });
  const [depList, setDepList] = useState({ loading: true, data: [] });

  const deptKeyword = useDebounce(keywords?.deptKeyword, 400);
  const posKeyword = useDebounce(keywords?.posKeyword, 400);
  const empKeyword = useDebounce(keywords?.keyword, 400);

  const loadEmpList = useCallback(() => {
    setList((prev) => ({ ...prev, data: [], loading: true }));

    const payload = {
      sortQuery: "-createdAt",
      ...filters,
      ...(empKeyword && { keyword: empKeyword }),
      ...(selectedDepartments?.length > 0 &&
        createFilter(selectedDepartments, "departmentId")),
      ...(selectedPositions?.length > 0 &&
        createFilter(selectedPositions, "positionId")),
    };

    getEmployeeList(payload).then((res) => {
      if (res?.status === 200) {
        setList({
          loading: false,
          data: res.docs,
          pageCount: res.totalPages,
          totalItem: res.totalDocs,
        });
      } else {
        setList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [
    filters,
    empKeyword,
    selectedDepartments.join(""),
    selectedPositions.join(""),
  ]);

  useEffect(() => loadEmpList(), [loadEmpList]);
  const loadDeplist = useCallback(() => {
    setDepList({ loading: true, data: [] });

    const payload = {
      limit: 30,
      ...(deptKeyword && { keyword: deptKeyword }),
    };

    getDepartmentList(payload).then((res) => {
      if (res?.status === 200) {
        setDepList({ loading: false, data: res.docs });
        setDepartment(
          res.docs.map(({ _id, name, departmentEmpCount }) => ({
            _id,
            name,
            value: _id,
            checked: false,
            count: departmentEmpCount,
          }))
        );
      } else {
        setDepList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [deptKeyword]);

  useEffect(() => loadDeplist(), [loadDeplist]);

  const loadPoslist = useCallback(() => {
    setPosList({ loading: true, data: [] });

    const payload = {
      limit: 30,
      ...(posKeyword && { keyword: posKeyword }),
    };

    getPositionList(payload).then((res) => {
      if (res?.status === 200) {
        setPosList({ loading: false, data: res.docs });
        setPosition(
          res.docs.map(({ _id, title, positionEmpCount }) => ({
            _id,
            name: title,
            value: _id,
            checked: false,
            count: positionEmpCount,
          }))
        );
      } else {
        setPosList((prev) => ({ ...prev, data: [], loading: false }));
        toast.error(res?.message);
      }
    });
  }, [posKeyword]);

  useEffect(() => loadPoslist(), [loadPoslist]);

  const deleteFunction = () => {
    setDeleteModal((prev) => ({ ...prev, loading: true }));

    deleteEmployee({ id: deleteModal.ids }).then((res) => {
      setDeleteModal((prev) => ({ ...prev, loading: false }));

      if (res?.status === 200) {
        setList((prev) => ({
          ...prev,
          data: prev.data.filter((item) => !deleteModal.ids.includes(item._id)),
        }));
        dispatch(
          setProfile((pre) => ({
            ...pre,
            employeeCount: pre?.employeeCount - 1,
          }))
        );
        setDeleteModal((prev) => ({ ...prev, isOpen: false }));
        toast.success(res?.message);
      } else {
        toast.error(res?.data?.message || res?.error);
      }
    });
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1024);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []); 
  console.log(window.innerWidth)

  return (
    <Directory>
      <div className="w-full relative grid grid-cols-4 gap-6 mb-4">
        {children ? (
          <>{children}</>
        ) : (
          <>
            <div className="col-span-4 xl:col-span-3 w-full h-full pt-8 pb-12 rounded-2xl bg-white">
              <div className="border-gray-100">
                <div className="flex justify-between items-start flex-col space-y-4 mb-4 pb-4">
                  <div className="relative flex flex-col items-center w-full">
                    <div className="relative flex flex-col w-full gap-5 pb-8 px-4 md:pl-10 md:pr-16 border-b border-b-slate-200">
                      <div className="relative flex flex-col w-full">
                        <div className="relative w-full">
                          <Search
                            placeholder={"Search someone by name , email"}
                            divClasses={
                              "!rounded-full !h-12 border border-[#bfbdbe] px-4 bg-[#fffef9]"
                            }
                            iconClasses={"border-none !text-xl text-[#807f7d]"}
                            search={(e) => {
                              setKeywords((pre) => ({ ...pre, keyword: e }));
                              setFilters((pre) => ({
                                ...pre,
                                offset: 0,
                                currentPage: 0,
                              }));
                            }}
                            inputClasses={"!px-1 placeholder:text-[#ababab]"}
                          />
                        </div>
                        <div className="relative flex items-center space-x-2 mt-8">
                          <div className="relative" data-tooltip-id="departments-dropdown">
                            <MultiselectDropdown
                              buttonPlaceholder={"Departments"}
                              xPlacement={"bottom"}
                              isCheckbox={true} 
                              dropdownData={department}
                              setDropdownData={setDepartment}
                              buttonArrowIconClasses={"!text-sm !right-3"}
                              buttonLabelClasses={"!text-sm !font-medium !border-black !text-left"}
                              dropdownContainer={"!w-52 lg:min-w-[250px] !left-[7px] !mt-[33px] !rounded-none relative before:absolute before:content-[''] before:left-[-15px] before:right-0 before:top-[-15px] before:rounded-[4px] before:w-[30px] before:h-[30px] before:transform before:rotate-45 before:bg-[#fff] before:translate-x-[-50%] before:shadow-[0px_2px_21px_2px_rgba(0,0,0,0.1)] before:z-[-1] before:mx-auto before:my-0 before:text-center"}
                              dropdownButtonClass={"!rounded-full !bg-slate-50 !pr-14 !bg-[#eeeeee] !text-[#ababab] !px-4 font-medium"}
                                buttonsinglelabelclass={"!text-xs md:!text-sm !font-medium"}
                              selectedItems={(val) =>
                                setSelectedDepartments(val)
                              }
                              onSearch={(e) =>
                                setKeywords((pre) => ({
                                  ...pre,
                                  deptKeyword: e,
                                }))
                              }
                            />
                            
                            <Tooltip
                              id="departments-dropdown"
                              place="top"
                                className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                              render={() => (
                                <div className="text-white">
                                  <div>Click to view departments list.</div>
                                </div>
                              )}
                            />
                          </div>
                          <div
                            className="relative"
                            data-tooltip-id="positions-dropdown"
                          >
                            <MultiselectDropdown
                              buttonPlaceholder={"Positions"}
                              xPlacement={"bottomLeft"}
                              isCheckbox={true} 
                              dropdownData={position}
                              setDropdownData={setPosition} 
                              dropdownButtonClass={"!rounded-full !bg-slate-50 !pr-14 !bg-[#eeeeee] !text-[#ababab] !px-4 font-medium"}  
                              buttonArrowIconClasses={"!text-sm right-3"}
                              buttonLabelClasses={"!text-xs md:!text-sm !font-medium"}
                                dropdownContainer={"!w-52 lg:min-w-[250px] !mt-[33px] !rounded-none relative before:absolute before:content-[''] before:left-[-10px] before:right-0 before:top-[-15px] before:rounded-[4px] before:w-[30px] before:h-[30px] before:transform before:rotate-45 before:bg-[#fff] before:translate-x-[-50%] before:shadow-[0px_2px_21px_2px_rgba(0,0,0,0.1)] before:z-[-1] before:mx-auto before:my-0 before:text-center"}
                                buttonsinglelabelclass={"!text-xs md:!text-sm !font-medium"}
                              selectedItems={(val) => setSelectedPositions(val)}
                              onSearch={(e) =>
                                setKeywords((pre) => ({
                                  ...pre,
                                  posKeyword: e,
                                }))
                              }
                            />
                            <Tooltip
                              id="positions-dropdown"
                              place="top"
                                className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                              render={() => (
                                <div className="text-white">
                                  <div>Click to view positions list.</div>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div> 
                    </div>
                    <div className="flex items-center justify-between w-full pt-8 px-4 md:pl-10 md:pr-16">
                      <div className=" text-[#ababab] text-base font-medium">
                      <span className="relative">
                          {" "}
                          ({list?.totalItem}){" "}
                        </span>employees
                        
                      </div>
                      <div className="relative flex md:space-x-2 gap-2 flex-row ml-auto items-center">
                        <div
                          className="relative"
                          data-tooltip-id="employee-add"
                        >
                          <Button
                            buttonLabel={"New"}
                            buttonIcon={"fa-regular fa-plus"}
                            buttonIconPosition={"left"}
                            buttonLabelClasses={"!font-bold !text-xs md:!text-sm"}
                            buttonClasses={"!z-0 !rounded-full !text-[10px] md:!text-xs uppercase px-2 md:!px-5 md:!pr-6 h-8 md:!h-10 font-bold"}
                            buttonFunction={() =>
                              setEmpAddtype({ isOpen: true })
                            }
                          fontFamily={""}
                          />
                          <Tooltip
                            id="employee-add"
                            place="top"
                              className="!text-slate-500 !text-xs !bg-slate-950 !bg-opacity-100 z-100 hidden md:block"
                            render={() => (
                              <div className="text-white">
                                <div>Click to add employees.</div>
                              </div>
                            )}
                          />
                        </div>
                        <div className="relative ml-auto xl:hidden">
                          <Button
                            buttonHasLink={false}
                            buttonIcon={"fa-regular fa-bars"}
                            buttonIconPosition={"left"}
                            buttonClasses={
                              "!p-0 !aspect-square flex items-center justify-center !rounded-md text-sm md:!text-xl !z-0 h-8 md:!h-10"
                            }
                            buttonFunction={() => setMenuOpen(true)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>

                  
                <div className="grid md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-8 pl-4 md:pl-10 pr-4 md:pr-16">
                  {list?.loading ? (
                      <EmployeeLoader count={8}/>
                  ) : list?.data &&
                    Array.isArray(list?.data) &&
                    list.data.length === 0 ? (
                    <div className="col-span-4">
                      <NoDataFound title="No Employees found" />
                    </div>
                  ) : (
                    list?.data?.map((emp, index) => (
                      <EmployeeItem
                        emp={emp}
                        index={index}
                        dispatch={dispatch}
                        setDeleteModal={setDeleteModal}
                        employeeModal={employeeModal}
                      />
                    ))
                  )}
                </div>

                {list?.pageCount > 1 && (
                  <div className="relative pt-4 mt-auto">
                    <Pagination
                      paginationClasses={"mt-4 justify-center"}
                      onPageChange={(val) => {
                        setFilters((pre) => ({
                          ...pre,
                          offset: pre?.limit * val?.selected,
                          currentPage: val?.selected,
                        }));
                      }}
                      pageCount={list?.pageCount}
                      currentPage={filters?.currentPage}
                    />
                  </div>
                    )}
              </div>
            </div>
            {menuOpen && (
                <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50" onClick={() => { setMenuOpen(false)}}></div>
            )}
          </>
        )}
        {depList?.loading ? (
          <EmployeeFilterLoader />
        ) : (
          <>
            {/* {(isMobile && menuOpen) || !isMobile ? ( */}
                
                  <EmployeeFilterSection
                    menuOpen={menuOpen}
                    department={department}
                    position={position}
                    navigate={navigate}
                    setMenuOpen={setMenuOpen}
                    setPosition={setPosition}
                    setDepartment={setDepartment}
                    setFilters={setFilters}

                  />
            {/* ) : null} */}
          </>
        )}

       
        
      </div>
      <EmployeeManage
        setList={setList}
        pos_list={posList}
        dep_list={depList}
        keywords={keywords}
        setKeywords={setKeywords}
        loadDeplist={loadDeplist}
        loadPoslist={loadPoslist}
        setEmpAddtype={setEmpAddtype}
      />
      <CsvUploadModal
        loadEmpList={loadEmpList}
        uploadModal={uploadModal}
        setEmpAddtype={setEmpAddtype}
        setUploadModal={setUploadModal}
      />
      <EmpAddType
        empAddType={empAddType}
        setEmpAddtype={setEmpAddtype}
        setUploadModal={setUploadModal}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => {
          setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        }}
        deleteHandler={deleteFunction}
        title="Delete Employee"
        subTitle="You are trying to delete a employee"
      />
    </Directory>
  );
}
