import React, { useEffect, useState } from "react";
import siteLogo from "../../assets/images/logo2.png";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import useForm from "../../hooks/useForm";
import { formValidate } from "../../helpers/formValidate";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { sendOtp } from "../../services/ProfileService";
import VerifyOtpForm from "./VerifyOtpForm";
import ChangePassword from "./ChangePassword";

const validation = {
  email: { required: true, message: "Email is required" },
};

const ForgotPassword = () => {
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({}, validation);
  const backgroundStyle = {
    backgroundColor: "#f1f5f9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const location = useLocation();


  const [errorMessage, setErrorMessage] = useState({
    color: "red-600",
    message: null,
  });
  const [loaded, setLoaded] = useState(true);
  const [isOtp, setIsOtp] = useState(false);
  const [email, setEmail] = useState();
  const [isVerified, setIsVerified] = useState(false);
  const [verifiedOtpData, setVerifiedOtpData] = useState();
  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(60);

  const otpSubmit = async (values) => {
    setLoaded(false);
    try {
      await sendOtp({
        requestType: "RESETPASSWORD",
        email: email,
      }).then((res) => {
        if (res?.status === 201) {
          toast.success("OTP Code Sent To Your Email Id.");
          setIsOtp(true);
          setEmail(email);
          setTimer(60);
          setShowResendButton(true);
        } else {
          toast.error(res?.data?.message);
        }
      });
      setLoaded(true);
    } catch (error) {
      setLoaded(true);
      setIsOtp(false);
      toast.error(error);
    }
  };
  useEffect(() => {
    let interval;
    if (showResendButton && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(interval);
            setShowResendButton(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [showResendButton, timer]);

  useEffect(() => {
    if (isOtp) {
      setShowResendButton(true);
    }
  }, [isOtp, isVerified]);

  // const cancelChange = () => {
  //   navigate(`/signin`);
  // };
  return (
    <div
      className="w-full min-h-screen flex items-center justify-center py-3 px-5"
      style={backgroundStyle}
    >
      <div className="w-full max-w-md xl:max-w-md bg-white py-8 px-4 rounded-2xl border border-orange-700">
        <div className="flex min-h-full flex-1 flex-col justify-center  py-4 lg:px-2">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-16 w-auto "
              src={siteLogo}
              alt="Your Company"
            />

            {isOtp && (
              <h3 className="mt-1 text-center text-xl font-bold leading-9 tracking-tight text-sky-600">
                Change Password
              </h3>
            )}
            {!isOtp && (
              <>
                <h3 className="mt-1 text-center text-xl font-bold leading-9 tracking-tight text-sky-600">
                  Send OTP
                </h3>
                <h4 className="mt-1 text-center text-md font-bold leading-9 tracking-tight text-slate-400">
                  Enter Your Email To Reset Password
                </h4>
              </>
            )}
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
            {!isOtp && (
              <>
                <form onSubmit={(event) => handleSubmit(event, otpSubmit)}>
                  <div className="mt-2">
                    <Input
                      label={"Email"}
                      labelClasses={"!text-xs"}
                      inputType={"text"}
                      inputPlaceholder={"Enter your email"}
                      value={values.email}
                      inputName={"email"}
                      onChange={(e) => {
                        handleChange(e);
                        setEmail(e.target.value);
                      }}
                      {...formValidate(errors, "email")}
                    />
                  </div>

                  <div className="flex justify-end">
                    <Button
                      buttonClasses={
                        "!justify-right bg-transparent !text-slate-500 border-none"
                      }
                      buttonHasLink={true}
                      buttonType={"button"}
                      buttonLabel={"Back to login page"}
                      buttonLink={location?.state==="admin" ? "/admin/signin" : "/signin"}
                    />
                  </div>
                  <div className=" flex justify-center items-center">
                    <Button
                      buttonClasses={
                        "w-full text-white hover:bg-orange-500 focus-visible:outline justify-center rounded-md mr-2"
                      }
                      buttonHasLink={false}
                      buttonType={"submit"}
                      buttonLabel={"Send Otp"}
                    />
                  </div>
                </form>
              </>
            )}

            {/* {isOtp && !isVerified && (
              <>
                <VerifyOtpForm
                  email={email}
                  setEmail={setEmail}
                  isVerified={isVerified}
                  setIsVerified={setIsVerified}
                  isOtp={isOtp}
                  setIsOtp={setIsOtp}
                  verifiedOtpData={verifiedOtpData}
                  setVerifiedOtpData={setVerifiedOtpData}
                />
                <div className="mt-2">
                  <Button
                    buttonClasses={
                      "w-full text-white !bg-sky-700 focus-visible:outline  justify-center rounded-md"
                    }
                    buttonHasLink={false}
                    buttonType={"button"}
                    buttonLabel={"Resend Otp"}
                    buttonFunction={onSubmit}
                    isDisable={showResendButton}
                  />
                </div>
                {showResendButton && (
                  <div className="text-center text-red-700 mt-2">
                    Resend OTP in {timer} seconds
                  </div>
                )}
              </>
            )} */}

            {isOtp && (
              <>
                <ChangePassword
                  email={email}
                  setEmail={setEmail}
                  showResendButton={showResendButton}
                  timer={timer}
                  otpSubmit={otpSubmit}
                  location={location}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
