import NoData from "../../assets/images/no-results-bg.2d2c6ee3.png";
import { classNames } from "../../helpers/classNames";
import Image from "../elements/Image";
import Button from "../form/Button";

const NoDataFound = ({
  title = "No Data Found",
  subtitle,
  action,
  imageClass,
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-full py-3 space-y-3">
      <div className={classNames("relative w-60 overflow-hidden", imageClass)}>
        <Image src={NoData} alt="no-data" className="w-full h-full " />
      </div>
      <div className="font-semibold text-xl capitalize text-slate-400">
        {title}
      </div>
      <div className="font-regular text-base text-slate-600">{subtitle}</div>
      {action && (
        <Button
          buttonHasLink={false}
          buttonIcon={"fa-regular fa-plus"}
          buttonIconPosition={"left"}
          buttonClasses={
            "!bg-opplio-blue !border-opplio-blue !text-white hover:!bg-white !border-opplio-blue  hover:!text-opplio-blue !space-x-1"
          }
          buttonLabel={action}
          buttonLabelClasses={"font-medium !text-sm"}
        />
      )}
    </div>
  );
};

export default NoDataFound;
