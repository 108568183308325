import React, { useState } from "react";

const TableSort = ({ sortState, onSort = () => {}, ...props }) => {
  const [sortType, setSortType] = useState(sortState);

  return (
    <>
      <button
        type="button"
        className="relative flex items-center justify-center text-sm w-6 h-6 rounded  text-white hover:shadow-lg transition-all bg-slate-700 duration-300 ease-in-out transform  focus:ring-1 focus:ring-offset-1 focus:ring-purple-400"
        onClick={() => {
          setSortType(sortType === "ASC" ? "DESC" : "ASC");
          onSort(sortType === "ASC" ? "DESC" : "ASC");
        }}
      >
        <span
          className={
            "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 " +
            (sortType === "ASC" ? "opacity-100" : "opacity-60")
          }
        >
          <i className="fa-solid fa-fw fa-sort-up"></i>
        </span>
        <span
          className={
            "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 " +
            (sortType === "DESC" ? "opacity-100" : "opacity-60")
          }
        >
          <i className="fa-solid fa-fw fa-sort-down"></i>
        </span>
      </button>
    </>
  );
};

export default TableSort;
