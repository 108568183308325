import Image from "../../../components/elements/Image";
import Button from "../../../components/form/Button";
import Search from "../../../components/form/Search";
import noEmpImg from "../../../assets/images/noEmpImg.svg";
import { Fragment, useCallback, useEffect, useState } from "react";
import DatePickerInput from "../../../components/form/DatePicker";
import EventManage from "../../../components/modals/front/EventManage";
import {
  deleteEvent,
  getEventList,
} from "../../../services/frontService/EventService";
import dayjs from "dayjs";
import { Menu, Transition } from "@headlessui/react";
import EventLoader from "../../../components/loaders/EventLoader";
import NoDataFound from "../../../components/common/NodataFound";
import DeleteModal from "../../../components/common/DeleteModal";
import { toast } from "react-toastify";
import { eventTypes } from "../../../components/constant/constant";
import { setTitle } from "../../../helpers/MetaTag";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";

export default function Calendar() {
  setTitle("ORG Chart | Calender");
  const [date, setDate] = useState(new Date());
  const [eventModal, setEventModal] = useState({ isOpen: false });
  const [eventList, setEventList] = useState({ loading: false, data: [] });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });
  const [type, setType] = useState("all");
  const [keyword, setKeyword] = useState("");

  const profile = useSelector((state) => state?.profile?.data);

  const userRole = profile?.role?.find(
    (item) => item?.code === "COMPANY-MANAGER"
  );


  const loadeventList = useCallback(() => {
    setEventList({ loading: true, data: [] });

    let payload = {
      startDate: dayjs(date).utc().startOf("day").toISOString(),
      type: type,
      keyword,
    };

    if (type === "all") {
      delete payload.type;
    }

    getEventList(payload).then((res) => {
      setEventList({
        loading: false,
        data: res?.status === 200 ? res?.docs : [],
      });
    });
  }, [date, type, keyword]);

  useEffect(() => loadeventList(), [loadeventList]);

  const deleteFunction = () => {
    setDeleteModal((prev) => ({ ...prev, loading: true }));

    deleteEvent({ id: deleteModal.ids }).then((res) => {
      setDeleteModal((prev) => ({ ...prev, loading: false }));

      if (res?.status === 200) {
        setEventList((prev) => ({
          ...prev,
          data: prev?.data?.filter(
            (item) => !deleteModal?.ids?.includes(item._id)
          ),
        }));
        setDeleteModal((prev) => ({ ...prev, isOpen: false }));
        toast.success(res?.message);
      } else {
        toast.error(res?.data?.message || res?.error);
      }
    });
  };

  return (
    <div className="flex w-full py-10 px-6 space-x-6 mb-4">
      <div className="relative flex-shrink flex-grow  flex flex-col">
        <div className="flex w-full items-center justify-between">
          <div className="font-semibold text-3xl text-slate-800 h-10 flex items-center">
            Calendar
          </div>
          {userRole?.code !== "COMPANY-MANAGER" && (
            <div
              className="flex gap-2"
              data-tooltip-id="add-event"
              data-tooltip-place="bottom"
            >
              <Button
                buttonIconPosition="left"
                buttonLabel="Add Status"
                buttonIcon="fa-light fa-calendar-plus"
                buttonFunction={() =>
                  setEventModal((pre) => ({ ...pre, isOpen: true, data: {} }))
                }
              />
              <Tooltip
                id="add-event"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">Click to add event.</div>
                )}
              />
            </div>
          )}
        </div>

        <div className="w-full min-h-[540px] grid grid-cols-12 mt-6 gap-4 ">
          <div className="col-span-8 bg-white rounded-md border border-slate-200 shadow p-6 ">
            <Search
              placeholder={"Search by person ..."}
              search={(val) => setKeyword(val)}
            />
            <div className="flex items-center gap-2 py-4 border-b ">
              <div
                onClick={() => setType("all")}
                className={`bg-orange-50 text-orange-500 hover:bg-orange-100 cursor-pointer  text-sm py-2 px-3 rounded-md font-semibold border border-orange-200 ${
                  type === "all" ? "!bg-orange-400 !text-white" : ""
                }`}
                data-tooltip-id="event-list"
                data-tooltip-place="bottom"
              >
                <i className="fa fa-list mr-2" />
                Everything
                <Tooltip
                  id="event-list"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100 !font-normal"
                  render={() => (
                    <div className="text-white">
                      Click to show all events list.
                    </div>
                  )}
                />
              </div>
              {Object.entries(eventTypes).map(
                ([key, { icon, color, border, hover, selected }]) => (
                  <div
                    key={key}
                    className={`flex items-center gap-2 border cursor-pointer ${color} text-sm py-2 px-3 rounded-md font-semibold ${hover} ${border} ${
                      type === key ? selected : ""
                    }`}
                    onClick={() => setType(key)}
                    data-tooltip-id={`${key}-tooltip`}
                    data-tooltip-place="bottom"
                  >
                    <i className={icon} />
                    <span className="capitalize">{key}</span>
                    <Tooltip
                      id={`${key}-tooltip`}
                      className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100 !font-normal"
                      render={() => (
                        <div className="text-white">
                          Click to show {key} list.
                        </div>
                      )}
                    />
                  </div>
                )
              )}
            </div>
            <div className="flex flex-col space-y-2 py-3">
              {eventList?.loading ? (
                <EventLoader count={8} />
              ) : eventList?.data &&
                Array.isArray(eventList?.data) &&
                eventList?.data?.length === 0 ? (
                <div className="col-span-4">
                  <NoDataFound title="No Events found" />
                </div>
              ) : (
                eventList?.data?.map((event, i) => {
                  const eventType = eventTypes[event?.type] || {};
                  return (
                    <div
                      key={i}
                      className="flex  items-center justify-between px-4 py-2 bg-white border rounded-lg shadow-sm"
                    >
                      <div className="flex items-center space-x-4">
                        {event?.type === "announcement" ||
                        event?.type === "holiday" ? (
                          <div className="w-12 h-12 relative ">
                            {event?.type === "holiday" ? (
                              <div className="w-full h-full flex items-center justify-center rounded-full bg-green-100 border border-green-200">
                                <i
                                  className={
                                    "fa-regular fa-umbrella-beach text-green-600"
                                  }
                                />
                              </div>
                            ) : (
                              <div className="w-full h-full flex items-center justify-center rounded-full bg-purple-100 border border-purple-200">
                                <i
                                  className={
                                    "fa-regular fa-bullhorn text-purple-600"
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="w-12 h-12 relative ">
                            <Image
                              src={event?.forUserDetail?.image?.url || noEmpImg}
                              alt={""}
                              effect={"blur"}
                              className="w-full h-full object-cover rounded-full border"
                            />
                            <span
                              className={`z-10  absolute bottom-1 -left-2 text-[10px] ${eventType.color} rounded-full py-[3px] px-[5px] border flex-shrink-0`}
                            >
                              <i className={eventType.icon} />
                            </span>
                          </div>
                        )}
                        <div className="flex-1">
                          {event?.type === "announcement" ||
                          event?.type === "holiday" ? (
                            <p className="text-sm font-semibold text-slate-700 capitalize">
                              {event?.type}
                            </p>
                          ) : (
                            <p className="text-sm font-semibold text-slate-700 capitalize">
                              {event?.forUserDetail?.firstName +
                                " " +
                                event?.forUserDetail?.lastName}
                            </p>
                          )}
                          <p className="text-xs text-slate-500">
                            {event?.type === "announcement" ||
                            event?.type === "holiday" ? (
                              <span className="capitalize font-semibold">
                                {event?.title}
                              </span>
                            ) : (
                              <span className="capitalize font-semibold">
                                {event?.type}
                              </span>
                            )}{" "}
                            • {dayjs(event?.startDate).format("MMM D")}
                            {event?.startDate !== event?.endDate &&
                              ` - ${dayjs(event?.endDate).format(
                                "MMM D"
                              )}`}{" "}
                          </p>
                        </div>
                      </div>
                      {userRole?.code !== "COMPANY-MANAGER" && (
                        <Menu as="div" className="relative">
                          <Menu.Button className="text-gray-500 hover:text-gray-700 focus:outline-none">
                            <i className="fa-solid fa-ellipsis-vertical text-xl" />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="z-30 absolute right-0 w-28 mt-2 origin-top-right bg-white border rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700"
                                      } flex items-center space-x-2  px-4 py-2 text-xs w-full hover:bg-blue-100 font-semibold`}
                                      onClick={() =>
                                        setEventModal((pre) => ({
                                          ...pre,
                                          isOpen: true,
                                          data: event,
                                        }))
                                      }
                                    >
                                      <i className="fa-regular fa-pen-to-square  text-blue-500" />
                                      <span>Edit</span>
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      className={`${
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700"
                                      } flex items-center space-x-2  px-4 py-2 text-xs w-full hover:bg-red-100 font-semibold`}
                                      onClick={() =>
                                        setDeleteModal((pre) => ({
                                          ...pre,
                                          isOpen: true,
                                          ids: [event?._id],
                                        }))
                                      }
                                    >
                                      <i className="fa-regular fa-trash-alt text-red-500" />
                                      <span>Delete</span>
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className="col-span-4 bg-white rounded-md border border-slate-200 shadow p-6">
            <DatePickerInput
              type="calendar"
              startDate={date}
              setStartDate={setDate}
              dateFormat={"yyyy/MM/dd"}
            />
          </div>
        </div>
      </div>
      <DeleteModal
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => {
          setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        }}
        deleteHandler={deleteFunction}
        title="Delete Employee"
        subTitle="You are trying to delete a employee"
      />
      <EventManage
        date={date}
        eventModal={eventModal}
        setEventList={setEventList}
        setEventModal={setEventModal}
      />
    </div>
  );
}
