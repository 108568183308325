import { deleteDepartment } from "../../../../services/frontService/DepartmentService";
import Directory from "../../../../pages/front/directory/Directory";
import DepartmentMange from "../../../modals/front/DepartmentMange";
import { setProfile } from "../../../../redux/slice/profileSlice,";
import { setTitle } from "../../../../helpers/MetaTag";
import DepartmentListItem from "./DepartmentListItem";
import DepartmentTreeItem from "./DepartmentTreeItem";
import DeleteModal from "../../../common/DeleteModal";
import Search from "../../../form/Search";
import Button from "../../../form/Button";
import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

export default function DepartmentSection() {
  setTitle("ORG Chart | Departments");
  const [isDepModal, setDepModal] = useState({ data: {}, isOpen: false });
  const [listupdate, setListupdate] = useState(0);
  const [style, setStyle] = useState("list");
  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [tree, setTree] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });

  const dispatch = useDispatch();

  const deletefunction = () => {
    setDeleteModal((pre) => ({ ...pre, loading: true }));
    deleteDepartment({
      id: deleteModal?.ids,
    }).then((res) => {
      setDeleteModal((pre) => ({ ...pre, loading: false }));
      if (res?.status === 200) {
        style === "list"
          ? setList((pre) => {
              const updatedData = pre?.data?.filter(
                (item) => !deleteModal?.ids.includes(item._id)
              );

              return {
                ...pre,
                data: updatedData,
              };
            })
          : setListupdate((pre) => pre + 1);
        dispatch(
          setProfile((pre) => ({
            ...pre,
            departmentCount: pre?.departmentCount - 1,
          }))
        );
        setDepModal((pre) => ({ ...pre, isOpen: false }));
        setDeleteModal((pre) => ({ ...pre, isOpen: false }));
        toast.success(res?.message);
      } else {
        toast.error(res?.message || res?.error);
      }
    });
  };

  return (
    <Directory>
      <div className="!h-full flex flex-col">
        <div className="w-full flex  flex-col lg:flex-row space-y-2 lg:items-center justify-between">
          <p className="text-lg text-gray-800 pl-2">
            Departments
            <span className="text-[#f76310]"> ({list?.totalItem})</span>
          </p>
          <div className="flex gap-2 items-center justify-center ">
            <Search
              placeholder={"Search departments"}
              search={(val) => setKeyword(val)}
              divClasses={"!rounded-md !h-10 border border-slate-300"}
            />
            <div className="flex gap-2">
              <Button
                buttonIcon={"fa-regular fa-list text-base"}
                buttonIconPosition={"left"}
                buttonFunction={() => setStyle("list")}
                buttonClasses={`!border-orange-300 ${
                  style === "list" ? "" : "!bg-white !text-orange-500"
                }`}
              />
              <Button
                buttonIcon={"fa-regular fa-sitemap text-base"}
                buttonIconPosition={"left"}
                buttonFunction={() => setStyle("tree")}
                buttonClasses={`!border-orange-300 ${
                  style === "tree" ? "" : "!bg-white !text-orange-500"
                }`}
              />
            </div>
            <div data-tooltip-id="add-department">
              <Button
                buttonLabel={"New"}
                buttonIcon={"fa-regular fa-plus text-xs"}
                buttonClasses={"!w-20"}
                buttonIconPosition={"left"}
                buttonFunction={() => {
                  setDepModal({ data: {}, isOpen: true });
                }}
              />
              <Tooltip
                id="add-department"
                place="left"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">Click to add departments.</div>
                )}
              />
            </div>
          </div>
        </div>

        {style === "list" ? (
          <DepartmentListItem
            list={list}
            setList={setList}
            keyword={keyword}
            isDepModal={isDepModal}
            setDepModal={setDepModal}
            setDeleteModal={setDeleteModal}
          />
        ) : (
          <DepartmentTreeItem
            tree={tree}
            setTree={setTree}
            isDepModal={isDepModal}
            listupdate={listupdate}
            setDepModal={setDepModal}
            setDeleteModal={setDeleteModal}
          />
        )}

        <DepartmentMange
          tree={tree}
          style={style}
          setTree={setTree}
          setList={setList}
          isDepModal={isDepModal}
          setDepModal={setDepModal}
          setListupdate={setListupdate}
        />
        <DeleteModal
          open={deleteModal?.isOpen}
          loading={deleteModal?.loading}
          onClose={() => {
            setDeleteModal((pre) => ({ ...pre, isOpen: false }));
          }}
          deleteHandler={deletefunction}
          title="Delete Department"
          subTitle="You are trying to delete a department"
        />
      </div>
    </Directory>
  );
}
