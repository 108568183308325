import React, { useCallback, useEffect, useState } from "react";
import Search from "../../../../components/form/Search";
import { Tooltip } from "react-tooltip";
import { getEventList } from "../../../../services/frontService/EventService";
import  dayjs from "dayjs";
import { eventTypes } from "../../../../components/constant/constant";
import EventLoader from "../../../../components/loaders/EventLoader";
import NoDataFound from "../../../../components/common/NodataFound";
import Image from "../../../../components/elements/Image";
import DatePickerInput from "../../../../components/form/DatePicker";

const CompanyEvents = ({id}) => {
  const [date, setDate] = useState(new Date());
  const [eventList, setEventList] = useState({ loading: false, data: [] });
  const [type, setType] = useState("all");
  const [keyword, setKeyword] = useState("");
  const loadeventList = useCallback(() => {
    setEventList({ loading: true, data: [] });

    let payload = {
      startDate: dayjs(date).utc().startOf("day").toISOString(),
      type: type,
      keyword,
      companyId : id,
    };

    if (type === "all") {
      delete payload.type;
    }

    getEventList(payload).then((res) => {
      setEventList({
        loading: false,
        data: res?.status === 200 ? res?.docs : [],
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, type, keyword]);

  useEffect(() => loadeventList(), [loadeventList]);
  return (
    <div className="flex w-full py-2 px-6 space-x-6 mb-4">
      <div className="relative flex-shrink flex-grow  flex flex-col">
        <div className="w-full min-h-[540px] grid grid-cols-12 mt-6 gap-4 ">
          <div className="col-span-8 bg-white rounded-md border border-slate-200 shadow p-6 ">
            <Search
              placeholder={"Search by person ..."}
              search={(val) => setKeyword(val)}
            />
            <div className="flex items-center gap-2 py-4 border-b ">
              <div
                onClick={() => setType("all")}
                className={`bg-orange-50 text-orange-500 hover:bg-orange-100 cursor-pointer  text-sm py-2 px-3 rounded-md font-semibold border border-orange-200 ${
                  type === "all" ? "!bg-orange-400 !text-white" : ""
                }`}
                data-tooltip-id="event-list"
                data-tooltip-place="bottom"
              >
                <i className="fa fa-list mr-2" />
                Everything
                <Tooltip
                  id="event-list"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100 !font-normal"
                  render={() => (
                    <div className="text-white">
                      Click to show all events list.
                    </div>
                  )}
                />
              </div>
              {Object.entries(eventTypes).map(
                ([key, { icon, color, border, hover, selected }]) => (
                  <div
                    key={key}
                    className={`flex items-center gap-2 border cursor-pointer ${color} text-sm py-2 px-3 rounded-md font-semibold ${hover} ${border} ${
                      type === key ? selected : ""
                    }`}
                    onClick={() => setType(key)}
                    data-tooltip-id={`${key}-tooltip`}
                    data-tooltip-place="bottom"
                  >
                    <i className={icon} />
                    <span className="capitalize">{key}</span>
                    <Tooltip
                      id={`${key}-tooltip`}
                      className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100 !font-normal"
                      render={() => (
                        <div className="text-white">
                          Click to show {key} list.
                        </div>
                      )}
                    />
                  </div>
                )
              )}
            </div>
            <div className="flex flex-col space-y-2 py-3">
              {eventList?.loading ? (
                <EventLoader count={8} />
              ) : eventList?.data &&
                Array.isArray(eventList?.data) &&
                eventList?.data?.length === 0 ? (
                <div className="col-span-4">
                  <NoDataFound title="No Events found" />
                </div>
              ) : (
                eventList?.data?.map((event, i) => {
                  const eventType = eventTypes[event?.type] || {};
                  return (
                    <div
                      key={i}
                      className="flex  items-center justify-between px-4 py-2 bg-white border rounded-lg shadow-sm"
                    >
                      <div className="flex items-center space-x-4">
                        {event?.type === "announcement" ||
                        event?.type === "holiday" ? (
                          <div className="w-12 h-12 relative ">
                            {event?.type === "holiday" ? (
                              <div className="w-full h-full flex items-center justify-center rounded-full bg-green-100 border border-green-200">
                                <i
                                  className={
                                    "fa-regular fa-umbrella-beach text-green-600"
                                  }
                                />
                              </div>
                            ) : (
                              <div className="w-full h-full flex items-center justify-center rounded-full bg-purple-100 border border-purple-200">
                                <i
                                  className={
                                    "fa-regular fa-bullhorn text-purple-600"
                                  }
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="w-12 h-12 relative ">
                            <Image
                              src={event?.forUserDetail?.image?.url}
                              alt={""}
                              effect={"blur"}
                              className="w-full h-full object-cover rounded-full border"
                            />
                            <span
                              className={`z-10  absolute bottom-1 -left-2 text-[10px] ${eventType.color} rounded-full py-[3px] px-[5px] border flex-shrink-0`}
                            >
                              <i className={eventType.icon} />
                            </span>
                          </div>
                        )}
                        <div className="flex-1">
                          {event?.type === "announcement" ||
                          event?.type === "holiday" ? (
                            <p className="text-sm font-semibold text-slate-700 capitalize">
                              {event?.type}
                            </p>
                          ) : (
                            <p className="text-sm font-semibold text-slate-700 capitalize">
                              {event?.forUserDetail?.firstName +
                                " " +
                                event?.forUserDetail?.lastName}
                            </p>
                          )}
                          <p className="text-xs text-slate-500">
                            {event?.type === "announcement" ||
                            event?.type === "holiday" ? (
                              <span className="capitalize font-semibold">
                                {event?.title}
                              </span>
                            ) : (
                              <span className="capitalize font-semibold">
                                {event?.type}
                              </span>
                            )}{" "}
                            • {dayjs(event?.startDate).format("MMM D")}
                            {event?.startDate !== event?.endDate &&
                              ` - ${dayjs(event?.endDate).format(
                                "MMM D"
                              )}`}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          <div className="col-span-4 bg-white rounded-md border border-slate-200 shadow p-6">
            <DatePickerInput
              type="calendar"
              startDate={date}
              setStartDate={setDate}
              dateFormat={"yyyy/MM/dd"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyEvents;
