import dayjs from "dayjs";
import { useEffect } from "react";

export const initialsValue = (str = "") => {
  const strArr = str?.split(" ");
  const initials =
    (strArr &&
      Array.isArray(strArr) &&
      strArr[0]
        .slice(0, 1)
        .concat(strArr?.length > 1 ? strArr.at(-1).slice(0, 1) : "")
        .toUpperCase()) ||
    [];
  return initials;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const handleOnDragEnd = (
  result,
  list = [],
  setList = () => {},
  callback = () => {}
) => {
  if (!result.destination) return;

  const items = reorder(list, result.source.index, result.destination.index);
  setList(items);
  callback(items);
};

export const createFilter = (filterArr = [], key) => {
  if (filterArr && typeof filterArr[0] === "boolean") {
    return { [key]: filterArr[0] };
  }
  return Array.isArray(filterArr) && filterArr.length > 0
    ? filterArr.reduce((acc, item, i) => {
        acc[`${key}[${i}]`] = item;
        return acc;
      }, {})
    : {};
};

export const handleFilterChange = (name, value, setFilters = () => {}) => {
  setFilters((prevFilters) => ({
    ...prevFilters,
    [name]: value,
  }));
};

export const isValidDate = (value, format) => {
  return dayjs(value, format, true).isValid();
};

export function useDisableZoom(shouldDisable) {
  useEffect(() => {
    const preventZoom = (e) => {
      // Prevent zoom only when the Ctrl key is pressed or pinch gestures are detected
      if (shouldDisable && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
      }
    };

    if (shouldDisable) {
      // Disable zoom for keyboard shortcuts and mouse events
      document.addEventListener("wheel", preventZoom, { passive: false });
      document.addEventListener("keydown", (e) => {
        if (e.ctrlKey && (e.key === "+" || e.key === "-" || e.key === "0")) {
          e.preventDefault();
        }
      });
    }

    return () => {
      if (shouldDisable) {
        // Cleanup event listeners
        document.removeEventListener("wheel", preventZoom);
        document.removeEventListener("keydown", preventZoom);
      }
    };
  }, [shouldDisable]);
}

// COLOR -LIGHTENER
export function lightenHexColor(hex, percent) {
  if (!hex || !/^#?[0-9A-Fa-f]{6}$/.test(hex)) {
    console.error("Invalid hex color:", hex);
    return null;
  }

  hex = hex.replace("#", "");

  const rgb = hex.match(/.{1,2}/g).map((x) => parseInt(x, 16));

  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  l = Math.min(1, l + percent / 100);

  const hue2rgb = (p, q, t) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  let r2, g2, b2;
  if (s === 0) {
    r2 = g2 = b2 = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r2 = hue2rgb(p, q, h + 1 / 3);
    g2 = hue2rgb(p, q, h);
    b2 = hue2rgb(p, q, h - 1 / 3);
  }

  const toHex = (x) =>
    Math.round(x * 255)
      .toString(16)
      .padStart(2, "0");
  return `#${toHex(r2)}${toHex(g2)}${toHex(b2)}`;
}

export function hexToRgb(hex) {
  if (!hex || !/^#?[0-9A-Fa-f]{6}$/.test(hex)) {
    console.error("Invalid hex color:", hex);
    return null;
  }
  hex = hex.replace(/^#/, "");

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  const a = hex.length === 8 ? parseInt(hex.slice(6, 8), 16) / 255 : 1;

  return { r, g, b, a };
}

export function hexToHsv(hex) {
  if (!hex || !/^#?[0-9A-Fa-f]{6}$/.test(hex)) {
    console.error("Invalid hex color:", hex);
    return null;
  }
  const { r, g, b, a = 1 } = hexToRgb(hex);

  const rScaled = r / 255;
  const gScaled = g / 255;
  const bScaled = b / 255;

  const max = Math.max(rScaled, gScaled, bScaled);
  const min = Math.min(rScaled, gScaled, bScaled);
  const delta = max - min;

  let h,
    s,
    v = max;

  if (delta === 0) {
    h = 0;
  } else if (max === rScaled) {
    h = ((gScaled - bScaled) / delta) % 6;
  } else if (max === gScaled) {
    h = (bScaled - rScaled) / delta + 2;
  } else {
    h = (rScaled - gScaled) / delta + 4;
  }

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  s = max === 0 ? 0 : delta / max;
  s = +(s * 100).toFixed(1);
  v = +(v * 100).toFixed(1);

  return { h, s, v, a };
}

export const updateList = (state, list, item, _id = null) => {
  switch (state) {
    case "add":
      return addItem(list, item, _id);
    case "edit":
      return editItem(list, item, _id);
    case "delete":
      return deleteItem(list, _id);
    default:
      return list;
  }
};

const addItem = (list, item, _id) => {
  if (!_id) {
    return [...list, item];
  }

  return list.map((entry) => {
    if (entry._id === _id) {
      entry.children = [...entry.children, item];
    } else if (entry.children && entry.children.length > 0) {
      entry.children = addItem(entry.children, item, _id);
    }
    return entry;
  });
};

const editItem = (list, item, _id) => {
  return list.map((entry) => {
    if (entry._id === _id) {
      return { ...entry, ...item };
    } else if (entry.children && entry.children.length > 0) {
      entry.children = editItem(entry.children, item, _id);
    }
    return entry;
  });
};

const deleteItem = (list, _id) => {
  return list.filter((entry) => {
    if (entry._id === _id) {
      return false;
    }
    if (entry.children && entry.children.length > 0) {
      entry.children = deleteItem(entry.children, _id);
    }
    return true;
  });
};
