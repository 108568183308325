import React, { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { bottom, top, left, right } from "@popperjs/core";
import Checkbox from "./Checkbox";
import { classNames } from "../../helpers/classNames";
import Search from "./Search";
import Indentification from "../elements/Indentification";
import NoData from "../../assets/images/no-results-bg.2d2c6ee3.png";
import Image from "../elements/Image";

const MultiselectDropdown = ({
  xPlacement,
  dropdownData = [],
  setDropdownData = () => {},
  selectedItems = () => {},
  onSearch = () => {},
  hasLogo = false,
  isCheckbox = false,
  isSearch = false,
  label,
  labelClasses,
  dropdownButtonClass,
  buttonLabel,
  buttonLabelClasses,
  buttonIcon,
  buttonIconPosition,
  buttonArrowIcon = "fa-regular fa-chevron-down",
  buttonArrowIconClasses,
  dropdownContainer,
  buttonsinglelabelclass,
  ...props
}) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [list, setList] = useState([]);

  const selectedCount = dropdownData.filter((item) => item.checked).length;
  const allSelected = selectedCount === dropdownData.length;

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 0],
        },
      },
    ],
  });

  useEffect(() => {
    setList(dropdownData);
    selectedItems(
      dropdownData?.filter((item) => item?.checked)?.map(({ value }) => value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownData]);

  const onSearchHandler = (e) => {
    if (e?.length > 0) {
      const filter_data = dropdownData?.filter((item, i) =>
        item?.name
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(e.toLowerCase().replace(/\s+/g, ""))
      );
      if (filter_data?.length > 0) {
        setList(filter_data);
      } else {
        setList([]);
        onSearch(e);
      }
    } else {
      setList(dropdownData);
    }
  };

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            
            {console.log(open,"open")}
            <div className={classNames("relative space-y-1 w-full")}>
              {label && (
                <div
                  className={classNames(
                    "text-sm font-semibold text-slate-400 tracking-none",
                    labelClasses
                  )}
                >
                  {label}
                </div>
              )}
              <div className="relative w-full">
                <Menu.Button
                  ref={setTargetElement}
                  className={classNames(
                    "w-full flex items-center space-x-2 h-10 px-3 pr-8 border border-transparent rounded-md transition-all duration-200",
                    open ? "!border-orange-200" : "!border-transparent",
                    dropdownButtonClass
                  )}
                >
                  {buttonIconPosition === "left" && (
                    <i className={classNames("fa-fw", buttonIcon)}></i>
                  )}
                  <div
                    className={classNames(
                      "text-base font-medium block !leading-none",
                      buttonLabelClasses
                    )}
                  >
                    {buttonLabel
                      ? `${buttonLabel} ${
                          selectedCount > 0 ? ` (${selectedCount})` : ""
                        }`
                      : `${props?.buttonPlaceholder} ${
                          selectedCount > 0 ? ` (${selectedCount})` : ""
                        }` || "Select"}
                  </div>
                  {buttonIconPosition === "right" && (
                    <i className={classNames("fa-fw", buttonIcon)}></i>
                  )}
                  {buttonArrowIcon && (
                    <div
                      className={classNames(
                        "absolute top-1/2 right-1 -translate-y-1/2 transition-all duration-200",
                        buttonArrowIconClasses,
                        open ? "-rotate-180" : ""
                      )}
                    >
                      <i className={classNames("fa-fw", buttonArrowIcon)}></i>
                    </div>
                  )}
                </Menu.Button>
              </div>
            </div>
            <Transition
              as={Fragment}
              className="z-50"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items
                className={classNames(
                  "absolute mt-1",
                  dropdownContainer
                )}
              >
                <div className={classNames("w-full origin-top-right bg-white shadow-lg focus:outline-none py-1 z-10 border-0")}>
                  <div className="py-1">
                  {isSearch && (
                    <div className="py-2 px-3">
                      <Search
                        placeholder={props?.placeholder || "Search"}
                        search={(e) => onSearchHandler(e)}
                      />
                    </div>
                  )}
                  <div className="py-1 max-h-80 overflow-auto scroll-smooth scrollbar">
                    {list?.length > 0 && (
                      <button
                        type="button"
                        onClick={() => {
                          const newCheckedState = !allSelected;
                          setDropdownData(
                            dropdownData.map((item) => ({
                              ...item,
                              checked: newCheckedState,
                            }))
                          );
                        }}
                        className={classNames(
                          "w-full flex items-center py-2 px-4 gap-3 font-semibold text-sm transition-all duration-200 hover:bg-opplio-veryLightBlue hover:text-opplio-blue",
                        )}
                        aria-pressed={allSelected}
                      >
                        <Checkbox
                            checked={allSelected}
                            checkboxLabel={""}
                          checkboxClass="peer-checked:!bg-opplio-blue peer-checked:!border-opplio-blue"
                        />
                        <div className="text-sm font-medium">
                          {allSelected ? "Deselect All" : "Select All"}
                        </div>
                      </button>
                    )}
                    {list?.map((item, i) => (
                      <button
                        key={i?.toString()}
                        type={"button"}
                        onClick={() => {
                          if (typeof item?.onClick == "function") {
                            item?.onClick(item);
                          } else {
                            setDropdownData(
                              dropdownData?.map((data) =>
                                data?.value === item?.value
                                  ? {
                                      ...data,
                                      checked:
                                        data?.checked === true ? false : true,
                                    }
                                  : data
                              )
                            );
                          }
                        }}
                        className={classNames(
                          "w-full flex items-center py-3 px-4 gap-3 font-semibold text-opplio-grayB text-sm transition-all duration-200 "
                        )}
                      >
                        {isCheckbox && (
                          <Checkbox
                            checked={item?.checked}
                            checkboxLabel={""}
                            checkboxClass={
                              "peer-checked:!bg-opplio-blue peer-checked:!border-opplio-blue"
                            }
                          />
                        )}
                        {hasLogo && (
                          <Indentification
                            image={item.logo}
                            alt={item.name}
                            initial={item.initials}
                            fill={false}
                            className={
                              "!w-8 !h-8 border border-opplio-line !rounded-md"
                            }
                          />
                        )}
                        <div
                          className={`text-xs ${
                            item?.checked ? "font-medium" : "font-normal"
                          } ${buttonsinglelabelclass}`}
                        >
                          {item.name.length > 20
                            ? item.name.substring(0, 20) + "..."
                            : item.name}
                        </div>
                      </button>
                    ))}

                    {list?.length == 0 && (
                      <div className="flex flex-col items-center justify-center w-full py-3 ">
                        <div
                          className={classNames(
                            "relative w-2/3 overflow-hidden"
                          )}
                        >
                          <Image
                            src={NoData}
                            alt="no-data"
                            className="w-full h-full "
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default MultiselectDropdown;
