import React from "react";
import Button from "../form/Button";
import Modal from "../elements/Modal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LogoutModal = ({
  isOpen = false,
  setOpen = () => {},
  onClose = () => {},
  title = "Logout ?",
  loading = false,
}) => {
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const onLogout = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("adminData");
    localStorage.removeItem("role");
    setOpen(false);
    toast.success("Logout Successfull!");
    setTimeout(function () {
      navigate(role === "ADMIN" ? "/admin/signin" : "/signin");
    }, 1000);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => onClose(setOpen(false))}
      title={title}
      size="sm"
      headerClass="!py-2 !px-2"
      titleIcon={"fa-sharp fa-regular fa-circle-exclamation"}
      modaltitleClasses="!text-slate-600"
    >
      <div className="text-center my-2 text-slate-500">
        Are You Sure You Want To Logout ?
      </div>
      <div className="mt-6">
      <div className="flex gap-6 justify-center">
        <Button
          buttonIconPosition={"left"}
          buttonIcon={"fa-regular fa-close"}
          buttonLabel={"Cancel"}
          buttonFunction={() => onClose(setOpen(false))}
          buttonClasses={"bg-slate-50 border-red-500 !text-slate-500"}
        />
        <Button
          buttonFunction={onLogout}
          isDisable={loading}
          isLoaderDisabled={true}
          buttonLabel={"Yes, I'm sure"}
          buttonClasses={"bg-red-500 border-red-500 hover:bg-red-600"}
        />
      </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
