import { employeeEdit } from "../../../services/frontService/EmployeeService";
import SelectDropdownSearch from "../../form/SelectDropdownSearch";
import { formValidate } from "../../../helpers/formValidate";
import noEmpImg from "../../../assets/images/noEmpImg.svg";
import ImageUploader from "../../form/ImageUploader";
import React, { useCallback, useEffect, useState } from "react";
import useForm from "../../../hooks/useForm";
import Modal from "../../elements/Modal";
import Button from "../../form/Button";
import { toast } from "react-toastify";
import Input from "../../form/Input";
import Radio from "../../form/Radio";
import { useDebounce } from "../../../helpers/useDebounce";
import { getDepartmentList } from "../../../services/frontService/DepartmentService";
import { getPositionList } from "../../../services/frontService/PositionService";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function EmployeeEdit({
  details,
  editmodalOpen,
  setDetails = () => {},
  setEditModalOpen = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState("COMPANY-EMPLOYEE");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [posList, setPosList] = useState({ loading: false, data: [] });
  const [depList, setDepList] = useState({ loading: false, data: [] });
  const [keywords, setKeywords] = useState({
    deptKeyword: "",
    posKeyword: "",
    empKeyword: "",
  });

  const deptKeyword = useDebounce(keywords.deptKeyword, 400);
  const posKeyword = useDebounce(keywords.posKeyword, 400);

  const data = details?.data && details?.data;

  const validation = {
    firstName: { required: true, message: "Please enter  first name!" },
    lastName: { required: true, message: "Please enter  last name!" },
    email: { required: true, message: "Please enter  email !" },
    positionId: { required: true, message: "Please select a position !" },
    departmentId: { required: true, message: "Please select a department !" },
  };

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);

  useEffect(() => {
    if (data) {
      setFieldsValue({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        positionId: data?.positionDetails?._id,
        departmentId: data?.departmentDetails?.map((item) => item?._id) || [],
      });
      setImage(data?.image?.url);
      const rolePriority = [
        "COMPANY-ADMINISTRATOR",
        "COMPANY-MANAGER",
        "COMPANY-EMPLOYEE",
      ];
      const highestPriorityRole = data?.roleDetails?.reduce(
        (prev, curr) => {
          return rolePriority.indexOf(curr.code) <
            rolePriority.indexOf(prev.code)
            ? curr
            : prev;
        },
        { code: "COMPANY-EMPLOYEE" }
      );
      setUserType(highestPriorityRole?.code);
    }
  }, [data]);

  const loadList = useCallback((type, setState, keyword, payload = {}) => {
    const apiMap = {
      depList: getDepartmentList,
      posList: getPositionList,
    };
    setState((pre) => ({ ...pre, loading: true }));
    apiMap[type]({ keyword, limit: 10, ...payload }).then((res) =>
      setState({ loading: false, data: res?.status === 200 ? res.docs : [] })
    );
  }, []);

  useEffect(() => {
    if (data?.phone) {
      let phoneNumber = String(data?.phone);
      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
        setPhone(phoneNumber);
      }
    } else {
      setPhone("");
    }
  }, [data]);
  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };

  useEffect(() => {
    loadList("depList", setDepList, deptKeyword);
  }, [deptKeyword, loadList]);

  useEffect(() => {
    loadList("posList", setPosList, posKeyword);
  }, [posKeyword, loadList]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        userType,
        phone: phone || "",
        image: image ? image : "null",
      };

      const res = await employeeEdit({
        ...payload,
        id: data?._id,
      });

      setLoading(false);
      if (res?.status === 201 || res?.status === 200) {
        toast.success(res?.message);
        setEditModalOpen({ isOpen: false });
        setDetails((prev) => ({
          ...prev,
          data: res?.data,
        }));
      } else {
        toast.error(res?.data?.message || res.message);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const radioOptions = [
    { label: "Administrator", value: "COMPANY-ADMINISTRATOR" },
    { label: "Evalutee", value: "COMPANY-MANAGER" },
    { label: "Employee", value: "COMPANY-EMPLOYEE" },
  ];

  return (
    <Modal
      title={data?._id ? "Edit Employee" : "Add Employee"}
      size="xl"
      open={editmodalOpen?.isOpen}
      onClose={() => {
        setEditModalOpen({ isOpen: false });
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-2xl !text-white"}
    >
      <form
        onSubmit={(event) => handleSubmit(event, onSubmit)}
        className="grid grid-cols-2 gap-2"
      >
        <div className="col-span-1 flex flex-col space-y-3 mt-8">
          <div className="relative ">
            <ImageUploader
              label=""
              image={image}
              altImage={noEmpImg}
              isMultiple={false}
              setImage={setImage}
              isCameraIcon={true}
              buttonLabelClasses="!text-xs"
              buttonIcon="fa-upload text-xs"
              labelClasses="text-lg font-bold"
              buttonClasses="!px-0 !h-8 !cursor-pointer"
              inputClasses="w-28 h-28 !hover:bg-slate-300"
              buttonLabel={data?.image ? "Change image" : "Upload image"}
            />
          </div>

          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-envelope text-slate-400"}
              inputGroupPosition={"left"}
              label={"Email"}
              inputPlaceholder={"Enter email"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"email"}
              value={values.email}
              inputName={"email"}
              isValidate={true}
              onChange={handleChange}
              {...formValidate(errors, "email")}
            />
          </div>
        </div>

        <div className="col-span-1 flex flex-col space-y-3 mt-1">
          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-user text-slate-400"}
              inputGroupPosition={"left"}
              label={"First Name"}
              inputPlaceholder={"Enter first name"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.firstName}
              isValidate={true}
              inputName={"firstName"}
              onChange={handleChange}
              charLimit={100}
              {...formValidate(errors, "firstName")}
            />
          </div>
          <div className="relative ">
            <Input
              isInputGroup={true}
              inputGroupIcon={"fa-light fa-user text-slate-400"}
              inputGroupPosition={"left"}
              label={"Last Name"}
              charLimit={100}
              inputPlaceholder={"Enter last name"}
              inputClasses={"pl-2"}
              labelClasses={"!text-sm !font-medium"}
              inputType={"text"}
              value={values.lastName}
              isValidate={true}
              inputName={"lastName"}
              onChange={handleChange}
              {...formValidate(errors, "lastName")}
            />
          </div>
          <div className="relative mt-1">
            <div className="text-sm font-medium text-slate-500">Phone</div>
            <PhoneInput
              placeholder="(xxx) xxx-xxxx"
              value={phone}
              onChange={handlePhoneInputChange}
              // defaultCountry="US"
              international={false}
            />
          </div>
        </div>
        <div className="col-span-2 relative mt-1">
          <SelectDropdownSearch
            label={"Position"}
            placeholder={"Choose an position"}
            isSearch={true}
            xPlacement={"bottom"}
            buttonArrowIcon={"fa-solid fa-chevron-down"}
            tabTextColor={"text-slate-700"}
            selectName={"positionId"}
            loading={posList?.loading}
            value={values?.positionId}
            onChange={handleChange}
            isValidate={true}
            dropdownButtonClass="!px-3 !border-gray-200 !w-full"
            {...formValidate(errors, "positionId")}
            dropdownData={posList?.data?.map(({ _id, title }) => ({
              name: title,
              _id: _id,
            }))}
            onSearch={(val) => {
              setKeywords((pre) => ({ ...pre, posKeyword: val }));
            }}
          />
        </div>
        <div className="col-span-2 relative mt-1">
          <SelectDropdownSearch
            placeholder={"Choose an department"}
            selectName={"departmentId"}
            label={"Department"}
            isMultiple={true}
            isSearch={true}
            isValidate={true}
            loading={depList?.loading}
            value={values?.departmentId}
            onChange={handleChange}
            {...formValidate(errors, "departmentId")}
            dropdownData={depList?.data?.map(({ _id, name }) => ({
              name: name,
              _id: _id,
            }))}
            onSearch={(val) => {
              setKeywords((pre) => ({ ...pre, deptKeyword: val }));
            }}
          />
        </div>
        <div className={`col-span-2 flex items-center justify-between mt-4`}>
          <div className="flex gap-2">
            {radioOptions.map((option) => (
              <Radio
                key={option?.value}
                radioLabel={option?.label}
                radioValue={option?.value}
                onChange={(e) => setUserType(e.target.value)}
                isChecked={userType === option?.value}
                radioName="userRole"
              />
            ))}
          </div>
          <Button
            loading={loading}
            buttonLabel={
              data?._id
                ? loading
                  ? "Saving..."
                  : "Save"
                : loading
                ? "Adding..."
                : "Add"
            }
            isDisable={loading ? true : false}
            buttonIcon={
              data?._id ? "fa-light fa-floppy-disk" : "fa-regular fa-plus"
            }
            buttonIconPosition={"left"}
            buttonType="submit"
          />
        </div>
      </form>
    </Modal>
  );
}
