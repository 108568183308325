import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../helpers/classNames";

const Button = ({
  buttonEffect = "",
  buttonClasses,
  buttonType = "button",
  buttonIcon,
  buttonIconPosition,
  buttonLabel,
  buttonLabelClasses,
  buttonFunction = () => {},
  buttonHasLink = false,
  buttonLink,
  isDisable = false,
  loading = false,
  btnLoading = false,
  btnLoaderClasses,
  ...props
}) => {
  return (
    <>
      {buttonHasLink ? (
        <Link
          to={buttonLink}
          className={classNames(
            "flex items-center space-x-2 text-sm sm:text-base text-white h-10 px-3 transition-all duration-200 ease-in-out relative cursor-pointer rounded-md text-center overflow-hidden z-[1] border border-orange-500 bg-orange-500 shadown-none",
            buttonClasses,
            isDisable ? "pointer-events-none opacity-75" : ""
          )}
        >
          {buttonIconPosition === "left" && (
            <i className={classNames("fa-fw", buttonIcon)}></i>
          )}
          {buttonLabel && (
            <span
              className={classNames(
                "text-sm font-medium block !leading-none",
                buttonLabelClasses
              )}
            >
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i className={classNames("fa-fw", buttonIcon)}></i>
          )}
        </Link>
      ) : (
        <button
          type={buttonType}
          className={classNames(
            "flex items-center space-x-1 text-sm sm:text-base text-white h-10 px-3 transition-all duration-200 ease-in-out relative cursor-pointer rounded-md text-center overflow-hidden z-[1] border border-orange-500 bg-orange-500 shadown-none",
            buttonClasses,
            isDisable ? "pointer-events-none opacity-75" : ""
          )}
          onClick={buttonFunction}
          disabled={isDisable}
        >
          {buttonIconPosition === "left" && (
            <i
              className={classNames(
                "fa-fw",
                !loading
                  ? buttonIcon
                  : "fa-duotone fa-spinner-third animate-spin"
              )}
            ></i>
          )}
          {buttonLabel && !btnLoading && (
            <span
              className={classNames(
                "text-sm font-medium block !leading-none",
                buttonLabelClasses
              )}
            >
              {buttonLabel}
            </span>
          )}
          {buttonIconPosition === "right" && (
            <i
              className={classNames(
                "fa-fw",
                !loading
                  ? buttonIcon
                  : "fa-duotone fa-spinner-third animate-spin"
              )}
            ></i>
          )}
          {btnLoading && (
            <span className={`dot-loader text-sm ${btnLoaderClasses}`}></span>
          )}
        </button>
      )}
    </>
  );
};

export default Button;
