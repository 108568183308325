import SelectDropdownSearch from "../../form/SelectDropdownSearch";
import React, { useCallback, useEffect, useState } from "react";
import noEmpImg from "../../../assets/images/noEmpImg.svg";
import { useDebounce } from "../../../helpers/useDebounce";
import useForm from "../../../hooks/useForm";
import Button from "../../form/Button";
import Input from "../../form/Input";
import { toast } from "react-toastify";
import {
  getEmployeeList,
  inviteAgain,
} from "../../../services/frontService/EmployeeService";
import Radio from "../../form/Radio";
import Checkbox from "../../form/Checkbox";

export default function InviteModal({ setInviteModalOpen = () => {} }) {
  const [empList, setEmpList] = useState({ loading: false, data: [] });
  const [userType, setUserType] = useState("COMPANY-EMPLOYEE");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [reinvite, setReinvite] = useState(false);
  const [keywords, setKeywords] = useState({
    empKeyword: "",
  });

  const empKeyword = useDebounce(keywords.empKeyword, 400);

  const validation = {};

  const { values, handleSubmit, handleChange, setFieldsValue } = useForm(
    {},
    validation
  );
  useEffect(() => {
    const userData = user
      ? empList?.data?.find((item) => item?._id === user)
      : {};
    setFieldsValue({
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      email: userData?.email || "",
    });
    const rolePriority = [
      "COMPANY-ADMINISTRATOR",
      "COMPANY-MANAGER",
      "COMPANY-EMPLOYEE",
    ];
    const highestPriorityRole = userData?.roleDetails?.reduce(
      (prev, curr) => {
        return rolePriority.indexOf(curr.code) < rolePriority.indexOf(prev.code)
          ? curr
          : prev;
      },
      { code: "COMPANY-EMPLOYEE" }
    );
    setUserType(highestPriorityRole?.code || "COMPANY-EMPLOYEE");
  }, [user, empList?.data]);

  const loadempList = useCallback(() => {
    setEmpList({ loading: true, data: [] });
    getEmployeeList({ keyword: empKeyword, sortQuery: "-createdAt" }).then(
      (res) => {
        setEmpList({
          loading: false,
          data: res?.status === 200 ? res?.docs : [],
        });
      }
    );
  }, [empKeyword]);

  useEffect(() => loadempList(), [loadempList]);

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      let payload = { userType };

      payload = user
        ? reinvite
          ? { ...payload, userId: user }
          : { ...values, ...payload, userId: user, userEdit: "true" }
        : { ...values, ...payload };

      const res = await inviteAgain(payload);

      if (res?.status === 200) {
        toast.success("Invite sent successfully");
        setInviteModalOpen(false);
      } else {
        toast.error(res?.data?.message || "An unexpected error occurred.");
      }
    } catch (error) {
      toast.error(
        error?.data?.message || "A network error occurred. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const radioOptions = [
    { label: "Administrator", value: "COMPANY-ADMINISTRATOR" },
    { label: "Evaluator", value: "COMPANY-MANAGER" },
    { label: "Employee", value: "COMPANY-EMPLOYEE" },
  ];

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="relative">
          <div className="grid grid-cols-12 gap-4">
            <div className="relative col-span-12">
              <SelectDropdownSearch
                label={"Employee"}
                placeholder={"Choose an Employee"}
                isSearch={true}
                value={user}
                xPlacement={"bottom"}
                buttonArrowIcon={"fa-solid fa-chevron-down"}
                tabTextColor={"text-slate-700"}
                selectName={"userId"}
                loading={empList?.loading}
                onChange={(e) => setUser(e.target.value)}
                dropdownButtonClass="!px-3 !border-gray-200 !w-full"
                dropdownData={empList?.data?.map((item) => ({
                  name: item?.firstName + " " + item?.lastName,
                  _id: item?._id,
                  url: item?.image?.url ? item?.image?.url : noEmpImg,
                  subItem: item?.positionDetails?.title,
                  icon: item?.roleDetails?.some(
                    (role) => role?.code === "COMPANY-MANAGER"
                  )
                    ? "fa-regular fa-user-crown text-orange-500"
                    : "",
                }))}
                onSearch={(val) => {
                  setKeywords((pre) => ({ ...pre, empKeyword: val }));
                }}
              />
            </div>
            {user && (
              <div className="relative col-span-12 ml-1">
                <Checkbox
                  onChange={(e) => {
                    setReinvite(e.target.checked);
                  }}
                  checkboxLabel={"Reinvite"}
                />
              </div>
            )}
            <div className="col-span-12 border-t mt-1"></div>
            <div className="relative col-span-6">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-user text-slate-400"}
                inputGroupPosition={"left"}
                label={"First Name"}
                inputPlaceholder={"Enter first name"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"text"}
                value={values.firstName}
                inputName={"firstName"}
                onChange={handleChange}
                isDisabled={reinvite}
              />
            </div>
            <div className="relative col-span-6">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-user text-slate-400"}
                inputGroupPosition={"left"}
                label={"Last Name"}
                inputPlaceholder={"Enter last name"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"text"}
                value={values.lastName}
                inputName={"lastName"}
                onChange={handleChange}
                isDisabled={reinvite}
              />
            </div>
            <div className="relative col-span-12">
              <Input
                isInputGroup={true}
                inputGroupIcon={"fa-light fa-envelope text-slate-400"}
                inputGroupPosition={"left"}
                label={"Email"}
                inputPlaceholder={"Enter email"}
                inputClasses={"pl-2"}
                labelClasses={"!text-sm !font-medium"}
                inputType={"email"}
                value={values.email}
                inputName={"email"}
                onChange={handleChange}
                isDisabled={reinvite}
              />
            </div>
          </div>
          <div className="col-span-12 border-t my-6"></div>
          <div className="flex items-center justify-between space-x-3 mt-4">
            <div className="flex gap-2">
              {radioOptions.map((option) => (
                <Radio
                  key={option?.value}
                  radioLabel={option?.label}
                  radioValue={option?.value}
                  onChange={(e) => setUserType(e.target.value)}
                  isChecked={userType === option?.value}
                  radioName="userRole"
                />
              ))}
            </div>
            <Button
              buttonHasLink={false}
              buttonType={"submit"}
              buttonLabel={loading ? "Inviting..." : "Invite"}
              isDisable={loading}
              buttonLabelClasses={"uppercase"}
              buttonClasses={
                "!text-white !bg-orange-500  focus-visible:outline justify-center rounded-md border border-orange-500"
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
}
