export default function EmployeeDetailsLoader() {
  return (
    <div className="col-span-3 w-full mx-auto bg-white shadow-md rounded-xl p-10 border border-slate-200 space-y-8 shimmer-effect">
      <header className="w-full flex items-center space-x-8 border-b border-slate-200 pb-8">
        <div className="w-36 h-36 rounded-full bg-slate-200 flex-shrink-0"></div>
        <div className="space-y-3 w-full">
          <div className="h-6 bg-slate-200 rounded w-3/4"></div>
          <div className="h-4 bg-slate-200 rounded w-1/2"></div>
          <div className="h-4 bg-slate-200 rounded w-1/3"></div>
        </div>
      </header>

      <section className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <div className="flex items-center bg-slate-20 p-5 shadow-sm rounded-lg border border-slate-200 space-x-4">
          <div className="h-6 w-6 bg-slate-200 rounded-full"></div>
          <div className="space-y-2 w-full">
            <div className="h-4 bg-slate-200 rounded w-1/3"></div>
            <div className="h-4 bg-slate-200 rounded w-2/3"></div>
          </div>
        </div>
        <div className="flex items-center bg-slate-20 p-5 shadow-sm rounded-lg border border-slate-200 space-x-4">
          <div className="h-6 w-6 bg-slate-200 rounded-full"></div>
          <div className="space-y-2 w-full">
            <div className="h-4 bg-slate-200 rounded w-1/3"></div>
            <div className="h-4 bg-slate-200 rounded w-2/3"></div>
          </div>
        </div>
      </section>

      <section className="bg-slate-20 p-6 shadow-sm rounded-lg border border-slate-200 space-y-3">
        <div className="h-6 bg-slate-200 rounded w-1/4"></div>
        <div className="h-4 bg-slate-200 rounded w-full"></div>
        <div className="h-4 bg-slate-200 rounded w-3/4"></div>
        <div className="h-4 bg-slate-200 rounded w-2/3"></div>
      </section>

      <section className="bg-slate-20 p-6 shadow rounded-lg border border-slate-200 space-y-3">
        <div className="h-6 bg-slate-200 rounded w-1/4"></div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {[...Array(2)].map((_, index) => (
            <div
              key={index}
              className="w-full flex items-center space-x-4 p-4 bg-white rounded-lg border border-slate-200 shadow-sm"
            >
              <div className="w-14 h-14 rounded-full bg-slate-200 flex-shrink-0"></div>
              <div className="space-y-2 w-full">
                <div className="h-4 bg-slate-200 rounded w-1/2"></div>
                <div className="h-4 bg-slate-200 rounded w-1/3"></div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="bg-slate-20 p-6 shadow rounded-lg border border-slate-200 space-y-3">
        <div className="h-6 bg-slate-200 rounded w-1/4"></div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          {[...Array(2)].map((_, index) => (
            <div
              key={index}
              className="w-full flex items-center space-x-4 p-4 bg-white rounded-lg border border-slate-200 shadow-sm"
            >
              <div className="w-14 h-14 rounded-full bg-slate-200 flex-shrink-0"></div>
              <div className="space-y-2 w-full">
                <div className="h-4 bg-slate-200 rounded w-1/2"></div>
                <div className="h-4 bg-slate-200 rounded w-1/3"></div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
