import { employeeModal } from "../../../redux/slice/employeeSlice";
import { useDispatch } from "react-redux";
import Modal from "../../elements/Modal";
import Button from "../../form/Button";

export default function EmpAddType({
  empAddType,
  setEmpAddtype = () => {},
  setUploadModal = () => {},
}) {
  const dispatch = useDispatch();

  return (
    <Modal
      title={"Employees"}
      size="lg"
      open={empAddType?.isOpen}
      onClose={() => {
        setEmpAddtype((pre) => ({ ...pre, isOpen: false }));
      }}
      modaltitleClasses={"!text-slate-200 text-lg"}
      headerClass={"!bg-black !rounded-t-2xl !text-white"}
    >
      <div className="w-full p-3 flex  justify-center items-center gap-4">
        <Button
          buttonLabel={"Bulk CSV Upload"}
          buttonIcon={"fa-regular fa-file-excel"}
          buttonIconPosition={"left"}
          buttonClasses={"!z-0 !rounded-full"}
          buttonFunction={() => {
            setEmpAddtype({ data: {}, isOpen: false });
            setUploadModal({ isOpen: true });
          }}
        />
        <p className="font-semibold">or</p>
        <Button
          buttonLabel={"Add Individual Employee"}
          buttonIcon={"fa-regular fa-user"}
          buttonIconPosition={"left"}
          buttonClasses={"!z-0 !rounded-full"}
          buttonFunction={() => {
            setEmpAddtype({ data: {}, isOpen: false });
            dispatch(employeeModal({ isOpen: true }));
          }}
        />
      </div>
    </Modal>
  );
}
