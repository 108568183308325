import NoImage from "../../assets/images/no-image.png";
import { classNames } from "../../helpers/classNames";
import React, { useRef } from "react";
import Image from "../elements/Image";
import Button from "./Button";

export default function ImageUploadSingle({
  label,
  labelClasses,
  image,
  setImage,
  icon,
  iconClasses,
  title,
  titleClasses,
  noteClasses,
  note,
  removeFile = () => {},
  accepts = "image/jpeg, image/png, image/gif",
}) {
  const inputRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (
      file &&
      ["jpg", "jpeg", "png", "gif"].includes(file.type.split("/")[1])
    ) {
      setImage(file);
    } else {
      alert("Please upload a valid image file.");
    }
  };

  return (
    <>
      <div className="relative">
        <div className="w-full relative space-y-1">
          {label && (
            <div
              className={classNames(
                "text-sm font-semibold text-slate-500 tracking-none",
                labelClasses
              )}
            >
              {label}
            </div>
          )}
          {image ? (
            <div className="relative w-48 aspect-square rounded-md border border-slate-100 flex group">
              <div className="opacity-0 invisible transition-all duration-200 group-hover:opacity-100 group-hover:visible">
                <Button
                  buttonIcon={"fa-light fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonClasses={
                    "!absolute !w-5 !h-5 !p-0 !justify-center -top-2 -right-1 !text-xs !bg-red-500 !border-none !text-white !rounded-full"
                  }
                  buttonFunction={() => {
                    setImage(null);
                    removeFile(image);
                  }}
                />
              </div>
              <div className="w-full h-full overflow-hidden rounded-md">
                <Image
                  alt=""
                  width={200}
                  height={200}
                  className="h-full w-full object-cover"
                  src={
                    image instanceof File
                      ? URL.createObjectURL(image)
                      : image || NoImage
                  }
                />
              </div>
            </div>
          ) : (
            <div className="h-48 w-full relative bg-white border-2 items-center rounded-lg cursor-pointer border-slate-200 border-dashed">
              <input
                ref={inputRef}
                type="file"
                accept={accepts}
                onChange={handleFileChange}
                className="h-full w-full opacity-0 z-10 absolute cursor-pointer"
              />
              <div className="h-full w-full absolute z-1 flex justify-center items-center top-0 p-4 text-center">
                <div className="flex flex-col space-y-1">
                  {icon && (
                    <div
                      className={classNames(
                        "text-slate-300 text-4xl text-center",
                        iconClasses
                      )}
                    >
                      <i className={classNames("fa-fw", icon)}></i>
                    </div>
                  )}
                  <div
                    className={classNames(
                      "text-base font-bold text-slate-500",
                      titleClasses
                    )}
                  >
                    {title}
                  </div>
                  <div
                    className={classNames(
                      "text-xs text-slate-400 text-center",
                      noteClasses
                    )}
                  >
                    {note}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
