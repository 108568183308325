import { apiRequest } from "../util/apiUtils";

export const CompanySignIn = async (
  params,
  setLoaded = () => {},
  setResData = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "company-login", {
      body: {
        email: params?.email,
        password: params?.password,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};

export const superAdminDirectLogin = async (
  params,
  setLoaded = () => {},
  setResData = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "login-for-admin", {
      body: {
        ...params,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};

export const viewRelation = async (
  params,
  setLoaded = () => {},
  setResData = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("post", "login-for-admin", {
      body: {
        ...params,
      },
    });
    const data = res.data;
    setResData(data);
    setLoaded(true);
    return res;
  } catch (error) {
    setResData(error);
    setLoaded(true);
    return error;
  }
};
