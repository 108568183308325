import { apiRequest } from "../util/apiUtils";

export const siteSettingDetails = async (params) => {
  try {
    const res = await apiRequest(
      "get", `site-setting`
    );

    return res?.data;
  } catch (err) {
    throw err;
  }
};
export const updateSetting = async (params) => {
  try {
    const res = await apiRequest(
      "put",
      `site-setting/${params?.id}`,
      {
        body: {
          ...params,
        },
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (err) {
    return err;
  }
};
