export default function EmployeeFilterLoader() {
  return (
    <div className="w-full space-y-6 relative flex-shrink-0">
      <div className="bg-white rounded-lg shadow-md py-4 px-6 border border-gray-200">
        <div className="font-bold text-lg mb-4 border-b pb-2 text-gray-700">
          Departments
        </div>
        <div className="flex flex-col space-y-3">
          {[...Array(9)].map((_, index) => (
            <div
              key={index}
              className="relative flex justify-between items-center py-2 px-2 rounded-md"
            >
              <div className="h-3 bg-slate-200 rounded w-2/3 shimmer-effect"></div>
              <div className="h-3 bg-slate-200 rounded w-1/6 shimmer-effect"></div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white rounded-lg shadow-md py-4 px-6 border border-gray-200">
        <div className="font-bold text-lg mb-4 border-b pb-2 text-gray-700">
          Positions
        </div>
        <div className="flex flex-col space-y-3">
          {[...Array(9)].map((_, index) => (
            <div
              key={index}
              className="relative flex justify-between items-center py-2 px-2 rounded"
            >
              <div className="h-3 bg-slate-200 rounded w-2/3 shimmer-effect"></div>
              <div className="h-3 bg-slate-200 rounded w-1/6 shimmer-effect"></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
