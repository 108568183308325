export default function EmployeeLoader({ count }) {
  return (
    <>
      {Array.from({ length: count }).map((_, i) => (
        <div
          key={i}
          className="col-span-1 relative flex flex-col items-center rounded-lg overflow-hidden transition-all duration-300 bg-white p-2 border shadow-md hover:shadow-lg shimmer-effect"
        >
          <div className="w-full aspect-square bg-slate-200 rounded-lg overflow-hidden "></div>

          <div className="flex flex-col items-center space-y-2 py-3 px-4 w-full">
            <div className="h-3 w-3/4 bg-slate-200 rounded-md "></div>
            <div className="h-2 w-1/2 bg-slate-200 rounded-md "></div>
            <div className="h-2 w-1/2 bg-slate-200 rounded-md "></div>
          </div>

          <div className="flex space-x-1 ">
            <div className="w-7 h-7 bg-slate-200 rounded-md flex items-center justify-center "></div>
            <div className="w-7 h-7 bg-slate-200 rounded-md flex items-center justify-center "></div>
          </div>
        </div>
      ))}
    </>
  );
}
