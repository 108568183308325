import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  sheduleData: [],
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      if (typeof action.payload === "function") {
        state.data = action.payload(state.data);
      } else {
        state.data = action.payload;
      }
    },
  },
});

export const { setProfile } = profileSlice?.actions;
export default profileSlice?.reducer;
