import React from "react";
import { classNames } from "../../helpers/classNames";

const Switch = ({ checked, functions = () => {}, className = "" }) => {
  return (
    <>
      <div className="relative overflow-hidden flex items-center">
        <input
          type="checkbox"
          className="peer absolute top-0 left-0 z-10 opacity-0 cursor-pointer w-full h-full"
          defaultChecked={checked}
          onChange={functions}
        />
        <div
          className={classNames(
            "w-12 h-6 rounded-3xl border border-slate-500 bg-opplio-silver transition-all duration-200",
            "before:absolute before:content-[''] before:top-1/2 before:left-0.5 before:-translate-y-1/2 before:bg-slate-400 before:w-5 before:h-5 before:rounded-full before:transition-all duration-200 before:shadow",
            "peer-checked:before:left-[26px] peer-checked:bg-orange-400 peer-checked:border-orange-400 peer-checked:before:bg-white",
            className
          )}
        ></div>
      </div>
    </>
  );
};

export default Switch;
