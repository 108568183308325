import { apiRequest } from "../../util/apiUtils";


export const adminDetails = async (params) => {
  try {
    const res = await apiRequest("get", `/user/admin-details/${params?.id}`);

    return res?.data;
  } catch (err) {
    throw err;
  }
};
export const addEditAdmin = async (params) => {
  try {
    const res = await apiRequest(
      params._id ? "put" : "post",
      params._id ? `/user/admin-edit/${params?._id}` : "/admin-signup",
      {
        body: params,
      },
      "multipart/form-data"
    );

    return res?.data;
  } catch (error) {
    return error;
  }
};
export const deleteAdmin = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", `/user/admin-delete`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
export const changeStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", `user/change-status`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};