import React, { useEffect, useState } from "react";
import noImage from "../../../assets/images/no-results-bg.2d2c6ee3.png";
import Modal from "../../../components/elements/Modal";
import ImageUploader from "../../form/ImageUploader";
import Button from "../../form/Button";
import Input from "../../form/Input";
import useForm from "../../../hooks/useForm";
import { formValidate } from "../../../helpers/formValidate";
import { toast } from "react-toastify";
import { companyUpdate } from "../../../services/frontService/CompanyService";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setProfile } from "../../../redux/slice/profileSlice,";

const CompanyManage = ({
  companyDetailsModal,
  setCompanyDetailsModal = () => {},
  setDetails = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const validation = {
    companyName: { required: true, message: "Please enter company name!" },
  };
  const profile = useSelector((state) => state?.profile?.data);

  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const [image, setImage] = useState(null);

  const data = companyDetailsModal?.data;

  useEffect(() => {
    if (data) {
      setFieldsValue({
        companyName: data?.companyCredential?.name || "",
        email: data?.companyCredential?.email || "",
      });
      if (data?.image?.url) {
        setImage(data?.image?.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = (values) => {
    setLoading(true);
    companyUpdate({
      companyName: values?.companyName,
      image: image || "null",
    }).then((res) => {
      if (res?.status === 200) {
        setDetails(res?.data);
        toast.success(res?.message);
        setCompanyDetailsModal({ isOpen: false });
        setLoading(false);
        dispatch(
          setProfile({
            ...profile,
            companyDetails: {
              ...profile?.companyDetails,
              companyName: res?.data?.companyCredential?.name,
            },
          })
        );
      } else {
        toast.error(res?.data?.message);
        setCompanyDetailsModal({ isOpen: false });
        setLoading(false);
      }
    });
  };
  return (
    <div>
      <Modal
        title="Edit Company Details"
        size="lg"
        open={companyDetailsModal.isOpen}
        onClose={() => setCompanyDetailsModal({ isOpen: false })}
        modaltitleClasses={"!text-slate-200 text-lg"}
        headerClass={"!bg-black !rounded-t-2xl !text-white"}
      >
        <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
          <div className="flex flex-wrap items-start justify-between space-y-4 w-full">
            <div className="flex flex-col items-center justify-center w-1/3 mt-8">
              <ImageUploader
                label=""
                image={image}
                altImage={noImage}
                isMultiple={false}
                setImage={setImage}
                isCameraIcon={true}
                buttonLabelClasses="!text-xs"
                buttonIcon="fa-upload text-xs"
                labelClasses="text-lg font-bold"
                buttonClasses="!px-0 !h-8 !cursor-pointer"
                inputClasses="w-28 h-28 !hover:bg-slate-300"
              />
            </div>

            <div className="flex flex-col w-2/3 space-y-4">
              <div className="relative">
                <Input
                  isInputGroup={true}
                  inputGroupIcon={"fa-light fa-users text-slate-400"}
                  inputGroupPosition={"left"}
                  label={"Company Name"}
                  inputPlaceholder={"Enter company name"}
                  inputClasses={"pl-2"}
                  labelClasses={"!text-sm !font-medium"}
                  inputType={"text"}
                  isValidate={true}
                  value={values.companyName}
                  onChange={handleChange}
                  inputName={"companyName"}
                  charLimit={50}
                  {...formValidate(errors, "companyName")}
                />
              </div>
              <div className="relative">
                <Input
                  isInputGroup={true}
                  inputGroupIcon={"fa-light fa-envelope text-slate-400"}
                  inputGroupPosition={"left"}
                  label={"Company Email"}
                  inputPlaceholder={"Enter company email"}
                  inputClasses={"pl-2"}
                  labelClasses={"!text-sm !font-medium"}
                  inputType={"email"}
                  isDisabled={true}
                  isValidate={true}
                  value={values.email}
                  onChange={handleChange}
                  inputName={"email"}
                />
              </div>
            </div>
          </div>

          <div className="flex items-center justify-end mt-4 gap-4">
            <Button
              buttonLabel={"Cancel"}
              buttonIcon={"fa-regular fa-xmark"}
              buttonIconPosition={"left"}
              buttonClasses={
                "!bg-transparent !border-orange-500 !text-orange-500"
              }
              buttonFunction={() => setCompanyDetailsModal({ isOpen: false })}
            />
            <Button
              loading={loading}
              buttonLabel={loading ? "Saving..." : "Save"}
              buttonIcon={"fa-light fa-floppy-disk"}
              buttonIconPosition={"left"}
              buttonType="submit"
              isDisable={loading ? true : false}
            />
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default CompanyManage;
