import dayjs from "dayjs";

export default function LatestNews({ announcementList }) {
  return (
    <div className="p-2">
      <p className="text-xl font-semibold text-slate-600 mb-4">Latest News</p>
      {announcementList?.loading ? (
        <div className="grid gap-4">
          {Array.from({ length: 1 }).map((_, index) => (
            <div
              key={index}
              className="border border-slate-300 rounded-lg p-4 bg-white shadow-sm shimmer-effect"
            >
              <div className="flex items-start gap-4">
                <div className="w-12 h-12 bg-slate-200 rounded-full flex-shrink-0"></div>
                <div className="flex-1 flex flex-col space-y-3">
                  <div className="h-4 bg-slate-200 rounded w-3/4"></div>
                  <div className="h-3 bg-slate-200 rounded w-full"></div>
                  <div className="h-3 bg-slate-200 rounded w-1/2 mt-2"></div>
                  <div className="h-3 bg-slate-200 rounded w-1/3 mt-2"></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : announcementList?.data?.length > 0 ? (
        <div className="grid gap-4">
          {announcementList.data.map((announcement) => (
            <div
              key={announcement._id}
              className="border border-slate-300 rounded-lg p-4 bg-white shadow-sm"
            >
              <div className="flex items-start gap-4">
                <div className="w-12 h-12 flex items-center justify-center rounded-full bg-purple-100 border border-purple-200 flex-shrink-0">
                  <i className="fa-regular fa-bullhorn text-purple-600 text-xl" />
                </div>
                <div className="flex flex-col space-y-2">
                  <p className="text-lg lg:text-xl font-semibold text-slate-700 capitalize">
                    {announcement.title}
                  </p>
                  <p className="text text-slate-500">
                    {announcement.description}
                  </p>
                  <p className="text-xs text-slate-400 mt-1 border-t py-2">
                    <i className="fa-regular fa-calendar mr-1" />
                    {dayjs(announcement?.startDate).format("MMM D")}
                    {announcement?.startDate !== announcement?.endDate &&
                      ` to ${dayjs(announcement?.endDate).format("MMM D")}`}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="border border-slate-300 rounded-lg p-6 bg-white shadow-sm">
          <div className="border-2 border-dashed rounded-lg p-6 text-center flex flex-col items-center justify-center gap-4">
            <div className="w-20 h-20 flex items-center justify-center rounded-full bg-orange-50">
              <i className="fa-light fa-calendar-times text-3xl text-gray-500" />
            </div>
            <p className="text-center text-sm font-medium text-slate-700">
              No announcements at the moment. Stay tuned for future events!
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
