import { apiRequest } from "../../util/apiUtils";

export const getCompanyList = async (params) => {
  try {
    const res = await apiRequest("get", `/user/company-administrators-list`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const companyAdd = async (payload) => {
  try {
    const res = await apiRequest(
      "post",
      `/user/company-administrator-add`,
      {
        body: payload,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const companyEdit = async (payload) => {
  try {
    const res = await apiRequest(
      "put",
      `/user/company-administrators-edit`,
      {
        body: payload,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const companyDetails = async (params) => {
  try {
    const res = await apiRequest(
      "get",
      `/user/company-administrators-details/${params?.id}`
    );

    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const changeStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", `user/change-status`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const deleteCompany = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", `/user/delete-all`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const superAdminDirectLogin = async (params,adminToken) => {
  try {
    const res = await apiRequest("post", `/panel-login`, {
      body: {
        ...params,
      },
      headers: {
        Authorization: `Bearer ${adminToken}`, 
      },
    });
    return res?.data;
  } catch (err) {
    return err;
  }
};
export const getCompanyDepartmentList = async (params) => {
  try {
    const res = await apiRequest("get", `/department`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const getPositionList = async (params) => {
  try {
    const res = await apiRequest("get", `/position`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const administratorInviteAgain = async (payload) => {
  try {
    const res = await apiRequest(
      "post",
      `/user/invite-again`,
      {
        body: payload,
      },
      
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};