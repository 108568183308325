import React from "react";
import { Link } from "react-router-dom";
import SidebarMenuBlocks from "../../components/adminNavigation/SidebarMenuBlocks";
import Button from "../../components/form/Button";
import { useSelector } from "react-redux";

const AdminSideBar = ({ isMenuOpen, setMenuOpen = () => {} }) => {
  const settings = useSelector((state) => state?.settings?.data);
  const adminSidebarMenu = [
    {
      _id: "1",
      menuTitle: "Statistics & Analytics",
      menuData: [
        {
          _id: "1",
          menuLabel: "Dashboard",
          menuLink: "/admin/dashboard",
          menuIcon: "fa-solid fa-grid-2",
          menuIconColor: "text-rose-500",
        },
      ],
    },
    {
      _id: "2",
      menuTitle: "User Management",
      menuData: [
        {
          _id: "1",
          menuLabel: "Admins",
          menuLink: "/admins",
          menuIcon: "fa-solid fa-user-crown",
          menuIconColor: "text-amber-500",
        },
        {
          _id: "2",
          menuLabel: "Companies",
          menuLink: "/admin/companies",
          menuIcon: "fa-solid fa-user",
          menuIconColor: "text-sky-500",
        },
        {
          _id: "3",
          menuLabel: "Employees",
          menuLink: "/admin/employees",
          menuIcon: "fa-solid fa-users-medical",
          menuIconColor: "text-fuchsia-500",
        },
      ],
    },
   

    {
      _id: "6",
      menuTitle: "Content Management",
      menuData: [
        {
          _id: "1",
          menuLabel: "Email Templates",
          menuLink: "/admin/email-template",
          menuIcon: "fa-solid fa-envelope",
          menuIconColor: "text-rose-500",
        },
        {
          _id: "2",
          menuLabel: "Site Settings",
          menuLink: "/admin/site-settings",
          menuIcon: "fa-solid fa-gear",
          menuIconColor: "text-amber-500",
        },
      ],
    },
  ];
  return (
    <>
      <div className="relative w-full bg-black  pt-6 ">
        <div className="relative flex gap-2 ">
          <Button
            buttonType={"button"}
            buttonIcon={
              isMenuOpen
                ? "fa-regular fa-chevron-right"
                : "fa-regular fa-chevron-left"
            }
            buttonIconPosition={"left"}
            buttonClasses={
              "!absolute !rounded-full right-[-14px] top-[-10px] !z-[60] px-0 w-10 !bg-orange-500 !text-black-600 hover:!bg-orange-600  !h-[30px] !w-[30px] !flex !items-center !justify-center !border-none"
            }
            buttonFunction={() => setMenuOpen(!isMenuOpen)}
          />
        </div>
        <div
          className={
            `relative mt-15 flex items-center gap-4 transition-all duration-100 z-60 border-slate-700` +
            (isMenuOpen ? "px-3" : "px-3")
          }
        >
          <Link className="h-6 mx-auto" to={"/"} replace>
            <img
              className="w-full h-full object-contain"
              src={!isMenuOpen ? settings?.logo?.url : settings?.favicon?.url}
              alt={"Logo"}
            />
          </Link>
        </div>
        <div
          className={
            "relative overflow-auto scroll-smooth scrollbar scrollbar-1 h-[calc(100%-4rem)] transition-all duration-100 divide-y " +
            (isMenuOpen ? " divide-slate-800" : "divide-transparent")
          }
        >
          {Array.isArray(adminSidebarMenu) &&
            adminSidebarMenu?.map((item, index) => {
              if (item) {
                return (
                  <SidebarMenuBlocks
                    key={item._id}
                    menuTitle={item.menuTitle}
                    menuData={item.menuData}
                    isMenuOpen={isMenuOpen}
                  />
                );
              }
              return null;
            })}
        </div>
      </div>
    </>
  );
};

export default AdminSideBar;
