import React from "react";

const DashboardSkeleton = () => {
  return (
    <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
      <div className="flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">Dashboard</div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
       
        <div className="relative flex items-center space-x-4 p-4 border border-blue-500 bg-blue-50 rounded-lg shadow min-h-[100px]">
          <div className="w-12 h-12 bg-blue-300 rounded-full animate-pulse"></div>
          <div className="absolute top-2 right-4 w-28 h-5 bg-blue-300 animate-pulse"></div>
          <div className="absolute bottom-2 right-4 w-12 h-8 bg-blue-300 animate-pulse"></div>
        </div>

      
        <div className="relative flex items-center space-x-4 p-4 border border-pink-500 bg-pink-50 rounded-lg shadow min-h-[100px]">
          <div className="w-12 h-12 bg-pink-300 rounded-full animate-pulse"></div>
          <div className="absolute top-2 right-4 w-28 h-5 bg-pink-300 animate-pulse"></div>
          <div className="absolute bottom-2 right-4 w-12 h-8 bg-pink-300 animate-pulse"></div>
        </div>

        <div className="relative flex items-center space-x-4 p-4 border border-orange-500 bg-orange-50 rounded-lg shadow min-h-[100px]">
          <div className="w-12 h-12 bg-orange-300 rounded-full animate-pulse"></div>
          <div className="absolute top-2 right-4 w-28 h-5 bg-orange-300 animate-pulse"></div>
          <div className="absolute bottom-2 right-4 w-12 h-8 bg-orange-300 animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default DashboardSkeleton;
