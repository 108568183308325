import React, { useEffect, useState } from "react";
import { setTitle } from "../../../helpers/MetaTag";
import { Link } from "react-router-dom";
import CompanyList from "../companies/CompanyList";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import NumberCountAnimation from "../../../helpers/NumberCountAnimation";
import { adminDashboard } from "../../../services/adminService/DashboardService";
import DashboardSkeleton from "../../../components/skeleton/DashboardSkeleton";

const Dashboard = () => {
  setTitle("ORG Chart Admin | Dashboard");
  const [dashboardData, setDashboardData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    adminDashboard()
      .then((res) => {
        if (res && res?.status === 200) {
          setDashboardData(res?.data);
        } else {
          toast.error(res?.message || "Failed to fetch admin data");
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error("An error occurred while fetching the dashboard data.");
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading ? (
        <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
          <div className="flex justify-between items-center mb-3">
            <div className="text-2xl font-bold">Dashboard</div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <Link to="/admins">
              <div
                className="relative flex items-center space-x-4 p-4 border border-blue-500 bg-blue-50 rounded-lg shadow min-h-[100px]"
                data-tooltip-id="admins-tooltip"
                data-tooltip-place="top"
              >
                <i className="fa-solid fa-user text-4xl text-blue-500"></i>
                <div className="absolute top-2 right-4 text-2xl font-semibold text-blue-600">
                  Admins
                </div>
                <div className="absolute bottom-2 right-4 font-semibold text-blue-500 text-2xl">
                  <NumberCountAnimation
                    start={0}
                    end={dashboardData?.adminCount || 0}
                    duration={1000}
                  />
                </div>
              </div>

              <Tooltip
                id="admins-tooltip"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">
                    Click to view all admin list.
                  </div>
                )}
              />
            </Link>
            <Link to="/admin/companies">
              <div
                className="relative flex items-center space-x-4 p-4 border border-pink-500 bg-pink-50 rounded-lg shadow min-h-[100px]"
                data-tooltip-id="companies-tooltip"
                data-tooltip-place="top"
              >
                <i className="fa-solid fa-building text-4xl text-pink-500"></i>
                <div className="absolute top-2 right-4 text-2xl font-semibold text-pink-600">
                  Companies
                </div>
                <div className="absolute bottom-2 right-4 font-semibold text-pink-500 text-2xl">
                  <NumberCountAnimation
                    start={0}
                    end={dashboardData?.companyCount || 0}
                    duration={1000}
                  />
                </div>
              </div>

              <Tooltip
                id="companies-tooltip"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">
                    Click to view all company list.
                  </div>
                )}
              />
            </Link>
            <Link to="/admin/employees">
              <div
                className="relative flex items-center space-x-4 p-4 border border-orange-500 bg-orange-50 rounded-lg shadow min-h-[100px]"
                data-tooltip-id="employees-tooltip"
                data-tooltip-place="top"
              >
                <i className="fa-solid fa-users text-4xl text-orange-500"></i>
                <div className="absolute top-2 right-4 text-2xl font-semibold text-orange-600">
                  Employees
                </div>
                <div className="absolute bottom-2 right-4 font-semibold text-orange-500 text-2xl">
                  <NumberCountAnimation
                    start={0}
                    end={dashboardData?.employeeCount || 0}
                    duration={1000}
                  />
                </div>
              </div>

              <Tooltip
                id="employees-tooltip"
                className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                render={() => (
                  <div className="text-white">
                    Click to view all employee list.
                  </div>
                )}
              />
            </Link>
          </div>
        </div>
      ) : (
        <DashboardSkeleton />
      )}

      <div className="border bg-white border-gray-200 p-4 shadow rounded-md mt-4">
        <CompanyList />
      </div>
    </>
  );
};

export default Dashboard;
