import React, { useEffect, useState, useRef } from "react";
import { classNames } from "../../helpers/classNames";

const Input = ({
  isValidate,
  divClasses,
  label,
  labelClasses,
  inputType,
  inputClasses,
  inputPlaceholder,
  inputValue,
  value = "",
  inputName,
  isInputGroup = false,
  inputGroupIcon,
  inputGroupPosition,
  inputGroupIconClass,
  errorType,
  errorText,
  isDisabled,
  focus = false,
  allowClear = false,
  onClear = () => {},
  onChange = () => {},
  onKeyDown = () => {},
  charLimit,
  ...props
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [focused, setFocused] = useState(false);
  const [error, setError] = useState("");
  const passwordInput = useRef(null);
  const handleChange = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  useEffect(() => {
    passwordInput.current.type = passwordVisibility ? "text" : inputType;
  }, [inputType, passwordVisibility]);

  useEffect(() => {
    if (passwordInput.current && focus) {
      passwordInput.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordInput.current, focus]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    if (inputType === "password" && value.includes(" ")) {
      setError("Spaces are not allowed.");
      return;
    }
    if (charLimit && value.length > charLimit) {
      setError(`Character limit of ${charLimit} exceeded`);
      return;
    } else {
      setError("");
    }
    onChange(e);
  };

  return (
    <>
      <div className={classNames("relative space-y-1 w-full", divClasses)}>
        {label && (
          <div
            className={classNames(
              "text-sm font-medium text-slate-500",
              labelClasses
            )}
          >
            {label}
            {isValidate && (
              <span className="text-[8px] text-red-500 ml-1 absolute top-[-2px]">
                <i className="fa-solid fa-asterisk"></i>
              </span>
            )}
          </div>
        )}
        <div className={classNames("relative flex")}>
          {isInputGroup && inputGroupPosition === "left" && (
            <div
              className={classNames(
                "w-10 flex-shrink-0 flex items-center justify-center border rounded-md rounded-r-none bg-white text-slate-400 text-base transition-all duration-200",
                inputGroupIconClass,
                isDisabled ? "!bg-slate-200" : "",
                focused ? "border-orange-200" : "border-slate-200"
              )}
            >
              <i className={classNames("fa-fw", inputGroupIcon)}></i>
            </div>
          )}
          <div className="w-full">
            <div className="relative flex">
              {isDisabled ? (
                <input
                  type={inputType}
                  ref={passwordInput}
                  className={classNames(
                    "w-full h-10 rounded-md bg-white border py-0 px-3 text-sm text-slate-800 !ring-0 !outline-0 transition-all duration-200",
                    isDisabled ? "!bg-slate-100" : "",
                    inputClasses,
                    isInputGroup
                      ? inputGroupPosition === "left"
                        ? "!border-l-0 !rounded-l-none pl-0"
                        : ""
                      : inputGroupPosition === "right"
                      ? "!border-r-0 !rounded-r-none pr-0"
                      : "",
                    focused ? "!border-orange-500" : "!border-slate-200"
                  )}
                  placeholder={inputPlaceholder}
                  name={inputName}
                  // defaultValue={inputValue}
                  value={value}
                  autoComplete={"new-" + inputType}
                  disabled
                />
              ) : (
                <input
                  type={inputType}
                  ref={passwordInput}
                  className={classNames(
                    "w-full h-10 rounded-md bg-white border py-0 px-3 text-sm text-slate-800 !ring-0 !outline-0 transition-all duration-200",
                    inputClasses,
                    isInputGroup
                      ? inputGroupPosition === "left"
                        ? "!border-l-0 !rounded-l-none pl-0"
                        : ""
                      : inputGroupPosition === "right"
                      ? "!border-r-0 !rounded-r-none pr-0"
                      : "",
                    focused ? "!border-orange-200" : "!border-slate-200"
                  )}
                  onChange={handleInputChange}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onKeyDown={onKeyDown}
                  placeholder={inputPlaceholder}
                  name={inputName}
                  defaultValue={inputValue}
                  value={value}
                  autoComplete={"new-" + inputType}
                />
              )}
              {allowClear && (
                <button
                  type="button"
                  className="w-10 h-full absolute top-0 right-0 bg-transparent text-slate-500 bg-slate-200 text-sm"
                  onClick={onClear}
                >
                  <i className="fa-light fa-circle-xmark"></i>
                </button>
              )}
              {inputType === "password" && (
                <button
                  type="button"
                  className="w-8 h-full absolute top-0 right-0 bg-transparent text-slate-500 bg-slate-200 text-sm"
                  onClick={handleChange}
                >
                  <i
                    className={classNames(
                      "fa-regular fa-fw",
                      passwordVisibility ? "fa-eye-slash" : "fa-eye"
                    )}
                  ></i>
                </button>
              )}
            </div>
          </div>
          {isInputGroup && inputGroupPosition === "right" && (
            <div
              className={classNames(
                "w-10 flex-shrink-0 flex items-center justify-center border rounded-md rounded-r-none bg-white text-slate-500 text-base transition-all duration-200",
                inputGroupIconClass,
                isDisabled ? "!bg-slate-200" : "",
                focused ? "!border-orange-500" : "!border-slate-200"
              )}
            >
              <i className={classNames("fa-fw", inputGroupIcon)}></i>
            </div>
          )}
        </div>

        {error && (
          <div className="text-xs font-medium text-yellow-600">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {error}
          </div>
        )}
        {errorType && errorType === "danger" && (
          <div className="text-xs font-medium text-red-600">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "warning" && (
          <div className="text-xs font-medium text-amber-500">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "success" && (
          <div className="text-xs font-medium text-green-600">
            <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "info" && (
          <div className="text-xs font-medium text-sky-600">
            <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
};

export default Input;
