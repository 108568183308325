import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useRef, useState } from "react";
import { bottom, top, left, right } from "@popperjs/core";
import { classNames } from "../../helpers/classNames";
import InviteModal from "../../components/sections/front/InviteModal";
import Modal from "../../components/elements/Modal";
import { Tooltip } from "react-tooltip";

const InviteDropdown = ({ xPlacement, dropdownContainer }) => {
  const navigate = useNavigate();
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const dropdownData = [
    {
      _id: 1,
      name: "Invite",
      icon: "fa-regular fa-paper-plane",
      onclick: () => {
        setInviteModalOpen(true);
      },
      standout: false,
    },
    // {
    //   _id: 2,
    //   name: "Collaborators",
    //   icon: "fa-regular fa-clipboard-user",
    //   link: "/admin-collaborators",
    //   standout: false,
    // },
  ];

  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button ref={setTargetElement} className="flex items-center">
              <div
                className="relative w-10 h-10 flex items-center justify-center focus:ring-0 transition-all duration-200"
                data-tooltip-id="invite"
              >
                <div className="text-base text-white">
                  <i className="fa-regular fa-user-plus"></i>
                </div>
                <Tooltip
                  id="invite"
                  place="left"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">Click to invite.</div>
                  )}
                />
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              className="z-50"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items
                className={classNames(
                  "absolute border border-slate-200 w-40 origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                  dropdownContainer
                )}
              >
                <div className="py-1 max-h-80 overflow-auto scroll-smooth scrollbar">
                  {dropdownData.map((item) => (
                    <Menu.Item key={item._id}>
                      {({ active }) =>
                        item?.link ? (
                          <button
                            type="button"
                            // href={item.link}
                            className={classNames(
                              "group flex w-full items-center px-3 py-1.5 text-sm gap-2 transition-all duration-200",
                              active
                                ? "bg-opplio-veryLightBlue text-orange-400"
                                : "text-opplio-grayB",
                              item.standout
                                ? "!text-opplio-red hover:!bg-opplio-red/10"
                                : "",
                              item.class
                            )}
                            onClick={() => navigate("/collaborators")}
                          >
                            {item.icon && (
                              <i className={classNames("fa-fw", item.icon)}></i>
                            )}
                            <span>{item.name}</span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={item?.onclick}
                            className={classNames(
                              "group cursor-pointer flex w-full items-center px-3 py-1.5 text-sm gap-2 transition-all duration-200",
                              active
                                ? "bg-opplio-veryLightBlue text-orange-400"
                                : "text-opplio-grayB",
                              item.standout
                                ? "!text-opplio-red hover:!bg-opplio-red/10"
                                : "",
                              item.class
                            )}
                          >
                            {item.icon && (
                              <i className={classNames("fa-fw", item.icon)}></i>
                            )}
                            <span>{item.name}</span>
                          </button>
                        )
                      }
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <Modal
        title={"Invite"}
        size="xl"
        open={inviteModalOpen}
        onClose={() => setInviteModalOpen(false)}
        modaltitleClasses={"!text-slate-200 text-lg"}
        headerClass={"!bg-black !rounded-t-2xl !text-white"}
      >
        <InviteModal setInviteModalOpen={setInviteModalOpen} />
      </Modal>
    </>
  );
};

export default InviteDropdown;
