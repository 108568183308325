import { apiRequest } from "../../util/apiUtils";

export const companyDetails = async (params) => {
    try {
      const res = await apiRequest("get", `/user/company-details`);
  
      return res?.data;
    } catch (err) {
      throw err;
    }
  };

  export const companyUpdate = async (params) => {
    try {
      const res = await apiRequest(
        "put",
        `/user/company-edit`,
        {
          body: {
            ...params,
          },
        },
        "multipart/form-data"
      );
      return res?.data;
    } catch (err) {
      return err;
    }
  };