import React from "react";
import UserDropdown from "./UserDropdown";

const AdminHeader = ({ profile }) => {
  const data = {
    image: profile?.image?.url,
    name: profile?.firstName
      ? profile?.firstName + " " + profile?.lastName
      : null,
  };

  return (
    <header className="sticky top-0 h-16 min-h-[64px] z-50 w-full flex items-center px-3 md:px-6 bg-orange-100 shadow gap-4">
      <div className="relative ml-auto flex justify-end items-center gap-3 ">
        <div className="relative">
          <UserDropdown role="admin" data={data} xPlacement={"bottomRight"} />
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
