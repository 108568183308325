import { apiRequest } from "../../util/apiUtils";

export const getEmailTemplateList = async (params) => {
  try {
    const res = await apiRequest("get", `/email-template`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const emailTemplateDetails = async (params) => {
    try {
      const res = await apiRequest(
        "get",
        `/email-template/${params?.id}`
      );
  
      return res?.data;
    } catch (err) {
      throw err;
    }
  };
  export const updateEmailTemplate = async (id,params) => {
    try {
      const res = await apiRequest(
        "put",
        `/email-template/${id}`,
        {
          body: params,
        },
        "multipart/form-data"
      );
      return res?.data;
    } catch (error) {
      return error;
    }
  };