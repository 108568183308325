import { Link } from "react-router-dom";
import Button from "../../../form/Button";
import noEmpImg from "../../../../assets/images/noEmpImg.svg";
import Image from "../../../elements/Image";
import EventLoader from "../../../loaders/EventLoader";
import dayjs from "dayjs";
import { eventTypes } from "../../../constant/constant";
import { useState } from "react";
import MyProfile from "../../../../pages/front/profile/MyProfile";
import { Tooltip } from "react-tooltip";

export default function ForYou({ profile, eventList }) {
  const [profileModal, setProfileModal] = useState({
    isOpen: false,
  });
  return (
    <div className="col-span-12 md:col-span-4 lg:col-span-3 flex flex-col gap-4 p-2">
      <p className="text-xl font-semibold text-slate-600">For You</p>
      <div data-tooltip-id="home-profile">
        <Button
          buttonHasLink={false}
          buttonIconPosition="left"
          buttonLabel="Edit Your Profile"
          buttonIcon="fa-regular fa-circle-user"
          buttonClasses="!bg-white !w-full !h-12 !text-slate-600 !border-slate-300 !font-medium !text-sm hover:!bg-slate-100"
          buttonFunction={() => setProfileModal({ isOpen: true })}
        />
        <Tooltip
          id="home-profile"
          place="top"
          className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-10 "
          render={() => (
            <div className="text-white">Click it to edit your profile</div>
          )}
        />
      </div>
      <div className="border border-slate-300 rounded-md p-4 text-slate-800">
        <p className="text-sm font-semibold">Your Org Memberships</p>
        <div className="flex flex-col gap-2 mt-3">
          <div className="flex justify-between items-center py-1">
            <div className="flex items-center gap-2">
              <i className="fa-light fa-users" />
              <p>Employees</p>
            </div>
            <span>{profile?.employeeCount}</span>
          </div>
          <div className="flex justify-between items-center py-1">
            <div className="flex items-center gap-2">
              <i className="fa-light fa-flag" />
              <p>Departments</p>
            </div>
            <span>{profile?.departmentCount}</span>
          </div>
          <div className="flex justify-between items-center py-1">
            <div className="flex items-center gap-2">
              <i className="fa-light fa-building-user" />
              <p>Position</p>
            </div>
            <span>{profile?.positionCount}</span>
          </div>
          <Link
            to="/chart"
            className="text-orange-600 font-semibold text-sm hover:underline mt-2"
            data-tooltip-id="org-chart"
          >
            View Org Chart <i className="fa-regular fa-arrow-right ml-1" />
            <Tooltip
              id="org-chart"
              place="top"
              className="!text-slate-500 !font-normal !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">Click to view org chart.</div>
              )}
            />
          </Link>
          <Link
            to="/directory/employee"
            className="text-orange-600 font-semibold text-sm hover:underline"
            data-tooltip-id="org-directory"
          >
            View Directory <i className="fa-regular fa-arrow-right ml-1" />
            <Tooltip
              id="org-directory"
              place="top"
              className="!text-slate-500 !font-normal !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">Click to view directory.</div>
              )}
            />
          </Link>
        </div>
      </div>
      <div className="border border-slate-300 rounded-md p-4 text-slate-800">
        <p className="text-sm font-semibold mb-4">Your Upcoming Events</p>
        <div className="space-y-2 2xl:space-y-4">
          {eventList?.loading ? (
            <EventLoader count={5} />
          ) : eventList?.data &&
            Array.isArray(eventList?.data) &&
            eventList?.data.length === 0 ? (
            <div className="border border-slate-300 rounded-lg p-5 bg-white shadow-sm">
              <div className="border-2 border-dashed rounded-lg p-6 text-center flex flex-col items-center justify-center gap-4">
                <div className="w-20 h-20 flex items-center justify-center rounded-full bg-gray-100">
                  <i className="fa-light fa-calendar-times text-3xl text-gray-500" />
                </div>
                <p className="text-center text-sm font-medium text-slate-700">
                  No upcoming birthdays or celebrations at the moment. Stay
                  tuned for future events!
                </p>
              </div>
            </div>
          ) : (
            eventList?.data?.map((event, i) => {
              const eventType = eventTypes[event?.type] || {};
              return (
                <div
                  key={i}
                  className="flex items-center xl:justify-center 2xl:justify-between px-4 py-2 bg-white border rounded-lg shadow-sm"
                >
                  <div className="flex md:flex-col 2xl:flex 2xl:flex-row items-center space-x-3 md:space-x-0 2xl:space-x-4">
                    {event?.type === "announcement" ||
                    event?.type === "holiday" ? (
                      <div className="w-12 h-12 relative ">
                        {event?.type === "holiday" ? (
                          <div className="w-full h-full flex items-center justify-center rounded-full bg-green-100 border border-green-200">
                            <i
                              className={
                                "fa-regular fa-umbrella-beach text-green-600"
                              }
                            />
                          </div>
                        ) : (
                          <div className="w-full h-full flex items-center justify-center rounded-full bg-purple-100 border border-purple-200">
                            <i
                              className={
                                "fa-regular fa-bullhorn text-purple-600"
                              }
                            />
                          </div>
                        )}
                      </div>
                    ) : (
                      <Link to={``} className="w-12 h-12 relative ">
                        <Image
                          src={event?.forUserDetail?.image?.url || noEmpImg}
                          alt={""}
                          effect={"blur"}
                          className="w-full h-full object-cover rounded-full border"
                        />
                        <span
                          className={`z-10  absolute bottom-1 -left-2 text-[10px] ${eventType.color} rounded-full py-[3px] px-[5px] border flex-shrink-0`}
                        >
                          <i className={eventType.icon} />
                        </span>
                      </Link>
                    )}
                    <div className="flex-1">
                      {event?.type === "announcement" ||
                      event?.type === "holiday" ? (
                        <p className="text-sm font-semibold text-slate-700 capitalize">
                          {event?.type}
                        </p>
                      ) : (
                        <p className="text-sm font-semibold text-slate-700 capitalize">
                          {event?.forUserDetail?.firstName +
                            " " +
                            event?.forUserDetail?.lastName}
                        </p>
                      )}
                      <p className="text-xs text-slate-500">
                        {event?.type === "announcement" ||
                        event?.type === "holiday" ? (
                          <span className="capitalize font-semibold">
                            {event?.title}
                          </span>
                        ) : (
                          <span className="capitalize font-semibold">
                            {event?.type}
                          </span>
                        )}{" "}
                        • {dayjs(event?.startDate).format("MMM D")}
                        {event?.startDate !== event?.endDate &&
                          ` - ${dayjs(event?.endDate).format("MMM D")}`}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <MyProfile
        profileModal={profileModal}
        setProfileModal={setProfileModal}
      />
    </div>
  );
}
