import { apiRequest } from "../../util/apiUtils";

export const getEventList = async (params) => {
  try {
    const res = await apiRequest("GET", `/event/list`, {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const eventAdd = async (payload) => {
  try {
    const res = await apiRequest(
      "POST",
      `/event/add`,
      {
        body: payload,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const eventEdit = async (payload) => {
  try {
    const res = await apiRequest(
      "PUT",
      `/event/edit`,
      {
        body: payload,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const deleteEvent = async (payload = {}) => {
  try {
    const res = await apiRequest("PATCH", `/event/delete`, {
      body: payload,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
