import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/form/Button";
import { Tooltip } from "react-tooltip";
import Input from "../../../components/form/Input";
import useForm from "../../../hooks/useForm";
import { toast } from "react-toastify";
import Select from "../../../components/form/Select";
import { formValidate } from "../../../helpers/formValidate";
import ImageUploader from "../../../components/form/ImageUploader";
import {
  addEditAdmin,
  adminDetails,
} from "../../../services/adminService/AdminsService";
import { useSelector } from "react-redux";
import { Validation } from "../../../helpers/Validation";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const ManageAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [details, setDetails] = useState();
  const [status, setStatus] = useState(1);
  const [phone, setPhone] = useState("");

  const validation = {
    firstName: { required: true, message: "Please enter your first name!" },
    lastName: { required: true, message: "Please enter your last name!" },
    email: { required: true, message: "Please enter your email!" },
    password: {
      required: id ? false : true,
      message: "Please enter password!",
    },
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({}, validation);
  const profile = useSelector((state) => state?.profile?.data);
  useEffect(() => {
    if (id) {
      adminDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetails(res?.data);
        } else {
          toast.error("Something went wrong!");
        }
      });
    }
  }, [id]);

  useEffect(() => {
    if (id && details) {
      setFieldsValue({
        firstName: details?.firstName || "",
        lastName: details?.lastName || "",
        email: details?.email || "",
      });

      setStatus(details?.status);

      if (details?.image?.url) {
        setImage(details.image.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, details]);

  useEffect(() => {
    if (details?.phone) {
      let phoneNumber = String(details?.phone);
      if (!phoneNumber.startsWith("+")) {
        phoneNumber = `+${phoneNumber}`;
        setPhone(phoneNumber);
      }
    } else {
      setPhone("");
    }
  }, [details?.phone]);

  const handlePhoneInputChange = (value) => {
    setPhone(value);
  };
  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push(
        "Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?)."
      );
    }

    return errors;
  };
  const onSubmit = async (values) => {
    setLoading(true);

    try {
      if (!Validation("email", values.email)) {
        toast.error("Invalid email format. Please enter a valid email.");
        setLoading(false);
        return;
      }

      if (values.password) {
        const passwordErrors = validatePassword(values.password);
        if (passwordErrors.length > 0) {
          toast.error(passwordErrors.join(" "));
          setLoading(false);
          return;
        }
      }
      const payload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phone: phone || "",
        password: values?.password,
        status: status,
        image: image || "null",
      };

      if (id) {
        payload._id = id;
      }

      const res = await addEditAdmin(payload);
      setLoading(false);
      if (res?.status === 201 || res?.status === 200) {
        toast.success(res?.message || "successful!");
        navigate("/admins");
      } else {
        toast.error(res?.data?.message || "Something went wrong!");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong! Please try again.");
    }
  };
  return (
    <div className="border bg-white border-gray-200 p-4 shadow rounded-md">
      <div className="flex justify-between items-center mb-3">
        <div className="text-2xl font-bold">{id ? "Update" : "Add"} Admin</div>
        <div className="relative flex items-center ml-auto gap-2">
          <div data-tooltip-id="reset-admin" data-tooltip-place="bottom">
            <Button
              buttonType={"button"}
              buttonIcon={"fa-regular fa-rotate-left"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => resetField()}
            />
            <Tooltip
              id="reset-admin"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">Please click to reset.</div>
              )}
            />
          </div>
          <div data-tooltip-id="back-admin" data-tooltip-place="bottom">
            <Button
              buttonType={"button"}
              buttonClasses={"!text-center"}
              buttonLabel={"Back"}
              buttonIcon={"fa-solid fa-arrow-left"}
              buttonIconPosition={"left"}
              buttonHasLink={false}
              buttonFunction={() => navigate("/admins")}
            />
            <Tooltip
              id="back-admin"
              className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
              render={() => (
                <div className="text-white">
                  Click to go back to admin list.
                </div>
              )}
            />
          </div>
        </div>
      </div>

      <form onSubmit={(e) => handleSubmit(e, onSubmit)}>
        <div className="grid grid-cols-12 gap-4">
          <div className="image bg-white p-4 col-span-12 lg:col-span-3 border-r border-slate-200 h-auto">
            <ImageUploader
              label=""
              labelClasses="text-lg font-bold"
              isMultiple={false}
              image={image}
              setImage={setImage}
              buttonLabel={image ? "Change image" : "Upload image"}
            />
          </div>
          <div className="input bg-white col-span-12 lg:col-span-9">
            <div className="grid lg:grid-cols-2 gap-3">
              <div className="relative ">
                <Input
                  label={"First Name"}
                  inputType={"text"}
                  value={values?.firstName}
                  inputPlaceholder={"Enter first name"}
                  inputName="firstName"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "firstName")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Last Name"}
                  inputType={"text"}
                  value={values?.lastName}
                  inputPlaceholder={"Enter last name"}
                  inputName="lastName"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "lastName")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Email"}
                  inputType={"text"}
                  value={values?.email}
                  inputPlaceholder={"Enter email"}
                  inputName="email"
                  onChange={handleChange}
                  isValidate={true}
                  isDisabled={details?._id === profile?._id ? true : false}
                  {...formValidate(errors, "email")}
                />
              </div>
              <div className="relative">
                <Input
                  label={"Password"}
                  inputType={"password"}
                  value={values?.password}
                  inputPlaceholder={"Enter password"}
                  inputName="password"
                  onChange={handleChange}
                  isValidate={true}
                  {...formValidate(errors, "password")}
                />
              </div>
              <div className="relative mt-0.5">
                <div className="text-sm font-medium text-slate-500">Phone</div>
                <PhoneInput
                  placeholder="(xxx) xxx-xxxx"
                  value={phone}
                  onChange={handlePhoneInputChange}
                  // defaultCountry="US"
                  international={false}
                />
              </div>
              <div className="relative">
                <Select
                  label={"Status"}
                  dropdownData={[
                    { name: "Active", value: 1 },
                    { name: "Inactive", value: 2 },
                  ]}
                  selectedValue={status}
                  setCustomSelecedvalue={(val) => setStatus(val)}
                />
              </div>
            </div>
            <div className="w-full flex items-center justify-end gap-3 mt-4">
              <div data-tooltip-id="admin-add" data-tooltip-place="left">
                <Button
                  buttonType={"submit"}
                  buttonIcon={
                    id ? "fa-light fa-floppy-disk" : "fa-light fa-plus"
                  }
                  buttonIconPosition={"left"}
                  buttonLabel={id ? "Save" : "Add"}
                  loading={loading}
                />
                <Tooltip
                  id="admin-add"
                  className="!text-slate-500 !text-sm !bg-slate-950 !bg-opacity-100 z-100"
                  render={() => (
                    <div className="text-white">Click to add admin.</div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ManageAdmin;
