import { status_filter } from "../../../components/constant/constant";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "../../../components/form/Select";
import Search from "../../../components/form/Search";
import Button from "../../../components/form/Button";
import Table from "../../../components/elements/table/Table";
import { setTitle } from "../../../helpers/MetaTag";
import {
  changeStatus,
  deleteCompany,
} from "../../../services/adminService/CompanyService";
import { createFilter, handleFilterChange } from "../../../helpers";
import DeleteModal from "../../../components/common/DeleteModal";
import { getEmailTemplateList } from "../../../services/adminService/EmailTemplateService";

const EmailTemplates = () => {
  setTitle("ORG Chart Admin | Email Templates");
  const [selectedItems, setSelectedItems] = useState([]);
  const [sort, setSort] = useState("-createdAt");
  const [filters, setFilters] = useState({
    limit: 10,
    offset: 0,
    currentPage: 0,
    keyword: "",
    status: null,
  });
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    ids: [],
    loading: false,
  });

  // loading the company list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));

    if (filters?.limit && sort) {
      getEmailTemplateList({
        sortQuery: sort,
        keyword: filters?.keyword,
        limit: filters?.limit === 0 ? list?.totalItem : filters?.limit,
        offset: filters?.offset,
        ...createFilter(filters?.status, "status"),
      }).then((res) => {
        if (res && res?.status === 200) {
          setList({
            loading: false,
            data: res?.docs,
            pageCount: res?.totalPages,
            totalItem: res?.totalDocs,
          });
        } else {
          setList((pre) => ({ ...pre, data: [], loading: false }));
          toast?.error(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sort]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // Status Change >>>>>>>>>>

  const tableHeadData = [
    {
      _id: 1,
      name: "Subject",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-subject");
        } else {
          setSort("subject");
        }
      },
    },
    {
      _id: 2,
      name: "Email For",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-mailFor");
        } else {
          setSort("mailFor");
        }
      },
    },
    {
      _id: 3,
      name: "From Mail",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-fromMail");
        } else {
          setSort("fromMail");
        }
      },
    },
    {
      _id: 4,
      name: "To Mail",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-toMail");
        } else {
          setSort("toMail");
        }
      },
    },
    {
      _id: 5,
      name: "Status",
      align: "left",
      isFilter: false,
      isFilterSearch: false,
      isSort: false,
      filterData: status_filter,
      onFilter: (data) => {
        handleFilterChange(
          "status",
          data?.map((item) => item?.value),
          setFilters
        );
        setFilters((prev) => ({ ...prev, offset: 0 ,currentPage: 0}));
      },
    },
    {
      _id: 6,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item) => ({
    _id: item?._id,
    rowData: [
      {
        _id: 1,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.subject || "N/A",
        isTooltip: false,
      },

      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 bg-yellow-100 text-yellow-600 `}
              >
                {item?.mailFor}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 3,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 bg-green-100 text-green-600 `}
              >
                {item?.fromMail}
              </div>
            </div>
          </div>
        ),
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: (
          <div>
            <div className={"flex items-center justify-start"}>
              <div
                className={`text-xs font-medium leading-tight rounded py-1 px-2 bg-blue-100 text-blue-600 `}
              >
                {item?.toMail || "To User"}
              </div>
            </div>
          </div>
        ),
      },

      {
        _id: 5,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "Inactive",
        statusType: item?.status === 1 ? "success" : "danger",
        // functions: () => handleStatusChange(item),
      },
      {
        _id: 6,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          {
            _id: 1,
            name: "Edit",
            icon: "fa-regular fa-pen-to-square",
            link: `/admin/email-template/edit/${item?._id}`,
            standout: true,
          },
          // {
          //   _id: 2,
          //   name: "Delete",
          //   icon: "fa-regular fa-trash-can",
          //   standout: true,
          //   onClick: () =>
          //     setDeleteModal((pre) => ({
          //       ...pre,
          //       isOpen: true,
          //       ids: [item?._id],
          //     })),
          // },
        ],
      },
    ],
  }));

  return (
    <div className="space-y-4 w-full flex flex-col pb-10">
      <div className="space-y-4 mt-5">
        <div className="font-semibold text-lg md:text-xl xl:text-2xl">
          Email Templates
        </div>

        <div className="flex items-center gap-3">
          <div className="w-36">
            <Select
              selectedValue={filters?.limit}
              dropdownData={[
                { name: "All Items", value: list?.totalItem },
                { name: "5 Items", value: 5 },
                { name: "10 Items", value: 10 },
                { name: "20 Items", value: 20 },
                { name: "30 Items", value: 30 },
                { name: "50 Items", value: 50 },
                { name: "100 Items", value: 100 },
              ]}
              setCustomSelecedvalue={(val) => {
                handleFilterChange("limit", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
                handleFilterChange("currentPage", 0, setFilters);
              }}
            />
          </div>
          <div className="w-72">
            <Search
              placeholder={"Search here"}
              search={(val) => {
                handleFilterChange("keyword", val, setFilters);
                handleFilterChange("offset", 0, setFilters);
                handleFilterChange("currentPage", 0, setFilters);
              }}
            />
          </div>
        </div>
      </div>
      <Table
        tableHeadData={tableHeadData}
        tableData={tableData}
        isLoder={list?.loading}
        pageCount={list?.pageCount}
        currentPage={filters?.currentPage}
        onPageChange={(val) => {
          const newOffset = filters.limit * val?.selected;
          handleFilterChange("offset", newOffset, setFilters);
          handleFilterChange("currentPage", val?.selected, setFilters);
          setSelectedItems([]);
        }}
        isOrderingDisabled={true}
        originalData={[...list?.data]}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
        tableImage={false}
        containerClasses={"flex-grow flex-shrink min-h-[calc(100vh-220px)]"}
        tableActionDropdownContainer={"!w-48"}
      />
      <DeleteModal
        open={deleteModal?.isOpen}
        loading={deleteModal?.loading}
        onClose={() => setDeleteModal((pre) => ({ ...pre, isOpen: false }))}
        // deleteHandler={deletefunction}
      />
    </div>
  );
};

export default EmailTemplates;
